import useAuth from "src/hooks/useAuth";

import { Button, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { experimentalStyled } from "@mui/material/styles";

const SidebarDivider = experimentalStyled(Divider)(
  ({ theme }) => `
        background: ${theme.sidebar.dividerBg};
`
);

function SidebarTopSection() {
  const { t } = useTranslation();
  return (
    <>
      <SidebarDivider />
    </>
  );
}

export default SidebarTopSection;
