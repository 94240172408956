import { useContext, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Box, Divider, Drawer, Hidden, Typography } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import { SidebarContext } from "src/contexts/SidebarContext";
import SidebarMenu from "./SidebarMenu";
import { fetchPartnerDocUrl, getPartnerSetting } from "src/utils/NetworkUtils";
import SidebarTopSection from "./SidebarTopSection";
import { FINMO_LOGO_URL } from "src/utils/globalStaticData";

const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        border-right: 1px solid #E5EDFF3B;
        height: 100%;
        padding: ${theme.spacing(2)};
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
        }
`
);

const TopSection = experimentalStyled(Box)(
  ({ theme }) => `
        margin-bottom: ${theme.spacing(2)};
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [logoUrl, setLogoUrl] = useState("");
  const [fetching, setFetching] = useState(true);
  const closeSidebar = () => toggleSidebar();

  const getPartnerLogo = async () => {
    setFetching(true);
    const partner_setting = await getPartnerSetting("partner_logo");

    const partner_document_id = partner_setting.data.value;
    if (!!partner_document_id) {
      const partner_logo_document = await fetchPartnerDocUrl(
        partner_document_id
      );
      const download_url = partner_logo_document.data.download_url;
      setLogoUrl(download_url);
      setFetching(false);
    } else {
      setLogoUrl("");
      setFetching(false);
    }
  };

  useEffect(() => {
    if (logoUrl.length <= 0) {
      (async () => await getPartnerLogo())();
    }
  }, []);

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
          <Scrollbars autoHide>
            <TopSection>
              <Box
                sx={{
                  mt: 3,
                }}
                justifyContent={"space-between"}
                alignItems={"center"}
                display="flex"
                flexDirection={"column"}
              >
                {!fetching && (
                  <Box height={"34px"}>
                    <img
                      style={{ height: "100%" }}
                      src={logoUrl.length > 0 ? logoUrl : FINMO_LOGO_URL}
                      alt="logo"
                    />
                  </Box>
                )}

                {!fetching && logoUrl.length > 0 && (
                  <Box
                    display={"flex"}
                    sx={{ mt: 2 }}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography variant="subtitle" fontSize={"12px"}>
                      Powered by
                      <img
                        loading="lazy"
                        style={{ width: "55px", marginLeft: "7px" }}
                        src={FINMO_LOGO_URL}
                        alt="Finmo"
                      />
                    </Typography>
                  </Box>
                )}
              </Box>
            </TopSection>
            <SidebarTopSection />
            <SidebarMenu />
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>
                <Box
                  sx={{
                    mt: 3,
                  }}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  display="flex"
                  flexDirection={"column"}
                >
                  {!fetching && (
                    <img
                      style={{ width: "80%" }}
                      src={logoUrl.length > 0 ? logoUrl : FINMO_LOGO_URL}
                      alt="logo"
                      width={200}
                    />
                  )}
                  {!fetching && logoUrl.length > 0 && (
                    <Box
                      display={"flex"}
                      sx={{ mt: 2 }}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography variant="subtitle" fontSize={"12px"}>
                        Powered by
                        <img
                          style={{ width: "55px", marginLeft: "7px" }}
                          src={FINMO_LOGO_URL}
                          alt="Finmo"
                        />
                      </Typography>
                    </Box>
                  )}
                </Box>
              </TopSection>
              <SidebarTopSection />
              <SidebarMenu />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
