export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const accessToken = window.localStorage.getItem("access_token") || "";
export const test_api_key = window.localStorage.getItem("test_api_key") || "";
export const prod_api_key = window.localStorage.getItem("prod_api_key") || "";

// export const BASE_API_URL = "http://localhost:9201";
// export const WEB_GTWY_API_URL = "http://localhost:9201";

export const BASE_API_URL = "https://pp-gtwy.finmo.net";
export const WEB_GTWY_API_URL = "https://pp-gtwy.finmo.net";
