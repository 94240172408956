const menuItems = [
  {
    items: [
      {
        name: "Home",
        link: "/preview/dashboards/analytics",
      },
      {
        name: "Partner Users",
        link: "/preview/dashboards/users",
      },
      {
        name: "Organizations",
        link: "/preview/dashboards/organization",
        // items: [
        //   {
        //     name: "All Organization",

        //   },
        //   {
        //     name: "Invite New Organization",
        //     link: "/preview/dashboards/invite-organization",
        //   },
        // ],
      },
      {
        name: "Organization Console",
        link: "/preview/dashboards/merchant-console",
      },
      {
        name: "Transactions & Reports",
        link: "/preview/dashboards/transactions",
      },

      // {
      //   name: "Organization User",
      //   items: [
      //     {
      //       name: "Organization Specific Users",
      //       link: "/preview/dashboards/user/orgs",
      //     },
      // {
      //   name: "Impersonate Org Users",
      //   link: "/preview/dashboards/user/impersonate",
      // },
      // ],
      // },
      // {
      //   name: "Transactions",
      //   items: [
      //     {
      //       name: "All Transactions",
      //       link: "/preview/dashboards/transactions",
      //     },
      //   ],
      // },
      {
        name: "Settings",
        mode: "all",
        link: "/preview/dashboards/setting",
      },
    ],
  },
];

export default menuItems;
