import { useRef, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";

import { experimentalStyled } from "@mui/material/styles";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import Label from "src/components/Label";
import { useTheme } from "@emotion/react";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxCompany = experimentalStyled(Box)(
  ({ theme }) => `
        background-color: ${theme.colors.primary.lighter};
        color: ${theme.colors.primary.main};
        padding: ${theme.spacing(1, 2)};
        border-radius: 8px
`
);

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: bold;
        color: ${theme.colors.alpha.black[100]};
        display: block;

`
);

const UserBoxDescription = experimentalStyled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const theme = useTheme();
  const history = useHistory();

  const { user, logout } = useAuth();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{`${user.first_name} ${user.last_name}`}</UserBoxLabel>
          </UserBoxText>
        </Hidden>

        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} color={"primary"} />
        </Hidden>
      </UserBoxButton>

      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          {/* <Avatar variant="rounded" alt={user.first_name} src={user.avatar} /> */}
          <UserBoxText>
            <UserBoxLabel variant="body1">{`${user.first_name} ${user.last_name}`}</UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem to="/preview/dashboards/profile" component={NavLink}>
            <PersonOutlineTwoToneIcon fontSize="small" />
            <ListItemText primary={"Profile"} />
          </ListItem>
          <ListItem to="/preview/dashboards/setting" component={NavLink}>
            <SettingsTwoToneIcon fontSize="small" />
            <ListItemText primary={"Settings"} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {"Sign out"}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
