import { intervalToDuration, formatDuration, formatISO } from "date-fns";
import { toast } from "react-toastify";
import Label from "src/components/Label";
import {
  currency_decimal,
  route_name,
  dashboard_routes,
  decimal_format_list,
} from "./globalStaticData";
import { useQuery } from "src/hooks/querys";
import getMT103Data from "./MT103_Reader/index";
import { createOrganizationSetting } from "./NetworkUtils";
import numeral from "numeral";

export const default_currency_live =
  localStorage.getItem("default_currency_live") || "";
export const default_currency_test =
  localStorage.getItem("default_currency_test") || "";

export const setDefaultCurrency = () => {
  localStorage.setItem("default_currency_live", default_currency_live);
  localStorage.setItem("default_currency_test", default_currency_test);
};

export const reduxErrorMessage = (error) => {
  if (
    error?.response &&
    (error?.response?.data?.error?.code === 110701 ||
      error?.response?.data?.error?.code === 110703)
  ) {
    localStorage.clear();
    setDefaultCurrency();
    window.location.reload();
    return;
  }
  return error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
};

export const apiErrorMessage = (error) => {
  // Unauthorized
  if (error?.response && error?.response?.data?.error?.code === 110703) {
    localStorage.clear();
    setDefaultCurrency();
    window.location.reload();
    return;
  }
  if (
    window.location.pathname !== "/preview/dashboards/analytics" &&
    error?.response?.status === 403
  ) {
    window.location.replace("/");
    return;
  }
  if (error?.response && error?.response?.data?.error?.code === 110701) {
    localStorage.clear();
    setDefaultCurrency();
    window.location.reload();
    return;
  }
  if (Array.isArray(error?.response && error?.response?.data?.error?.message)) {
    const message = error?.response && error?.response?.data?.error?.message;
    return message.join(" , ");
  } else if (error?.response && error?.response?.data?.message) {
    return error?.response?.data?.message;
  } else {
    return error?.response && error?.response?.data?.error
      ? error?.response?.data?.error?.message
      : error?.message;
  }
};

export const GetAllQueryString = () => {
  const query = useQuery();
  let queryString = [];
  query.forEach((value, key) => {
    if (key !== "mode" && key !== "section") {
      queryString.push({ filter_key: key, filter_value: value });
    }
  });
  return queryString;
};

export const convert_to_dob = (dob) => {
  try {
    let new_dt = new Date(
      dob.getTime() + Math.abs(dob.getTimezoneOffset()) * 60000
    );
    return (dob = new_dt.toLocaleDateString("en-SE"));
  } catch {
    return dob;
  }
};

export const limitStr = (str, len, endsWith = "") => {
  return str.length > len ? str.substring(0, len) + endsWith : str;
};

export const deleteObjProperty = (obj, keys = []) => {
  const cloneObj = { ...obj };
  keys.map((key) => delete cloneObj[key]);
  return cloneObj;
};

export const objToKeyPairArr = (obj) => (obj ? Object.entries(obj) : []);

export const rmUnderScore = (str) => str && str.replaceAll("_", " ");

export const capitalizeFirst = (str) => {
  return str ? str.slice(0, 1).toUpperCase() + str.slice(1) : "";
};

export const isEmptyObj = (obj) =>
  Object.entries(obj).length === 0 && obj.constructor === Object;

export const toastNotification = (type, message, time, position) => {
  toast[type || "error"](message || "", {
    position: position || "bottom-center",
    autoClose: time || 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const isObject = (obj) =>
  typeof obj === "object" && obj !== null && !Array.isArray(obj);

export const handleAmountChange = (val, curr) => {
  const decimals =
    currency_decimal[curr] === 0
      ? 0
      : currency_decimal[curr]
      ? currency_decimal[curr]
      : 2;
  const arr = val.toString().split(".");
  if (arr.length === 1) return val;
  const int = arr[0],
    max = arr[1].length,
    dec = arr[1].substr(0, decimals > max ? max : decimals);
  const amount = decimals === 0 ? int : [int, ".", dec].join("");
  return amount;
};

export const toDecimal = (num, curr) => {
  const decimals =
    currency_decimal[curr] === 0
      ? 0
      : currency_decimal[curr]
      ? currency_decimal[curr]
      : 2;
  return parseFloat(num.toFixed(decimals));
};

export const toCurrencyBasedDecimal = (num, curr) => {
  const format = decimal_format_list[currency_decimal[curr]];
  return numeral(toDecimal(num, curr)).format(format);
};

export const returnValidAmount = (amt, curr = "") => {
  const amount = amt === 0 ? `${0} ${curr}` : amt ? `${amt} ${curr}` : "N/A";
  return amount;
};

export const getCheckoutReqObj = ({
  amount,
  currency,
  description,
  expire_at,
  redirect_url,
  advanced_redirect_url,
  webhook_url,
  country,
  include_category,
  exclude_category,
  include_payin_method,
  exclude_payin_method,
  organization_reference_id,
  notification_email,
  receipt_email,
  notification_phone_country_code,
  notification_phone_number,
  notification_phone_number_e164,
  customer_id,
  credit_wallet_id,
  amount_breakdown,
}) => ({
  amount: +amount,
  country,
  currency,
  include_category: include_category.length === 0 ? null : include_category,
  exclude_category: exclude_category.length === 0 ? null : exclude_category,
  include_payin_method:
    include_payin_method.length === 0 ? null : include_payin_method,
  exclude_payin_method:
    exclude_payin_method.length === 0 ? null : exclude_payin_method,
  description: description === "" ? null : description,
  expire_at: expire_at === "" ? null : expire_at,
  redirect_url: redirect_url === "" ? null : redirect_url,
  advanced_redirect_url: redirect_url !== "" ? null : advanced_redirect_url,
  webhook_url: webhook_url === "" ? null : webhook_url,
  organization_reference_id:
    organization_reference_id === "" ? null : organization_reference_id,
  customer_id: customer_id === "" ? null : customer_id,
  credit_wallet_id: credit_wallet_id === "" ? null : credit_wallet_id,
  notification_email: notification_email === "" ? null : notification_email,
  receipt_email: receipt_email === "" ? null : receipt_email,
  notification_phone_country_code:
    notification_phone_country_code === ""
      ? null
      : notification_phone_country_code,
  notification_phone_number:
    notification_phone_number === "" ? null : notification_phone_number,
  notification_phone_number_e164:
    notification_phone_number_e164 === ""
      ? null
      : notification_phone_number_e164,
  amount_breakdown: amount_breakdown?.length === 0 ? {} : amount_breakdown,
});

export const getVirtualAccountReqObj = ({
  customer_id,
  currency,
  description,
  payin_method_name,
  payin_method_param,
  credit_wallet_id,
  client_reference,
  order_id,
  customer_name,
  scope,
  webhook_url,
  organization_reference_id,
}) => ({
  customer_id: customer_id === "" ? null : customer_id,
  currency,
  description: description === "" ? null : description,
  payin_method_name,
  payin_method_param: isEmptyObj(payin_method_param)
    ? null
    : payin_method_param,
  credit_wallet_id: credit_wallet_id === "" ? null : credit_wallet_id,
  scope,
  webhook_url: webhook_url === "" ? null : webhook_url,
  organization_reference_id:
    organization_reference_id === "" ? null : organization_reference_id,
});

export const getIndividualReqObj = ({
  first_name,
  last_name,
  dob,
  email,
  nationality,
  address,
  phone_country_code,
  phone_number,
  identification_type,
  identification_value,
  description,
  address_country,
  address_line1,
  address_line2,
  address_city,
  address_state,
  address_zip_code,
  country_of_residence,
  identification_custom_type,
  webhook_url,
  organization_reference_id,
}) => ({
  description: description === "" ? null : description,
  type: "individual",
  webhook_url: webhook_url === "" ? null : webhook_url,
  organization_reference_id:
    organization_reference_id === "" ? null : organization_reference_id,
  individual: {
    first_name,
    last_name,
    dob: dob === "" ? null : dob,
    email: email === "" ? null : email,
    nationality: nationality === "" ? null : nationality,
    address: address === "" ? null : address,
    phone_country_code: phone_country_code === "" ? null : phone_country_code,
    phone_number: phone_number === "" ? null : phone_number,
    identification_type:
      identification_type === "" ? null : identification_type,
    identification_value:
      identification_value === "" ? null : identification_value,
    identification_custom_type:
      identification_custom_type === "" ? null : identification_custom_type,
    country_of_residence:
      country_of_residence === "" ? null : country_of_residence,
    address_country: address_country === "" ? null : address_country,
    address_line1: address_line1 === "" ? null : address_line1,
    address_line2: address_line2 === "" ? null : address_line2,
    address_city: address_city === "" ? null : address_city,
    address_state: address_state === "" ? null : address_state,
    address_zip_code: address_zip_code === "" ? null : address_zip_code,
  },
});

export const getCommonReqObj = (valuesObj, typeVal) => ({
  beneficiary_name:
    valuesObj.beneficiary_name === "" ? null : valuesObj.beneficiary_name,
  sender_name: valuesObj.sender_name === "" ? null : valuesObj.sender_name,
  description: valuesObj.description === "" ? null : valuesObj.description,
  currency: valuesObj.currency === "" ? null : valuesObj.currency,
  bank_country: valuesObj.bank_country === "" ? null : valuesObj.bank_country,
  bank_name: valuesObj.bank_name === "" ? null : valuesObj.bank_name,
  bank_code: valuesObj.bank_code === "" ? null : valuesObj.bank_code,
  account_number:
    valuesObj.account_number === "" || valuesObj.account_number == null
      ? null
      : valuesObj.account_number.trim(),
  bsb:
    valuesObj.bsb === "" || valuesObj.bsb === null
      ? null
      : valuesObj.bsb.trim(),
  bic_swift: valuesObj.bic_swift === "" ? null : valuesObj.bic_swift,
  iban: valuesObj.iban === "" ? null : valuesObj.iban,
  aba: valuesObj.aba === "" ? null : valuesObj.aba,
  sort_code: valuesObj.sort_code === "" ? null : valuesObj.sort_code,
  pay_id: valuesObj.pay_id === "" ? null : valuesObj.pay_id,
  pay_id_type: valuesObj.pay_id_type === "" ? null : valuesObj.pay_id_type,
  proxy_type: valuesObj.proxy_type === "" ? null : valuesObj.proxy_type,
  proxy_value: valuesObj.proxy_value === "" ? null : valuesObj.proxy_value,
  organization_reference_id:
    valuesObj.organization_reference_id === ""
      ? null
      : valuesObj.organization_reference_id,
  type: typeVal,
  company:
    typeVal === "company"
      ? {
          name: valuesObj.company_name !== "" ? valuesObj.company_name : null,
          registration_number:
            valuesObj.company_registration_number !== ""
              ? valuesObj.company_registration_number
              : null,
          phone_country_code:
            valuesObj.company_phone_country_code !== ""
              ? valuesObj.company_phone_country_code
              : null,
          phone_number:
            valuesObj.company_phone_number !== ""
              ? valuesObj.company_phone_number
              : null,
          address_country:
            valuesObj.company_address_country === ""
              ? null
              : valuesObj.company_address_country,
          address_state:
            valuesObj.company_address_state === ""
              ? null
              : valuesObj.company_address_state,
          address_city:
            valuesObj.company_address_city === ""
              ? null
              : valuesObj.company_address_city,
          address_line1:
            valuesObj.company_address_line1 === ""
              ? null
              : valuesObj.company_address_line1,
          address_line2:
            valuesObj.company_address_line2 === ""
              ? null
              : valuesObj.company_address_line2,
          address_zip_code:
            valuesObj.company_address_zip_code === ""
              ? null
              : valuesObj.company_address_zip_code,
        }
      : null,
  individual:
    typeVal === "individual"
      ? {
          first_name: valuesObj.first_name === "" ? null : valuesObj.first_name,
          last_name: valuesObj.last_name === "" ? null : valuesObj.last_name,
          dob: valuesObj.dob === "" ? null : valuesObj.dob,
          email: valuesObj.email === "" ? null : valuesObj.email,
          nationality:
            valuesObj.nationality === "" ? null : valuesObj.nationality,
          address_line1:
            valuesObj.address_line1 === "" ? null : valuesObj.address_line1,
          address_line2:
            valuesObj.address_line2 === "" ? null : valuesObj.address_line2,
          address_city:
            valuesObj.address_city === "" ? null : valuesObj.address_city,
          address_state:
            valuesObj.address_state === "" ? null : valuesObj.address_state,
          address_country:
            valuesObj.address_country === "" ? null : valuesObj.address_country,
          address_zip_code:
            valuesObj.address_zip_code === ""
              ? null
              : valuesObj.address_zip_code,
          country_of_residence:
            valuesObj.country_of_residence === ""
              ? null
              : valuesObj.country_of_residence,
          phone_country_code:
            valuesObj.phone_country_code === ""
              ? null
              : valuesObj.phone_country_code,
          phone_number:
            valuesObj.phone_number === "" ? null : valuesObj.phone_number,
          identification_type:
            valuesObj.identification_type === ""
              ? null
              : valuesObj.identification_type,
          identification_custom_type:
            valuesObj.identification_custom_type === ""
              ? null
              : valuesObj.identification_custom_type,
          identification_value:
            valuesObj.identification_value === ""
              ? null
              : valuesObj.identification_value,
        }
      : null,
});

export const getSenderReqObj = (valuesObj, typeVal) => ({
  sender_name: valuesObj.sender_name === "" ? null : valuesObj.sender_name,
  description: valuesObj.description === "" ? null : valuesObj.description,
  currency: valuesObj.currency === "" ? null : valuesObj.currency,
  organization_reference_id:
    valuesObj.organization_reference_id === ""
      ? null
      : valuesObj.organization_reference_id,
  type: typeVal,
  company:
    typeVal === "company"
      ? {
          name: valuesObj.company_name !== "" ? valuesObj.company_name : null,
          registration_number:
            valuesObj.company_registration_number !== ""
              ? valuesObj.company_registration_number
              : null,
          phone_country_code:
            valuesObj.company_phone_country_code !== ""
              ? valuesObj.company_phone_country_code
              : null,
          phone_number:
            valuesObj.company_phone_number !== ""
              ? valuesObj.company_phone_number
              : null,
          address_country:
            valuesObj.company_address_country === ""
              ? null
              : valuesObj.company_address_country,
          address_state:
            valuesObj.company_address_state === ""
              ? null
              : valuesObj.company_address_state,
          address_city:
            valuesObj.company_address_city === ""
              ? null
              : valuesObj.company_address_city,
          address_line1:
            valuesObj.company_address_line1 === ""
              ? null
              : valuesObj.company_address_line1,
          address_line2:
            valuesObj.company_address_line2 === ""
              ? null
              : valuesObj.company_address_line2,
          address_zip_code:
            valuesObj.company_address_zip_code === ""
              ? null
              : valuesObj.company_address_zip_code,
        }
      : null,
  individual:
    typeVal === "individual"
      ? {
          first_name: valuesObj.first_name === "" ? null : valuesObj.first_name,
          last_name: valuesObj.last_name === "" ? null : valuesObj.last_name,
          dob: valuesObj.dob === "" ? null : valuesObj.dob,
          email: valuesObj.email === "" ? null : valuesObj.email,
          nationality:
            valuesObj.nationality === "" ? null : valuesObj.nationality,
          address_line1:
            valuesObj.address_line1 === "" ? null : valuesObj.address_line1,
          address_line2:
            valuesObj.address_line2 === "" ? null : valuesObj.address_line2,
          address_city:
            valuesObj.address_city === "" ? null : valuesObj.address_city,
          address_state:
            valuesObj.address_state === "" ? null : valuesObj.address_state,
          address_country:
            valuesObj.address_country === "" ? null : valuesObj.address_country,
          address_zip_code:
            valuesObj.address_zip_code === ""
              ? null
              : valuesObj.address_zip_code,
          country_of_residence:
            valuesObj.country_of_residence === ""
              ? null
              : valuesObj.country_of_residence,
          phone_country_code:
            valuesObj.phone_country_code === ""
              ? null
              : valuesObj.phone_country_code,
          phone_number:
            valuesObj.phone_number === "" ? null : valuesObj.phone_number,
          identification_type:
            valuesObj.identification_type === ""
              ? null
              : valuesObj.identification_type,
          identification_custom_type:
            valuesObj.identification_custom_type === ""
              ? null
              : valuesObj.identification_custom_type,
          identification_value:
            valuesObj.identification_value === ""
              ? null
              : valuesObj.identification_value,
        }
      : null,
});

export const getPayoutReqObj = ({
  sender_country,
  sender_currency,
  sender_amount,
  beneficiary_amount,
  beneficiary_currency,
  payout_method_name,
  payout_method_param,
  payout_sender_id,
  sender_name,
  sender_bsb,
  sender_acc_no,
  payout_beneficiary_id,
  ben_name,
  ben_bsb,
  ben_acc_no,
  ben_payid_email,
  debit_wallet_id,
  fx_rate_id,
  metadata,
  organization_reference_id,
  webhook_url,
  purpose_code,
  receipt_email,
  description,
  payout_beneficiary_param,
  payout_sender_param,
  payout_reference,
}) => ({
  fx_rate_id: fx_rate_id ? fx_rate_id : null,
  sender_country: sender_country ? sender_country : null,
  sender_amount: sender_amount ? +sender_amount : null,
  beneficiary_amount: beneficiary_amount ? +beneficiary_amount : null,
  sender_currency: sender_currency ? sender_currency : null,
  beneficiary_currency: beneficiary_currency ? beneficiary_currency : null,
  description: description === "" ? null : description,
  payout_method_name,
  payout_reference: payout_reference ? payout_reference : null,
  payout_method_param: payout_method_param ? payout_method_param : null,
  payout_sender_id: payout_sender_id === "" ? null : payout_sender_id,
  payout_sender_param: payout_sender_param,
  payout_beneficiary_id:
    payout_beneficiary_id === "" ? null : payout_beneficiary_id,
  payout_beneficiary_param: payout_beneficiary_param,
  debit_wallet_id: debit_wallet_id === "" ? null : debit_wallet_id,
  organization_reference_id:
    organization_reference_id === "" ? null : organization_reference_id,
  purpose_code: purpose_code === "" ? null : purpose_code,
  webhook_url: webhook_url === "" ? null : webhook_url,
  receipt_email: receipt_email === "" ? null : receipt_email,
});

export const getCompanyReqObj = (valuesObj) => ({
  description: valuesObj.description === "" ? null : valuesObj.description,
  webhook_url: valuesObj.webhook_url === "" ? null : valuesObj.webhook_url,
  organization_reference_id:
    valuesObj.organization_reference_id === ""
      ? null
      : valuesObj.organization_reference_id,
  type: "company",
  company: {
    name: valuesObj.company_name,
    registration_number: valuesObj.company_registration_number,
    phone_country_code:
      valuesObj.company_phone_country_code !== ""
        ? valuesObj.company_phone_country_code
        : null,
    phone_number:
      valuesObj.company_phone_number !== ""
        ? valuesObj.company_phone_number
        : null,
    company_email:
      valuesObj.company_email === "" ? null : valuesObj.company_email,

    address_country:
      valuesObj.company_address_country === ""
        ? null
        : valuesObj.company_address_country,
    address_state:
      valuesObj.company_address_state === ""
        ? null
        : valuesObj.company_address_state,
    address_city:
      valuesObj.company_address_city === ""
        ? null
        : valuesObj.company_address_city,
    address_line1:
      valuesObj.company_address_line1 === ""
        ? null
        : valuesObj.company_address_line1,
    address_line2:
      valuesObj.company_address_line2 === ""
        ? null
        : valuesObj.company_address_line2,
    address_zip_code:
      valuesObj.company_address_zip_code === ""
        ? null
        : valuesObj.company_address_zip_code,
    incorporation_country:
      valuesObj.company_address_country === ""
        ? null
        : valuesObj.company_address_country,
    authorized_user: {
      first_name: valuesObj.first_name,
      last_name: valuesObj.last_name,
      email: valuesObj.email === "" ? null : valuesObj.email,
      nationality: valuesObj.nationality === "" ? null : valuesObj.nationality,
      phone_country_code:
        valuesObj.phone_country_code === ""
          ? null
          : valuesObj.phone_country_code,
      phone_number:
        valuesObj.phone_number === "" ? null : valuesObj.phone_number,
      identification_value:
        valuesObj.identification_value === ""
          ? null
          : valuesObj.identification_value,
      identification_type:
        valuesObj.identification_type === ""
          ? null
          : valuesObj.identification_type,
      identification_custom_type:
        valuesObj.identification_custom_type === ""
          ? null
          : valuesObj.identification_custom_type,
      dob: valuesObj.dob === "" ? null : valuesObj.dob,
      country_of_residence: valuesObj.country_of_residence,
      address_country:
        valuesObj.address_country === "" ? null : valuesObj.address_country,
      address_state:
        valuesObj.address_state === "" ? null : valuesObj.address_state,
      address_city:
        valuesObj.address_city === "" ? null : valuesObj.address_city,
      address_line1:
        valuesObj.address_line1 === "" ? null : valuesObj.address_line1,
      address_line2:
        valuesObj.address_line2 === "" ? null : valuesObj.address_line2,
      address_zip_code:
        valuesObj.address_zip_code === "" ? null : valuesObj.address_zip_code,
    },
  },
});

export const getWalletReqObj = ({
  category,
  customer_id,
  description,
  currency_list,
  merchant_customer_id,
}) => ({
  category,
  customer_id,
  description,
  currency_list: currency_list,
});

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getFilterQueryUrl = (filterQueryArr) =>
  filterQueryArr
    .filter(
      ({ filter_value, filter_key }) =>
        filter_key !== "" &&
        filter_value !== "" &&
        filter_key !== null &&
        filter_value !== null &&
        filter_key !== undefined &&
        filter_value !== undefined
    )
    .reduce((prevVal, currArrVal, currIdx, arr) => {
      const { filter_key, filter_value } = currArrVal;

      if (currIdx === arr.length - 1) {
        return `${prevVal}${filter_key}=${filter_value}`;
      } else {
        return prevVal + `${filter_key}=${filter_value}&`;
      }
    }, "");

export const getKybVerificationReqObj = (kybObj, rejection_field) => {
  const { shareholders, directors } = kybObj;

  if (
    !kybObj.auth_rep_identification_document_id &&
    !shareholders.length &&
    !directors.length
  ) {
    return null;
  }

  const verification_req = [];

  if (
    kybObj.auth_rep_identification_document_id &&
    (rejection_field === null ? true : rejection_field?.auth_rep?.length > 0)
  ) {
    verification_req.push({
      verification_entity_id: kybObj.auth_rep_id,
      verification_doc_type: "DOCUMENT",
      verification_entity: "AUTH_REP",
    });
  }

  shareholders.forEach((shareholder) => {
    if (
      !!shareholder.identification_document_id &&
      (rejection_field === null
        ? true
        : rejection_field?.shareholder?.[shareholder.org_shareholder_id]
            ?.length > 0)
    ) {
      verification_req.push({
        verification_entity_id: shareholder.org_shareholder_id,
        verification_doc_type: "DOCUMENT",
        verification_entity: "SHAREHOLDER",
      });
    }
  });

  directors.forEach((director) => {
    if (
      !!director.identification_document_id &&
      (rejection_field === null
        ? true
        : rejection_field?.director?.[director.org_director_id]?.length > 0)
    ) {
      verification_req.push({
        verification_entity_id: director.org_director_id,
        verification_doc_type: "DOCUMENT",
        verification_entity: "DIRECTOR",
      });
    }
  });

  return verification_req;
};

export const getKybScreeningReqObj = (values, kybDetails) => {
  const { shareholders, directors } = values;
  const org_id = kybDetails?.organization?.org_id;

  const screening_req = [];

  if (values.org_dba_name) {
    screening_req.push({
      screening_entity_id: org_id,
      screening_entity: "ORGANIZATION",
      screening_term: values.org_dba_name,
      filter_list: {
        country_codes: removeDuplicates([
          values.org_address_country,
          kybDetails?.organization?.country,
        ]),
      },
    });
  }

  if (values.auth_rep_first_name && values.auth_rep_last_name) {
    screening_req.push({
      screening_entity_id: values.auth_rep_id,
      screening_entity: "AUTH_REP",
      screening_term: `${values.auth_rep_first_name} ${values.auth_rep_last_name}`,
      filter_list: {
        birth_year: values.auth_rep_dob?.split("-")[0],
        country_codes: removeDuplicates([
          values.auth_rep_address_country,
          values.auth_rep_nationality,
        ]),
      },
    });
  }

  shareholders.forEach((shareholder) => {
    if (shareholder.first_name && shareholder.last_name) {
      screening_req.push({
        screening_entity_id: shareholder.org_shareholder_id,
        screening_entity: "SHAREHOLDER",
        screening_term: `${shareholder.first_name} ${shareholder.last_name}`,
        filter_list: {
          birth_year: shareholder.dob?.split("-")[0],
          country_codes: [shareholder.address_country],
        },
      });
    }
  });

  directors.forEach((director) => {
    if (director.first_name && director.last_name) {
      screening_req.push({
        screening_entity_id: director.org_director_id,
        screening_entity: "DIRECTOR",
        screening_term: `${director.first_name} ${director.last_name}`,
        filter_list: {
          country_codes: removeDuplicates([
            director.address_country,
            director.nationality,
          ]),
        },
      });
    }
  });

  return screening_req;
};

export const getKybRequestObj = (kybObj) => ({
  organization: {
    name: kybObj.org_name === "" ? null : kybObj.org_name,
    short_name: kybObj.org_short_name === "" ? null : kybObj.org_short_name,
    legal_name: kybObj.org_legal_name === "" ? null : kybObj.org_legal_name,
    dba_name: kybObj.org_dba_name === "" ? null : kybObj.org_dba_name,
    business_type: kybObj.business_type === "" ? null : kybObj.business_type,
    address_line1:
      kybObj.org_address_line1 === "" ? null : kybObj.org_address_line1,
    address_line2:
      kybObj.org_address_line2 === "" ? null : kybObj.org_address_line2,
    address_city:
      kybObj.org_address_city === "" ? null : kybObj.org_address_city,
    address_state:
      kybObj.org_address_state === "" ? null : kybObj.org_address_state,
    address_country:
      kybObj.org_address_country === "" ? null : kybObj.org_address_country,
    country:
      kybObj.org_address_country === "" ? null : kybObj.org_address_country,
    address_zip_code:
      kybObj.org_address_zip_code === "" ? null : kybObj.org_address_zip_code,
    org_timezone: kybObj.org_timezone === "" ? null : kybObj.org_timezone,
    is_abn_acn: kybObj.is_abn_acn === "" ? null : kybObj.is_abn_acn,
    acn: kybObj.acn === "" ? null : kybObj.acn,
    abn: kybObj.abn === "" ? null : kybObj.abn,
    referral_source: kybObj.referral_source || null,
    referred_by: kybObj.referred_by || null,
    source_of_funds: kybObj.org_source_of_funds || null,
    source_of_funds_custom: kybObj.org_source_of_funds_custom || null,
    expected_payin_country_list:
      kybObj.expected_payin_country_list.length === 0
        ? null
        : kybObj.expected_payin_country_list,
    expected_payout_country_list:
      kybObj.expected_payout_country_list.length === 0
        ? null
        : kybObj.expected_payout_country_list,

    expected_txn_count_per_month:
      kybObj.expected_txn_count_per_month === ""
        ? null
        : Number(kybObj.expected_txn_count_per_month),
    expected_txn_value_per_tx_usd:
      kybObj.expected_txn_value_per_tx_usd === ""
        ? null
        : Number(kybObj.expected_txn_value_per_tx_usd),
    account_usage_intent:
      kybObj.account_usage_intent.length === 0
        ? null
        : kybObj.account_usage_intent,
    account_usage_purpose:
      kybObj.account_usage_purpose === "" ? null : kybObj.account_usage_purpose,
    tax_registration_number:
      kybObj.org_tax_registration_number === ""
        ? null
        : kybObj.org_tax_registration_number,
    company_registration_number:
      kybObj.org_company_registration_number === ""
        ? null
        : kybObj.org_company_registration_number,
    industry_name:
      kybObj.org_industry_name === "" ? null : kybObj.org_industry_name,
    website_url: kybObj.org_website_url === "" ? null : kybObj.org_website_url,
    product_description:
      kybObj.org_product_description === ""
        ? null
        : kybObj.org_product_description,
    statement_descriptor:
      kybObj.org_statement_descriptor === ""
        ? null
        : kybObj.org_statement_descriptor,
    support_email_address:
      kybObj.org_support_email_address === ""
        ? null
        : kybObj.org_support_email_address,
    support_phone_country_code:
      kybObj.org_support_phone_country_code === ""
        ? null
        : kybObj.org_support_phone_country_code,
    support_phone_number:
      kybObj.org_support_phone_number === ""
        ? null
        : kybObj.org_support_phone_number,
    support_phone_number_e164:
      kybObj.org_support_phone_number_e164 === ""
        ? null
        : kybObj.org_support_phone_number_e164,
    notification_setting:
      kybObj.org_notification_setting === ""
        ? null
        : kybObj.org_notification_setting,
    company_registration_document_id:
      kybObj.company_registration_document_id === ""
        ? null
        : kybObj.company_registration_document_id,
    company_logo_id: kybObj.company_logo_id || null,
    company_address_proof_document_id:
      kybObj.company_address_proof_document_id === ""
        ? null
        : kybObj.company_address_proof_document_id,
    company_shareholding_structure_document_id:
      kybObj?.company_shareholding_structure_document_id === ""
        ? null
        : kybObj?.company_shareholding_structure_document_id,
    msa_signature_document_id_list:
      kybObj.msa_signature_document_id === "" ||
      kybObj.msa_signature_document_id === null
        ? []
        : [kybObj.msa_signature_document_id],
    additional_documents: kybObj?.additional_documents?.map((document) => ({
      field_id: document.field_id,
      doc_id: document.doc_id,
    })),
    is_licensed_fi:
      kybObj?.is_licensed_fi === "" ? null : kybObj?.is_licensed_fi,
    is_cfd_provider:
      kybObj?.is_cfd_provider === "" ? null : kybObj?.is_cfd_provider,
    aml_cft_policy_document_id:
      kybObj?.aml_cft_policy_document_id === ""
        ? null
        : kybObj?.aml_cft_policy_document_id,
    customer_onboarding_policy_document_id:
      kybObj?.customer_onboarding_policy_document_id === ""
        ? null
        : kybObj?.customer_onboarding_policy_document_id,
    license_document_id:
      kybObj?.license_document_id === "" ? null : kybObj?.license_document_id,
    client_suitability_test_document_id:
      kybObj?.client_suitability_test_document_id === ""
        ? null
        : kybObj?.client_suitability_test_document_id,
    risk_disclosures_document_id:
      kybObj?.risk_disclosures_document_id === ""
        ? null
        : kybObj?.risk_disclosures_document_id,
  },
  auth_rep: {
    first_name:
      kybObj.auth_rep_first_name === "" ? null : kybObj.auth_rep_first_name,
    last_name:
      kybObj.auth_rep_last_name === "" ? null : kybObj.auth_rep_last_name,
    dob: kybObj.auth_rep_dob === "" ? null : kybObj.auth_rep_dob,
    email: kybObj.auth_rep_email === "" ? null : kybObj.auth_rep_email,
    phone_country_code:
      kybObj.auth_rep_phone_country_code === ""
        ? null
        : kybObj.auth_rep_phone_country_code,
    phone_number:
      kybObj.auth_rep_phone_number === "" ? null : kybObj.auth_rep_phone_number,
    phone_number_e164:
      kybObj.auth_rep_phone_number_e164 === ""
        ? null
        : kybObj.auth_rep_phone_number_e164,
    nationality:
      kybObj.auth_rep_nationality === "" ? null : kybObj.auth_rep_nationality,
    identification_type:
      kybObj.auth_rep_identification_type === ""
        ? null
        : kybObj.auth_rep_identification_type,
    identification_value:
      kybObj.auth_rep_identification_value === ""
        ? null
        : kybObj.auth_rep_identification_value,
    identification_custom_type:
      kybObj.auth_rep_identification_custom_type === ""
        ? null
        : kybObj.auth_rep_identification_custom_type,
    address_line1:
      kybObj.auth_rep_address_line1 === ""
        ? null
        : kybObj.auth_rep_address_line1,
    address_line2:
      kybObj.auth_rep_address_line2 === ""
        ? null
        : kybObj.auth_rep_address_line2,
    address_city:
      kybObj.auth_rep_address_city === "" ? null : kybObj.auth_rep_address_city,
    address_state:
      kybObj.auth_rep_address_state === ""
        ? null
        : kybObj.auth_rep_address_state,
    address_country:
      kybObj.auth_rep_address_country === ""
        ? null
        : kybObj.auth_rep_address_country,
    address_zip_code:
      kybObj.auth_rep_address_zip_code === ""
        ? null
        : kybObj.auth_rep_address_zip_code,
    identification_document_id:
      kybObj.auth_rep_identification_document_id === ""
        ? null
        : kybObj.auth_rep_identification_document_id,
    address_proof_document_id:
      kybObj.auth_rep_address_proof_document_id === ""
        ? null
        : kybObj.auth_rep_address_proof_document_id,
  },
  shareholder: kybObj.shareholders.map((shareholder) => ({
    first_name: shareholder.first_name === "" ? null : shareholder.first_name,
    last_name: shareholder.last_name === "" ? null : shareholder.last_name,
    dob: shareholder.dob === "" ? null : shareholder.dob,
    email: shareholder.email === "" ? null : shareholder.email,
    shareholder_percentage:
      shareholder.shareholder_percentage === null
        ? null
        : shareholder.shareholder_percentage >= 0 &&
          shareholder.shareholder_percentage <= 100
        ? Number(shareholder.shareholder_percentage)
        : null,
    is_owner_of_over_25_percent:
      shareholder.is_owner_of_over_25_percent === ""
        ? null
        : shareholder.is_owner_of_over_25_percent,
    phone_country_code:
      shareholder.phone_country_code === ""
        ? null
        : shareholder.phone_country_code,
    phone_number:
      shareholder.phone_number === "" ? null : shareholder.phone_number,
    phone_number_e164:
      shareholder.phone_number_e164 === ""
        ? null
        : shareholder.phone_number_e164,
    nationality:
      shareholder.nationality === "" ? null : shareholder.nationality,
    identification_type:
      shareholder.identification_type === ""
        ? null
        : shareholder.identification_type,
    identification_custom_type:
      shareholder.identification_custom_type === ""
        ? null
        : shareholder.identification_custom_type,
    identification_value:
      shareholder.identification_value === ""
        ? null
        : shareholder.identification_value,
    identification_document_id:
      shareholder.identification_document_id === ""
        ? null
        : shareholder.identification_document_id,
    address_proof_document_id:
      shareholder.address_proof_document_id === ""
        ? null
        : shareholder.address_proof_document_id,
  })),
  director: kybObj.directors.map((director) => ({
    first_name: director.first_name || null,
    last_name: director.last_name || null,
    email: director.email || null,
    phone_country_code: director.phone_country_code || null,
    phone_number: director.phone_number || null,
    phone_number_e164: director.phone_number_e164 || null,
    nationality: director.nationality || null,
    address_line1: director.address_line1 || null,
    address_line2: director.address_line2 || null,
    address_city: director.address_city || null,
    address_state: director.address_state || null,
    address_country: director.address_country || null,
    address_zip_code: director.address_zip_code || null,
    identification_type: director.identification_type || null,
    identification_custom_type: director.identification_custom_type || null,
    identification_value: director.identification_value || null,
    identification_document_id: director.identification_document_id || null,
  })),
  settlement_bank: [
    {
      account_holder_name:
        kybObj.settlement_bank?.account_holder_name === ""
          ? null
          : kybObj.settlement_bank?.account_holder_name,
      type: "company",
      settlement_bank_display_name:
        kybObj.settlement_bank?.settlement_bank_display_name === ""
          ? null
          : kybObj.settlement_bank?.settlement_bank_display_name,
      currency:
        kybObj.settlement_bank?.currency === ""
          ? null
          : kybObj.settlement_bank?.currency,
      bank_country:
        kybObj.settlement_bank?.bank_country === ""
          ? null
          : kybObj.settlement_bank?.bank_country,
      bank_name:
        kybObj.settlement_bank?.bank_name === ""
          ? null
          : kybObj.settlement_bank?.bank_name,
      account_number:
        kybObj.settlement_bank?.account_number === ""
          ? null
          : kybObj.settlement_bank?.account_number,
      bsb:
        kybObj.settlement_bank?.bsb === "" ? null : kybObj.settlement_bank?.bsb,
      bic_swift:
        kybObj.settlement_bank?.bic_swift === ""
          ? null
          : kybObj.settlement_bank?.bic_swift,
      iban:
        kybObj.settlement_bank?.iban === ""
          ? null
          : kybObj.settlement_bank?.iban,
      aba:
        kybObj.settlement_bank?.aba === "" ? null : kybObj.settlement_bank?.aba,
      sort_code:
        kybObj.settlement_bank?.sort_code === ""
          ? null
          : kybObj.settlement_bank?.sort_code,
      pay_id:
        kybObj.settlement_bank?.pay_id === ""
          ? null
          : kybObj.settlement_bank?.pay_id,
      pay_id_type:
        kybObj.settlement_bank?.pay_id_type === ""
          ? null
          : kybObj.settlement_bank?.pay_id_type,
      payout_method_id:
        kybObj.settlement_bank?.payout_method_id === ""
          ? null
          : kybObj.settlement_bank?.payout_method_id,
      company: {
        name:
          kybObj.settlement_bank?.company?.name === ""
            ? null
            : kybObj.settlement_bank?.company?.name,
        registration_number:
          kybObj.settlement_bank?.company?.registration_number === ""
            ? null
            : kybObj.settlement_bank?.company?.registration_number,
        incorporation_country:
          kybObj.settlement_bank?.company?.incorporation_country === ""
            ? null
            : kybObj.settlement_bank?.company?.incorporation_country,
        address_country:
          kybObj.settlement_bank?.company?.address_country === ""
            ? null
            : kybObj.settlement_bank?.company?.address_country,
        address_state:
          kybObj.settlement_bank?.company?.address_state === ""
            ? null
            : kybObj.settlement_bank?.company?.address_state,
        address_city:
          kybObj.settlement_bank?.company?.address_city === ""
            ? null
            : kybObj.settlement_bank?.company?.address_city,
        address_line1:
          kybObj.settlement_bank?.company?.address_line1 === ""
            ? null
            : kybObj.settlement_bank?.company?.address_line1,
        address_line2:
          kybObj.settlement_bank?.company?.address_line2 === ""
            ? null
            : kybObj.settlement_bank?.company?.address_line2,
        address_zip_code:
          kybObj.settlement_bank?.company?.address_zip_code === ""
            ? null
            : kybObj.settlement_bank?.company?.address_zip_code,
        phone_number:
          kybObj.settlement_bank?.company?.phone_number === ""
            ? null
            : kybObj.settlement_bank?.company?.phone_number,
        phone_country_code:
          kybObj.settlement_bank?.company?.phone_country_code === ""
            ? null
            : kybObj.settlement_bank?.company?.phone_country_code,
        phone_number_e164:
          kybObj.settlement_bank?.company?.phone_number_e164 === ""
            ? null
            : kybObj.settlement_bank?.company?.phone_number_e164,
        company_email:
          kybObj.settlement_bank?.company?.company_email === ""
            ? null
            : kybObj.settlement_bank?.company?.company_email,
      },
      bank_statement_document_id:
        kybObj.sbank_bank_statement_document_id === ""
          ? null
          : kybObj.sbank_bank_statement_document_id,
    },
  ],
  kyb: {
    signature_initials:
      kybObj.signature_initials === "" ? null : kybObj.signature_initials,
    signature_timestamp: Math.floor(new Date().getTime() / 1000),
  },
});

export const verifyDocSizeType = (e, fileSize = 2000000) => {
  if (
    e.target.files[0] &&
    (e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg")
  ) {
    if (Number(e.target.files[0].size) >= fileSize) {
      toastNotification(
        "error",
        `File size should not be more than ${
          fileSize === 2000000 ? "2MB" : "4MB"
        }`,
        2000
      );
      return false;
    }
  } else if (e.target.value !== "") {
    toastNotification(
      "error",
      "DOC format should be in PDF/JPEG/PNG/JPG",
      2000
    );
    return false;
  }

  return true;
};

export const getSettlementReqObj = (bankObj) => ({
  currency: bankObj.sbank_currency === "" ? null : bankObj.sbank_currency,
  bank_country:
    bankObj.sbank_bank_country === "" ? null : bankObj.sbank_bank_country,
  bank_name: bankObj.sbank_bank_name === "" ? null : bankObj.sbank_bank_name,
  account_number:
    bankObj.sbank_account_number === "" ? null : bankObj.sbank_account_number,
  account_holder_name:
    bankObj.sbank_account_holder_name === ""
      ? null
      : bankObj.sbank_account_holder_name,
  bsb: bankObj.sbank_bsb === "" ? null : bankObj.sbank_bsb,
  bic_swift: bankObj.sbank_bic_swift === "" ? null : bankObj.sbank_bic_swift,
  iban: bankObj.sbank_iban === "" ? null : bankObj.sbank_iban,
  aba: bankObj.sbank_aba === "" ? null : bankObj.sbank_aba,
  sort_code: bankObj.sbank_sort_code === "" ? null : bankObj.sbank_sort_code,
  bank_statement_document_id:
    bankObj.sbank_bank_statement_document_id === ""
      ? null
      : bankObj.sbank_bank_statement_document_id,
});

export const getOrgPeerReqObj = (obj) => ({
  first_name: obj.first_name,
  last_name: obj.last_name,
  email: obj.email,
  is_admin: obj.is_admin,
  is_developer: obj.is_developer,
});

export const convertToDays = (totalSeconds) => {
  const format = ["years", "months", "weeks", "days", "hours", "minutes"];
  const duration = intervalToDuration({ start: 0, end: totalSeconds * 1000 });
  return formatDuration(duration, { format: format, delimiter: ", " });
};

export const getCurrencyFlagPath = (currency) => {
  try {
    return require(`../assests/images/currencyflags/${currency.toLowerCase()}.png`)
      .default;
  } catch (err) {
    return null;
  }
};

export const getSideDrawerEventLink = (event, event_id) => {
  let url = "";
  if (event && event_id && dashboard_routes[route_name[event]]) {
    if (
      event === route_name["VIRTUAL_ACCOUNT"] ||
      event === route_name["WALLET"] ||
      event === route_name["SETTLEMENT_PAYOUT"] ||
      event === route_name["SETTLEMENT"]
    ) {
      url = `${dashboard_routes[route_name[event]]}/${event_id}`;
    } else {
      url = `${
        dashboard_routes[route_name[event]]
      }?${event.toLowerCase()}_id=${event_id}`;
    }
  }

  return url;
};

export const getMT103Details = (swift) => {
  if (typeof swift === "string") {
    const data = getMT103Data(swift);
    if (data) {
      return data;
    }
  }
};

export const setCustomColumnSettings = (columns, setTableCustomColumnData) => {
  setTableCustomColumnData((prevState) => {
    return prevState.map((prevStateObj) => {
      if (columns?.includes(prevStateObj.columnName)) {
        return { ...prevStateObj, isVisible: true };
      } else {
        return { ...prevStateObj, isVisible: false };
      }
    });
  });
};

export const createCustomColumnSettings = async (
  columns,
  column_setting_key,
  setTableCustomColumnData
) => {
  setTableCustomColumnData(columns);
  let data = [];
  columns.forEach((item) => {
    if (item.isVisible) {
      data.push(item.columnName);
    }
  });
  try {
    await createOrganizationSetting("all", {
      key: column_setting_key,
      scope: "PARTNER_USER",
      value: data,
    });
  } catch (err) {}
};

export const formatTimeFilterData = (start_time, end_time) => {
  const dateFilter = [];

  if (start_time) {
    dateFilter.push({
      filter_key: "start_time",
      filter_value: Math.floor(start_time.getTime() / 1000),
    });
  }
  if (end_time) {
    dateFilter.push({
      filter_key: "end_time",
      filter_value: Math.floor(end_time.getTime() / 1000),
    });
  }

  return dateFilter;
};

export const getISOformatString = (value) => {
  return `${formatISO(new Date(value), {
    representation: "complete",
  })
    .split(":")
    .slice(0, 2)
    .join(":")}:00Z`;
};

export const removeDuplicates = (arr) => {
  const set = new Set(arr);
  let unique_arr = Array.from(set);
  return unique_arr;
};

export const isObjNull = (obj) =>
  Object.values(obj).every((doc) => {
    if (doc === null) {
      return true;
    }
    return false;
  });

export const downloadDoc = async (org_id, doc_id) => {
  try {
    const { success, data } = await getSingleDoc(org_id, doc_id);
    if (success) {
      window.open(data.download_url, "_blank");
    }
  } catch (err) {
    toastNotification("error", apiErrorMessage(err), 2000);
  }
};

export const matchValue = (val1, val2) => {
  let value1;
  let value2;
  if (val1 && val2) {
    value1 = val1.toLowerCase();
    value2 = val2.toLowerCase();
    if (value1 == value2) {
      return <Label color={"success"}>Matched</Label>;
    }
  }
  return <Label color={"error"}>Unmatched</Label>;
};
