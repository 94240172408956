import PropTypes from "prop-types";
import { experimentalStyled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import Sidebar from "./Sidebar";
import Header from "./Header";

const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        background: "#F2F6FF";
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.sidebar.width};
        }
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
        padding: ${theme.spacing(13.25, 9.6, 6, 8.3)}
`
);

const SidebarLayout = ({ children }) => {
  const is_bo_admin_login = localStorage.getItem("is_bo_admin_login") || false;

  return (
    <>
      {is_bo_admin_login && (
        <Box
          sx={{
            position: "fixed",
            zIndex: 999,
            width: "100vw",
          }}
          icon={false}
        >
          <Typography
            fontWeight={500}
            sx={{
              color: "#fff",
              py: 0.5,
              background: "#FFA31A",
            }}
            fontSize={11}
            align="center"
          >
            This is a user-impersonated session. Please be careful..
          </Typography>
        </Box>
      )}
      <Sidebar />
      <MainWrapper>
        <Header />
        <MainContent>{children}</MainContent>
      </MainWrapper>
    </>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.node,
};

export default SidebarLayout;
