export const DATE_CONVERSION_FORMAT = "EEE, dd MMM yyyy, ppp";
export const LIMIT_OFFSET = 1000000;
export const PASSWORD_ERROR_TEXT =
  "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character";
export const VALID_URL_REGEX =
  /((ftp|http(s)?):\/\/)?(www\.)?(wss?:\/\/)?([a-z]{2,10}?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@%+\/'!#$^?:,(){}[\]~*&\-_.`=|;"<>\s]).{8,50}$/;
export const SITE_KEY = "6LfHyBsjAAAAAMXE3PmDco3BAz5IZWVwzEUpHfQD";

export const AL_TEMPLATE_DOWNLOAD_URL =
  "https://finmo-assets.s3.ap-southeast-2.amazonaws.com/partner/authorization-letter/Authorization+Letter+Template.pdf";

export const FINMO_LOGO_URL =
  "https://finmo-assets.s3.ap-southeast-2.amazonaws.com/FINMO_New_Logo_Full_Color.png";

export const FINMO_LOGOS = {
  finmo_white_url:
    "https://finmo-assets.s3.ap-southeast-2.amazonaws.com/FINMO_Logo_Full_White.png",
  finmo_colored_url:
    "https://finmo-assets.s3.ap-southeast-2.amazonaws.com/FINMO_New_Logo_Full_Color.png",
};

export const SEARCHBOX_ROUTE_LIST = [
  {
    label: "Account Details",
    rbac: "read_only",
    link: "/preview/dashboards/account-detail?section=wallet",
  },
  {
    label: "Create Payment Link (Checkout)",
    link: "/preview/dashboards/checkout",
  },
  {
    label: "Create Virtual Account",
    link: "/preview/dashboards/create-virtual-account",
  },
  {
    label: "Create New Customer",
    link: "/preview/dashboards/new-customer",
  },
  {
    label: "Create Wallet Fund Transfer",
    link: "/preview/dashboards/create-wallet-fund-transfer",
  },

  {
    label: "Create Local Payout",
    link: "/preview/dashboards/add-payout/local",
  },
  {
    label: "Home",
    rbac: "read_only",
    link: "/preview/dashboards/analytics",
  },
  {
    label: "Buisness Details",
    rbac: "read_only",
    link: "/preview/dashboards/business-detail",
  },
  {
    label: "Pricing",
    rbac: "read_only",
    link: "/preview/dashboards/pricing",
  },
  {
    label: "Payment Method Config",
    rbac: "read_only",
    link: "/preview/dashboards/payment-method-setting",
  },
  {
    label: "All Users",
    rbac: "read_only",
    link: "/preview/dashboards/users",
  },
  {
    label: "Add New User",
    link: "/preview/dashboards/add-user",
  },
  {
    label: "All Payins",
    rbac: "read_only",
    link: "/preview/dashboards/payments",
  },
  {
    label: "All Virtual Accounts",
    rbac: "read_only",
    link: "/preview/dashboards/virtual-accounts",
  },
  {
    label: "All Refunds",
    rbac: "read_only",
    link: "/preview/dashboards/refunds",
  },
  {
    label: "All Checkout Sessions",
    rbac: "read_only",
    link: "/preview/dashboards/sessions",
  },
  {
    label: "All Wallet Fund Transfers",
    rbac: "read_only",
    link: "/preview/dashboards/wallet-fund-transfer",
  },
  {
    label: "All Transactions",
    rbac: "read_only",
    link: "/preview/dashboards/transactions",
  },
  {
    label: "Settings",
    rbac: "read_only",
    link: "/preview/dashboards/setting",
  },
  {
    label: "API keys",
    rbac: "read_only",
    link: "/preview/dashboards/setting/developer/api",
  },
  {
    label: "PayIn Top-Up",
    link: "/preview/dashboards/account-detail?section=wallet",
  },
  {
    label: "Payout Top-Up",
    link: "/preview/dashboards/account-detail?section=wallet",
  },
  {
    label: "MSA",
    rbac: "read_only",
    link: "/preview/dashboards/msa",
  },
  {
    label: "New User Wallet",
    link: "/preview/dashboards/create-wallet",
  },
  {
    label: "All Wallets",
    rbac: "read_only",
    link: "/preview/dashboards/wallets",
  },
  {
    label: "All Wallet Transactions",
    rbac: "read_only",
    link: "/preview/dashboards/wallets/transaction",
  },
  {
    label: "All Customer Level Transactions",
    rbac: "read_only",
    link: "/preview/dashboards/transaction/customerlevel",
  },
  {
    label: "All Customers",
    rbac: "read_only",
    link: "/preview/dashboards/customers",
  },
  {
    label: "All Org Level Transactions",
    rbac: "read_only",
    link: "/preview/dashboards/transaction/orglevel",
  },
  {
    label: "Settlements",
    rbac: "read_only",
    link: "/preview/dashboards/settlements",
  },
  {
    label: "All Invoices",
    rbac: "read_only",
    link: "/preview/dashboards/invoices?section=ALL",
  },
  {
    label: "All Reports",
    rbac: "read_only",
    link: "/preview/dashboards/report",
  },
  {
    label: "All Adjustments",
    rbac: "read_only",
    link: "/preview/dashboards/adjustments",
  },
  {
    label: "Webhooks",
    rbac: "read_only",
    link: "/preview/dashboards/setting/developer/webhook-url",
  },
  {
    label: "IP Whitelisting",
    rbac: "read_only",
    link: "/preview/dashboards/setting/security/iplisting",
  },
  {
    label: "Configure Logo",
    rbac: "read_only",
    link: "/preview/dashboards/setting/security/iplisting",
  },
  {
    label: "Checkout Custom Theme",
    rbac: "read_only",
    link: "/preview/dashboards/setting/customizedcheckout/customtheme",
  },
  {
    label: "Transaction Notification Settings",
    rbac: "read_only",
    link: "/preview/dashboards/setting/notifications",
  },
  {
    label: "Webhook Custom Header",
    rbac: "read_only",
    link: "/preview/dashboards/setting/webhook-custom-header",
  },
  {
    label: "API Reference (Docs)",
    rbac: "read_only",
    link: "https://docs.finmo.net/",
  },
];
export const country_names = [
  {
    name: "Aland Islands",
    code: "AX",
  },
  // {
  //   name: "Albania",
  //   code: "AL",
  // },
  {
    name: "Algeria",
    code: "DZ",
  },
  {
    name: "American Samoa",
    code: "AS",
  },
  {
    name: "Andorra",
    code: "AD",
  },
  {
    name: "Angola",
    code: "AO",
  },
  {
    name: "Anguilla",
    code: "AI",
  },
  {
    name: "Antarctica",
    code: "AQ",
  },
  {
    name: "Antigua And Barbuda",
    code: "AG",
  },
  {
    name: "Argentina",
    code: "AR",
  },
  {
    name: "Armenia",
    code: "AM",
  },
  {
    name: "Aruba",
    code: "AW",
  },
  {
    name: "Australia",
    code: "AU",
  },
  {
    name: "Austria",
    code: "AT",
  },
  // {
  //   name: "Azerbaijan",
  //   code: "AZ",
  // },
  {
    name: "Bahamas",
    code: "BS",
  },
  {
    name: "Bahrain",
    code: "BH",
  },
  {
    name: "Bangladesh",
    code: "BD",
  },
  {
    name: "Barbados",
    code: "BB",
  },
  {
    name: "Belgium",
    code: "BE",
  },
  {
    name: "Belize",
    code: "BZ",
  },
  {
    name: "Benin",
    code: "BJ",
  },
  {
    name: "Bermuda",
    code: "BM",
  },
  {
    name: "Bhutan",
    code: "BT",
  },
  {
    name: "Bolivia",
    code: "BO",
  },
  // {
  //   name: "Bosnia And Herzegovina",
  //   code: "BA",
  // },
  {
    name: "Botswana",
    code: "BW",
  },
  {
    name: "Bouvet Island",
    code: "BV",
  },
  {
    name: "Brazil",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    code: "BN",
  },
  {
    name: "Bulgaria",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    code: "BF",
  },
  {
    name: "Cambodia",
    code: "KH",
  },
  {
    name: "Cameroon",
    code: "CM",
  },
  {
    name: "Canada",
    code: "CA",
  },
  {
    name: "Cape Verde",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    code: "KY",
  },
  {
    name: "Chad",
    code: "TD",
  },
  {
    name: "Chile",
    code: "CL",
  },
  {
    name: "China",
    code: "CN",
  },
  {
    name: "Christmas Island",
    code: "CX",
  },
  {
    name: "Cocos (keeling) Islands",
    code: "CC",
  },
  {
    name: "Colombia",
    code: "CO",
  },
  {
    name: "Comoros",
    code: "KM",
  },
  {
    name: "Cook Islands",
    code: "CK",
  },
  {
    name: "Costa Rica",
    code: "CR",
  },
  {
    name: 'Cote D"ivoire',
    code: "CI",
  },
  // {
  //   name: "Croatia",
  //   code: "HR",
  // },
  {
    name: "Cyprus",
    code: "CY",
  },
  { name: "Curacao", code: "CW" },

  {
    name: "Czech Republic",
    code: "CZ",
  },
  {
    name: "Denmark",
    code: "DK",
  },
  {
    name: "Djibouti",
    code: "DJ",
  },
  {
    name: "Dominica",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    code: "DO",
  },
  {
    name: "Ecuador",
    code: "EC",
  },
  {
    name: "Egypt",
    code: "EG",
  },
  {
    name: "El Salvador",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
  },
  // {
  //   name: "Eritrea",
  //   code: "ER",
  // },
  {
    name: "Estonia",
    code: "EE",
  },
  // {
  //   name: "Ethiopia",
  //   code: "ET",
  // },
  {
    name: "Falkland Islands (malvinas)",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    code: "FO",
  },
  {
    name: "Fiji",
    code: "FJ",
  },
  {
    name: "Finland",
    code: "FI",
  },
  {
    name: "France",
    code: "FR",
  },
  {
    name: "French Guiana",
    code: "GF",
  },
  {
    name: "French Polynesia",
    code: "PF",
  },
  {
    name: "French Southern Territories",
    code: "TF",
  },
  {
    name: "Gabon",
    code: "GA",
  },
  {
    name: "Gambia",
    code: "GM",
  },
  {
    name: "Georgia",
    code: "GE",
  },
  {
    name: "Germany",
    code: "DE",
  },
  {
    name: "Ghana",
    code: "GH",
  },
  {
    name: "Gibraltar",
    code: "GI",
  },
  {
    name: "Greece",
    code: "GR",
  },
  {
    name: "Greenland",
    code: "GL",
  },
  {
    name: "Grenada",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    code: "GP",
  },
  {
    name: "Guam",
    code: "GU",
  },
  {
    name: "Guatemala",
    code: "GT",
  },
  {
    name: "Guernsey",
    code: "GG",
  },
  {
    name: "Guinea",
    code: "GN",
  },
  // {
  //   name: "Guinea-bissau",
  //   code: "GW",
  // },
  {
    name: "Guyana",
    code: "GY",
  },
  // {
  //   name: "Haiti",
  //   code: "HT",
  // },
  {
    name: "Heard Island & Mcdonald Islands",
    code: "HM",
  },
  {
    name: "Holy See (vatican City State)",
    code: "VA",
  },
  {
    name: "Honduras",
    code: "HN",
  },
  {
    name: "Hong Kong",
    code: "HK",
  },
  {
    name: "Hungary",
    code: "HU",
  },
  {
    name: "Iceland",
    code: "IS",
  },
  {
    name: "India",
    code: "IN",
  },
  {
    name: "Indonesia",
    code: "ID",
  },
  {
    name: "Ireland",
    code: "IE",
  },
  {
    name: "Isle Of Man",
    code: "IM",
  },
  {
    name: "Israel",
    code: "IL",
  },
  {
    name: "Italy",
    code: "IT",
  },
  {
    name: "Jamaica",
    code: "JM",
  },
  {
    name: "Japan",
    code: "JP",
  },
  {
    name: "Jersey",
    code: "JE",
  },
  {
    name: "Jordan",
    code: "JO",
  },
  // {
  //   name: "Kazakhstan",
  //   code: "KZ",
  // },
  {
    name: "Kenya",
    code: "KE",
  },
  {
    name: "Kiribati",
    code: "KI",
  },
  {
    name: "Korea",
    code: "KR",
  },
  {
    name: "Kuwait",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
  },
  {
    name: 'Lao People"s Democratic Republic',
    code: "LA",
  },
  {
    name: "Latvia",
    code: "LV",
  },
  {
    name: "Lesotho",
    code: "LS",
  },
  {
    name: "Liechtenstein",
    code: "LI",
  },
  {
    name: "Lithuania",
    code: "LT",
  },
  {
    name: "Luxembourg",
    code: "LU",
  },
  {
    name: "Macao",
    code: "MO",
  },
  // {
  //   name: "Macedonia",
  //   code: "MK",
  // },
  {
    name: "Madagascar",
    code: "MG",
  },
  {
    name: "Malawi",
    code: "MW",
  },
  {
    name: "Malaysia",
    code: "MY",
  },
  {
    name: "Maldives",
    code: "MV",
  },
  {
    name: "Mali",
    code: "ML",
  },
  {
    name: "Malta",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    code: "MH",
  },
  {
    name: "Martinique",
    code: "MQ",
  },
  {
    name: "Mauritania",
    code: "MR",
  },
  {
    name: "Mauritius",
    code: "MU",
  },
  {
    name: "Mayotte",
    code: "YT",
  },
  {
    name: "Mexico",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States Of",
    code: "FM",
  },
  {
    name: "Moldova",
    code: "MD",
  },
  {
    name: "Monaco",
    code: "MC",
  },
  {
    name: "Mongolia",
    code: "MN",
  },
  {
    name: "Montenegro",
    code: "ME",
  },
  {
    name: "Montserrat",
    code: "MS",
  },
  {
    name: "Morocco",
    code: "MA",
  },
  {
    name: "Mozambique",
    code: "MZ",
  },
  // {
  //   name: "Myanmar",
  //   code: "MM",
  // },
  {
    name: "Namibia",
    code: "NA",
  },
  {
    name: "Nauru",
    code: "NR",
  },
  {
    name: "Nepal",
    code: "NP",
  },
  {
    name: "Netherlands",
    code: "NL",
  },
  // {
  //   name: "Netherlands Antilles",
  //   code: "AN",
  // },
  {
    name: "New Caledonia",
    code: "NC",
  },
  {
    name: "New Zealand",
    code: "NZ",
  },
  // {
  //   name: "Nicaragua",
  //   code: "NI",
  // },
  {
    name: "Niger",
    code: "NE",
  },
  {
    name: "Nigeria",
    code: "NG",
  },
  {
    name: "Niue",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
  },
  {
    name: "Norway",
    code: "NO",
  },
  {
    name: "Oman",
    code: "OM",
  },
  {
    name: "Pakistan",
    code: "PK",
  },
  {
    name: "Palau",
    code: "PW",
  },
  // {
  //   name: "Palestinian Territory, Occupied",
  //   code: "PS",
  // },
  {
    name: "Panama",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
  },
  {
    name: "Paraguay",
    code: "PY",
  },
  {
    name: "Peru",
    code: "PE",
  },
  {
    name: "Philippines",
    code: "PH",
  },
  {
    name: "Pitcairn",
    code: "PN",
  },
  {
    name: "Poland",
    code: "PL",
  },
  {
    name: "Portugal",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    code: "PR",
  },
  {
    name: "Qatar",
    code: "QA",
  },
  {
    name: "Reunion",
    code: "RE",
  },
  {
    name: "Romania",
    code: "RO",
  },
  // {
  //   name: "Russian Federation",
  //   code: "RU",
  // },
  {
    name: "Rwanda",
    code: "RW",
  },
  {
    name: "Saint Barthelemy",
    code: "BL",
  },
  {
    name: "Saint Helena",
    code: "SH",
  },
  {
    name: "Saint Kitts And Nevis",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    code: "LC",
  },
  {
    name: "Saint Martin",
    code: "MF",
  },
  {
    name: "Saint Pierre And Miquelon",
    code: "PM",
  },
  {
    name: "Saint Vincent And Grenadines",
    code: "VC",
  },
  {
    name: "Samoa",
    code: "WS",
  },
  {
    name: "San Marino",
    code: "SM",
  },
  {
    name: "Sao Tome And Principe",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
  },
  {
    name: "Senegal",
    code: "SN",
  },
  {
    name: "Serbia",
    code: "RS",
  },
  {
    name: "Seychelles",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    code: "SL",
  },
  {
    name: "Singapore",
    code: "SG",
  },
  {
    name: "Sint Maarten",
    code: "SX",
  },
  {
    name: "Slovakia",
    code: "SK",
  },
  {
    name: "Slovenia",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    code: "SB",
  },
  {
    name: "South Africa",
    code: "ZA",
  },
  {
    name: "South Georgia And Sandwich Isl.",
    code: "GS",
  },
  {
    name: "Spain",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    code: "LK",
  },
  {
    name: "Suriname",
    code: "SR",
  },
  {
    name: "Svalbard And Jan Mayen",
    code: "SJ",
  },
  {
    name: "Swaziland",
    code: "SZ",
  },
  {
    name: "Sweden",
    code: "SE",
  },
  {
    name: "Switzerland",
    code: "CH",
  },
  {
    name: "Taiwan",
    code: "TW",
  },
  {
    name: "Tajikistan",
    code: "TJ",
  },
  {
    name: "Tanzania",
    code: "TZ",
  },
  {
    name: "Thailand",
    code: "TH",
  },
  {
    name: "Timor-leste",
    code: "TL",
  },
  {
    name: "Togo",
    code: "TG",
  },
  {
    name: "Tokelau",
    code: "TK",
  },
  {
    name: "Tonga",
    code: "TO",
  },
  {
    name: "Trinidad And Tobago",
    code: "TT",
  },
  // {
  //   name: "Tunisia",
  //   code: "TN",
  // },
  {
    name: "Turkey",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    code: "TM",
  },
  {
    name: "Turks And Caicos Islands",
    code: "TC",
  },
  {
    name: "Tuvalu",
    code: "TV",
  },
  {
    name: "Uganda",
    code: "UG",
  },
  {
    name: "Ukraine",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
  },
  {
    name: "United Kingdom",
    code: "GB",
  },
  {
    name: "United States",
    code: "US",
  },
  {
    name: "United States Outlying Islands",
    code: "UM",
  },
  {
    name: "Uruguay",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    code: "VU",
  },
  {
    name: "Vietnam",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.s.",
    code: "VI",
  },
  {
    name: "Wallis And Futuna",
    code: "WF",
  },
  {
    name: "Zambia",
    code: "ZM",
  },
];

export const code_to_country = {
  AX: "Aland Islands",
  // AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua And Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  // AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  // BA: "Bosnia And Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  KH: "Cambodia",
  CM: "Cameroon",
  CW: "Curacao",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: 'Cote D"ivoire',
  // HR: "Croatia",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  // ER: "Eritrea",
  EE: "Estonia",
  // ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  // GW: "Guinea-bissau",
  GY: "Guyana",
  // HT: "Haiti",
  HM: "Heard Island & Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IE: "Ireland",
  IM: "Isle Of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KR: "Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: 'Lao People"s Democratic Republic',
  LV: "Latvia",
  LS: "Lesotho",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  // MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia, Federated States Of",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  // MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  // AN: "Netherlands Antilles",
  SX: "Sint Maarten",
  NC: "New Caledonia",
  NZ: "New Zealand",
  // NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  // PS: "Palestinian Territory, Occupied",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  // RU: "Russian Federation",
  RS: "Serbia",
  RW: "Rwanda",
  BL: "Saint Barthelemy",
  SH: "Saint Helena",
  KN: "Saint Kitts And Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre And Miquelon",
  VC: "Saint Vincent And Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome And Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  ZA: "South Africa",
  GS: "South Georgia And Sandwich Isl.",
  ES: "Spain",
  LK: "Sri Lanka",
  SR: "Suriname",
  SJ: "Svalbard And Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad And Tobago",
  // TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks And Caicos Islands",
  TV: "Tuvalu",
  UA: "Ukraine",
  UG: "Uganda",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UM: "United States Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VN: "Vietnam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis And Futuna",
  ZM: "Zambia",
};
export const country_to_code = {
  "Aland islands": "AX",
  // Albania: "AL",
  Algeria: "DZ",
  "American samoa": "AS",
  Andorra: "AD",
  Angola: "AO",
  Anguilla: "AI",
  Antarctica: "AQ",
  "Antigua and barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Austria: "AT",
  // Azerbaijan: "AZ",
  Bahamas: "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bermuda: "BM",
  Bhutan: "BT",
  Bolivia: "BO",
  // "Bosnia and herzegovina": "BA",
  Botswana: "BW",
  "Bouvet island": "BV",
  Brazil: "BR",
  "British indian ocean territory": "IO",
  "Brunei darussalam": "BN",
  Bulgaria: "BG",
  "Burkina faso": "BF",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cape verde": "CV",
  "Cayman islands": "KY",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  "Christmas island": "CX",
  "Cocos (keeling) islands": "CC",
  Colombia: "CO",
  Comoros: "KM",
  Curacao: "CW",
  "Cook islands": "CK",
  "Costa rica": "CR",
  'Cote d"ivoire': "CI",
  // Cuba: "CU",
  Cyprus: "CY",
  "Czech republic": "CZ",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican republic": "DO",
  Ecuador: "EC",
  Egypt: "EG",
  "El salvador": "SV",
  "Equatorial guinea": "GQ",
  // Eritrea: "ER",
  Estonia: "EE",
  // Ethiopia: "ET",
  "Falkland islands (malvinas)": "FK",
  "Faroe islands": "FO",
  Fiji: "FJ",
  Finland: "FI",
  France: "FR",
  "French guiana": "GF",
  "French polynesia": "PF",
  "French southern territories": "TF",
  Gabon: "GA",
  Gambia: "GM",
  Georgia: "GE",
  Germany: "DE",
  Ghana: "GH",
  Gibraltar: "GI",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guadeloupe: "GP",
  Guam: "GU",
  Guatemala: "GT",
  Guernsey: "GG",
  Guinea: "GN",
  // "Guinea-bissau": "GW",
  Guyana: "GY",
  // Haiti: "HT",
  "Heard island & mcdonald islands": "HM",
  "Holy see (vatican city state)": "VA",
  Honduras: "HN",
  "Hong kong": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  Ireland: "IE",
  "Isle of man": "IM",
  Israel: "IL",
  Italy: "IT",
  Jamaica: "JM",
  Japan: "JP",
  Jersey: "JE",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  Korea: "KR",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  'Lao people"s democratic republic': "LA",
  Latvia: "LV",
  Lesotho: "LS",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Macao: "MO",
  // Macedonia: "MK",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall islands": "MH",
  Martinique: "MQ",
  Mauritania: "MR",
  Mauritius: "MU",
  Mayotte: "YT",
  Mexico: "MX",
  "Micronesia, federated states of": "FM",
  Moldova: "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  // Myanmar: "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  Netherlands: "NL",
  // "Netherlands antilles": "AN",
  "Sint Maarten": "SX",
  "New caledonia": "NC",
  "New zealand": "NZ",
  // Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  Niue: "NU",
  "Norfolk island": "NF",
  "Northern mariana islands": "MP",
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  // "Palestinian territory, occupied": "PS",
  Panama: "PA",
  "Papua new guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  Pitcairn: "PN",
  Poland: "PL",
  Portugal: "PT",
  "Puerto rico": "PR",
  Qatar: "QA",
  Reunion: "RE",
  Romania: "RO",
  // "Russian federation": "RU",
  Rwanda: "RW",
  "Saint barthelemy": "BL",
  "Saint helena": "SH",
  "Saint kitts and nevis": "KN",
  "Saint lucia": "LC",
  "Saint martin": "MF",
  "Saint pierre and miquelon": "PM",
  "Saint vincent and grenadines": "VC",
  Samoa: "WS",
  "San marino": "SM",
  "Sao tome and principe": "ST",
  "Saudi arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra leone": "SL",
  Singapore: "SG",
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon islands": "SB",
  "South africa": "ZA",
  "South georgia and sandwich isl.": "GS",
  Spain: "ES",
  "Sri lanka": "LK",
  Suriname: "SR",
  "Svalbard and jan mayen": "SJ",
  Swaziland: "SZ",
  Sweden: "SE",
  Switzerland: "CH",
  Taiwan: "TW",
  Tajikistan: "TJ",
  Tanzania: "TZ",
  Thailand: "TH",
  "Timor-leste": "TL",
  Togo: "TG",
  Tokelau: "TK",
  Tonga: "TO",
  "Trinidad and tobago": "TT",
  // Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  "Turks and caicos islands": "TC",
  Tuvalu: "TV",
  Ukraine: "UA",
  Uganda: "UG",
  "United arab emirates": "AE",
  "United kingdom": "GB",
  "United states": "US",
  "United states outlying islands": "UM",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  Vietnam: "VN",
  "Virgin islands, british": "VG",
  "Virgin islands, u.s.": "VI",
  "Wallis and futuna": "WF",
  Zambia: "ZM",
};

export const nationality_list = [
  { name: "Andorran", code: "AD" },
  { name: "Emirati", code: "AE" },
  { name: "Antiguan, Barbudan", code: "AG" },
  { name: "Anguillian", code: "AI" },
  // { name: "Albanian", code: "AL" },
  { name: "Armenian", code: "AM" },
  { name: "Dutch", code: "AN" },
  { name: "Angolan", code: "AO" },
  { name: "Argentinean", code: "AR" },
  { name: "American Samoan", code: "AS" },
  { name: "Austrian", code: "AT" },
  { name: "Australian", code: "AU" },
  { name: "Aruban", code: "AW" },
  { name: "Swedish", code: "AX" },
  // { name: "Azerbaijani", code: "AZ" },
  // { name: "Bosnian, Herzegovinian", code: "BA" },
  { name: "Barbadian", code: "BB" },
  { name: "Bangladeshi", code: "BD" },
  { name: "Belgian", code: "BE" },
  { name: "Burkinabe", code: "BF" },
  { name: "Bulgarian", code: "BG" },
  { name: "Bahraini", code: "BH" },
  { name: "Beninese", code: "BJ" },
  { name: "Saint Barthélemy Islander", code: "BL" },
  { name: "Bermudian", code: "BM" },
  { name: "Bruneian", code: "BN" },
  { name: "Bolivian", code: "BO" },
  { name: "Dutch (BQ)", code: "BQ" },
  { name: "Brazilian", code: "BR" },
  { name: "Bahamian", code: "BS" },
  { name: "Bhutanese", code: "BT" },
  { name: "Motswana", code: "BW" },
  { name: "Belizean", code: "BZ" },
  { name: "Canadian", code: "CA" },
  { name: "Cocos Islander", code: "CC" },
  { name: "Swiss", code: "CH" },
  { name: "Ivorian", code: "CI" },
  { name: "Cook Islander", code: "CK" },
  { name: "Chilean", code: "CL" },
  { name: "Cameroonian", code: "CM" },
  { name: "Chinese", code: "CN" },
  { name: "Colombian", code: "CO" },
  { name: "Costa Rican", code: "CR" },
  { name: "Montenegrins, Serbs", code: "CS" },
  { name: "Cape Verdian", code: "CV" },
  { name: "Curaçaoan", code: "CW" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cypriot", code: "CY" },
  { name: "Czech", code: "CZ" },
  { name: "German", code: "DE" },
  { name: "Djibouti", code: "DJ" },
  { name: "Danish", code: "DK" },
  { name: "Dominican", code: "DM" },
  { name: "Dominican (DO)", code: "DO" },
  { name: "Algerian", code: "DZ" },
  { name: "Ecuadorean", code: "EC" },
  { name: "Estonian", code: "EE" },
  { name: "Egyptian", code: "EG" },
  // { name: "Eritrean", code: "ER" },
  { name: "Spanish", code: "ES" },
  // { name: "Ethiopian", code: "ET" },
  { name: "Finnish", code: "FI" },
  { name: "Fijian", code: "FJ" },
  { name: "Falkland Islander", code: "FK" },
  { name: "Micronesian", code: "FM" },
  { name: "Faroese", code: "FO" },
  { name: "French", code: "FR" },
  { name: "Gabonese", code: "GA" },
  { name: "British", code: "GB" },
  { name: "Grenadian", code: "GD" },
  { name: "Georgian", code: "GE" },
  { name: "Channel Islander", code: "GG" },
  { name: "Ghanaian", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greenlandic", code: "GL" },
  { name: "Gambian", code: "GM" },
  { name: "Guinean", code: "GN" },
  { name: "Guadeloupian", code: "GP" },
  { name: "Equatorial Guinean", code: "GQ" },
  { name: "Greek", code: "GR" },
  { name: "South Georgia and the South Sandwich Islander", code: "GS" },
  { name: "Guatemalan", code: "GT" },
  { name: "Guamanian", code: "GU" },
  // { name: "Guinea-Bissauan", code: "GW" },
  { name: "Guyanese", code: "GY" },
  { name: "Chinese (HK)", code: "HK" },
  { name: "Heard and McDonald Islander", code: "HM" },
  { name: "Honduran", code: "HN" },
  // { name: "Croatian", code: "HR" },
  // { name: "Haitian", code: "HT" },
  { name: "Hungarian", code: "HU" },
  { name: "Indonesian", code: "ID" },
  { name: "Irish", code: "IE" },
  { name: "Israeli", code: "IL" },
  { name: "Manx", code: "IM" },
  { name: "Indian (IN)", code: "IN" },
  { name: "Indian (IO)", code: "IO" },
  { name: "Icelander", code: "IS" },
  { name: "Italian", code: "IT" },
  { name: "Channel Islander (JE)", code: "JE" },
  { name: "Jamaican", code: "JM" },
  { name: "Jordanian", code: "JO" },
  { name: "Japanese", code: "JP" },
  { name: "Kenyan", code: "KE" },
  { name: "Kirghiz", code: "KG" },
  { name: "Cambodian", code: "KH" },
  { name: "I-Kiribati", code: "KI" },
  { name: "Comoran", code: "KM" },
  { name: "Kittian and Nevisian", code: "KN" },
  { name: "South Korean", code: "KR" },
  { name: "Kuwaiti", code: "KW" },
  { name: "Caymanian", code: "KY" },
  { name: "Kazakhstani", code: "KZ" },
  { name: "Laotian", code: "LA" },
  { name: "Saint Lucian", code: "LC" },
  { name: "Liechtensteiner", code: "LI" },
  { name: "Sri Lankan", code: "LK" },
  { name: "Mosotho", code: "LS" },
  { name: "Lithuanian", code: "LT" },
  { name: "Luxembourger", code: "LU" },
  { name: "Latvian", code: "LV" },
  { name: "Moroccan", code: "MA" },
  { name: "Monegasque", code: "MC" },
  { name: "Moldovan", code: "MD" },
  { name: "Montenegrin", code: "ME" },
  { name: "Saint Martin Islander", code: "MF" },
  { name: "Malagasy", code: "MG" },
  { name: "Marshallese", code: "MH" },
  // { name: "Macedonian", code: "MK" },
  { name: "Malian", code: "ML" },
  // { name: "Myanmar", code: "MM" },
  { name: "Mongolian", code: "MN" },
  { name: "Chinese (MO)", code: "MO" },
  { name: "American", code: "MP" },
  { name: "French(MQ)", code: "MQ" },
  { name: "Mauritanian", code: "MR" },
  { name: "Montserratian", code: "MS" },
  { name: "Maltese", code: "MT" },
  { name: "Mauritian", code: "MU" },
  { name: "Maldivan", code: "MV" },
  { name: "Malawian", code: "MW" },
  { name: "Mexican", code: "MX" },
  { name: "Malaysian", code: "MY" },
  { name: "Mozambican", code: "MZ" },
  { name: "Namibian", code: "NA" },
  { name: "New Caledonian", code: "NC" },
  { name: "Nigerian", code: "NE" },
  { name: "Norfolk Islander", code: "NF" },
  { name: "Nigerian (NG)", code: "NG" },
  // { name: "Nicaraguan", code: "NI" },
  { name: "Dutch (NL)", code: "NL" },
  { name: "Norwegian", code: "NO" },
  { name: "Nepalese", code: "NP" },
  { name: "Nauruan", code: "NR" },
  { name: "Niuean", code: "NU" },
  { name: "New Zealander", code: "NZ" },
  { name: "Omani", code: "OM" },
  { name: "Panamanian", code: "PA" },
  { name: "Peruvian", code: "PE" },
  { name: "French Polynesian", code: "PF" },
  { name: "Papua New Guinean", code: "PG" },
  { name: "Filipino", code: "PH" },
  { name: "Pakistani", code: "PK" },
  { name: "Polish", code: "PL" },
  { name: "French (PM)", code: "PM" },
  { name: "Pitcairn Islander", code: "PN" },
  { name: "Puerto Rican", code: "PR" },
  // { name: "Palestinian", code: "PS" },
  { name: "Portuguese", code: "PT" },
  { name: "Palauan", code: "PW" },
  { name: "Paraguayan", code: "PY" },
  { name: "Qatari", code: "QA" },
  { name: "French (RE)", code: "RE" },
  { name: "Romanian", code: "RO" },
  // { name: "Russian", code: "RU" },
  { name: "Rwandan", code: "RW" },
  { name: "Saudi Arabian", code: "SA" },
  { name: "Solomon Islander", code: "SB" },
  { name: "Seychellois", code: "SC" },
  { name: "Swedish (SE)", code: "SE" },
  { name: "Singaporean", code: "SG" },
  { name: "Saint Helenian", code: "SH" },
  { name: "Slovene", code: "SI" },
  { name: "Norwegian (SJ)", code: "SJ" },
  { name: "Slovak", code: "SK" },
  { name: "Sierra Leonean", code: "SL" },
  { name: "Sammarinese", code: "SM" },
  { name: "Senegalese", code: "SN" },
  { name: "Serbian", code: "RS" },
  { name: "Surinamer", code: "SR" },
  { name: "Sao Tomean", code: "ST" },
  { name: "Salvadoran", code: "SV" },
  { name: "Swazi", code: "SZ" },
  { name: "Turks and Caicos Islander", code: "TC" },
  { name: "Chadian", code: "TD" },
  { name: "French (TF)", code: "TF" },
  { name: "Togolese", code: "TG" },
  { name: "Thai", code: "TH" },
  { name: "Tadzhik", code: "TJ" },
  { name: "Tokelauan", code: "TK" },
  { name: "East Timorese", code: "TL" },
  { name: "Turkmen", code: "TM" },
  // { name: "Tunisian", code: "TN" },
  { name: "Tongan", code: "TO" },
  { name: "Turkish", code: "TR" },
  { name: "Trinidadian", code: "TT" },
  { name: "Tuvaluan", code: "TV" },
  { name: "Taiwanese", code: "TW" },
  { name: "Tanzanian", code: "TZ" },
  { name: "Ukrainian", code: "UA" },
  { name: "Ugandan", code: "UG" },
  { name: "American (UM)", code: "UM" },
  { name: "American (US)", code: "US" },
  { name: "Uruguayan", code: "UY" },
  { name: "Uzbekistani", code: "UZ" },
  { name: "Italian(VA)", code: "VA" },
  { name: "Saint Vincentian", code: "VC" },
  { name: "Virgin Islander (VG)", code: "VG" },
  { name: "Virgin Islander (VI)", code: "VI" },
  { name: "Vietnamese", code: "VN" },
  { name: "Ni-Vanuatu", code: "VU" },
  { name: "Wallis and Futuna Islander", code: "WF" },
  { name: "Samoan", code: "WS" },
  { name: "French(YT)", code: "YT" },
  { name: "South African", code: "ZA" },
  { name: "Zambian", code: "ZM" },
];

export const nationality_to_code = {
  Andorran: "AD",
  Emirati: "AE",
  "Antiguan, Barbudan": "AG",
  Anguillian: "AI",
  // Albanian: "AL",
  Armenian: "AM",
  Dutch: "AN",
  Angolan: "AO",
  Argentinean: "AR",
  "American Samoan": "AS",
  Austrian: "AT",
  Australian: "AU",
  Aruban: "AW",
  Swedish: "AX",
  // Azerbaijani: "AZ",
  // "Bosnian, Herzegovinian": "BA",
  Barbadian: "BB",
  Bangladeshi: "BD",
  Belgian: "BE",
  Burkinabe: "BF",
  Bulgarian: "BG",
  Bahraini: "BH",
  Beninese: "BJ",
  "Saint Barthélemy Islander": "BL",
  Bermudian: "BM",
  Bruneian: "BN",
  Bolivian: "BO",
  "Dutch (BQ)": "BQ",
  Brazilian: "BR",
  Bahamian: "BS",
  Bhutanese: "BT",
  Motswana: "BW",
  Belizean: "BZ",
  Canadian: "CA",
  "Cocos Islander": "CC",
  Swiss: "CH",
  Ivorian: "CI",
  "Cook Islander": "CK",
  Chilean: "CL",
  Cameroonian: "CM",
  Chinese: "CN",
  Colombian: "CO",
  "Costa Rican": "CR",
  "Montenegrins, Serbs": "CS",
  "Cape Verdian": "CV",
  Curaçaoan: "CW",
  "Christmas Island": "CX",
  Cypriot: "CY",
  Czech: "CZ",
  German: "DE",
  Djibouti: "DJ",
  Danish: "DK",
  Dominican: "DM",
  "Dominican (DO)": "DO",
  Algerian: "DZ",
  Ecuadorean: "EC",
  Estonian: "EE",
  Egyptian: "EG",
  // Eritrean: "ER",
  Spanish: "ES",
  // Ethiopian: "ET",
  Finnish: "FI",
  Fijian: "FJ",
  "Falkland Islander": "FK",
  Micronesian: "FM",
  Faroese: "FO",
  French: "FR",
  Gabonese: "GA",
  British: "GB",
  Grenadian: "GD",
  Georgian: "GE",
  "Channel Islander": "GG",
  Ghanaian: "GH",
  Gibraltar: "GI",
  Greenlandic: "GL",
  Gambian: "GM",
  Guinean: "GN",
  Guadeloupian: "GP",
  "Equatorial Guinean": "GQ",
  Greek: "GR",
  "South Georgia and the South Sandwich Islander": "GS",
  Guatemalan: "GT",
  Guamanian: "GU",
  // "Guinea-Bissauan": "GW",
  Guyanese: "GY",
  "Chinese (HK)": "HK",
  "Heard and McDonald Islander": "HM",
  Honduran: "HN",
  // Croatian: "HR",
  // Haitian: "HT",
  Hungarian: "HU",
  Indonesian: "ID",
  Irish: "IE",
  Israeli: "IL",
  Manx: "IM",
  "Indian (IN)": "IN",
  "Indian (IO)": "IO",
  Icelander: "IS",
  Italian: "IT",
  "Channel Islander (JE)": "JE",
  Jamaican: "JM",
  Jordanian: "JO",
  Japanese: "JP",
  Kenyan: "KE",
  Kirghiz: "KG",
  Cambodian: "KH",
  "I-Kiribati": "KI",
  Comoran: "KM",
  "Kittian and Nevisian": "KN",
  "South Korean": "KR",
  Kuwaiti: "KW",
  Caymanian: "KY",
  Kazakhstani: "KZ",
  Laotian: "LA",
  "Saint Lucian": "LC",
  Liechtensteiner: "LI",
  "Sri Lankan": "LK",
  Mosotho: "LS",
  Lithuanian: "LT",
  Luxembourger: "LU",
  Latvian: "LV",
  Moroccan: "MA",
  Monegasque: "MC",
  Moldovan: "MD",
  Montenegrin: "ME",
  "Saint Martin Islander": "MF",
  Malagasy: "MG",
  Marshallese: "MH",
  // Macedonian: "MK",
  Malian: "ML",
  // Myanmar: "MM",
  Mongolian: "MN",
  "Chinese (MO)": "MO",
  American: "MP",
  "French(MQ)": "MQ",
  Mauritanian: "MR",
  Montserratian: "MS",
  Maltese: "MT",
  Mauritian: "MU",
  Maldivan: "MV",
  Malawian: "MW",
  Mexican: "MX",
  Malaysian: "MY",
  Mozambican: "MZ",
  Namibian: "NA",
  "New Caledonian": "NC",
  Nigerian: "NE",
  "Norfolk Islander": "NF",
  "Nigerian (NG)": "NG",
  // Nicaraguan: "NI",
  "Dutch (NL)": "NL",
  Norwegian: "NO",
  Nepalese: "NP",
  Nauruan: "NR",
  Niuean: "NU",
  "New Zealander": "NZ",
  Omani: "OM",
  Panamanian: "PA",
  Peruvian: "PE",
  "French Polynesian": "PF",
  "Papua New Guinean": "PG",
  Filipino: "PH",
  Pakistani: "PK",
  Polish: "PL",
  "French (PM)": "PM",
  "Pitcairn Islander": "PN",
  "Puerto Rican": "PR",
  // Palestinian: "PS",
  Portuguese: "PT",
  Palauan: "PW",
  Paraguayan: "PY",
  Qatari: "QA",
  "French (RE)": "RE",
  Romanian: "RO",
  // Russian: "RU",
  Rwandan: "RW",
  "Saudi Arabian": "SA",
  "Solomon Islander": "SB",
  Seychellois: "SC",
  "Swedish (SE)": "SE",
  Singaporean: "SG",
  "Saint Helenian": "SH",
  Slovene: "SI",
  "Norwegian (SJ)": "SJ",
  Slovak: "SK",
  "Sierra Leonean": "SL",
  Sammarinese: "SM",
  Senegalese: "SN",
  Serbian: "RS",
  Surinamer: "SR",
  "Sao Tomean": "ST",
  Salvadoran: "SV",
  Swazi: "SZ",
  "Turks and Caicos Islander": "TC",
  Chadian: "TD",
  "French (TF)": "TF",
  Togolese: "TG",
  Thai: "TH",
  Tadzhik: "TJ",
  Tokelauan: "TK",
  "East Timorese": "TL",
  Turkmen: "TM",
  // Tunisian: "TN",
  Tongan: "TO",
  Turkish: "TR",
  Trinidadian: "TT",
  Tuvaluan: "TV",
  Taiwanese: "TW",
  Tanzanian: "TZ",
  Ugandan: "UG",
  Ukrainian: "UA",
  "American (UM)": "UM",
  "American (US)": "US",
  Uruguayan: "UY",
  Uzbekistani: "UZ",
  "Italian(VA)": "VA",
  "Saint Vincentian": "VC",
  "Virgin Islander (VG)": "VG",
  "Virgin Islander (VI)": "VI",
  Vietnamese: "VN",
  "Ni-Vanuatu": "VU",
  "Wallis and Futuna Islander": "WF",
  Samoan: "WS",
  "French(YT)": "YT",
  "South African": "ZA",
  Zambian: "ZM",
};

export const code_to_nationality = {
  AD: "Andorran",
  AE: "Emirati",
  AG: "Antiguan, Barbudan",
  AI: "Anguillian",
  // AL: "Albanian",
  AM: "Armenian",
  AN: "Dutch",
  AO: "Angolan",
  AR: "Argentinean",
  AS: "American Samoan",
  AT: "Austrian",
  AU: "Australian",
  AW: "Aruban",
  AX: "Swedish",
  // AZ: "Azerbaijani",
  // BA: "Bosnian, Herzegovinian",
  BB: "Barbadian",
  BD: "Bangladeshi",
  BE: "Belgian",
  BF: "Burkinabe",
  BG: "Bulgarian",
  BH: "Bahraini",
  BJ: "Beninese",
  BL: "Saint Barthélemy Islander",
  BM: "Bermudian",
  BN: "Bruneian",
  BO: "Bolivian",
  BQ: "Dutch",
  BR: "Brazilian",
  BS: "Bahamian",
  BT: "Bhutanese",
  BW: "Motswana",
  BZ: "Belizean",
  CA: "Canadian",
  CC: "Cocos Islander",
  CH: "Swiss",
  CI: "Ivorian",
  CK: "Cook Islander",
  CL: "Chilean",
  CM: "Cameroonian",
  CN: "Chinese",
  CO: "Colombian",
  CR: "Costa Rican",
  CS: "Montenegrins, Serbs",
  CV: "Cape Verdian",
  CW: "Curaçaoan",
  CX: "Christmas Island",
  CY: "Cypriot",
  CZ: "Czech",
  DE: "German",
  DJ: "Djibouti",
  DK: "Danish",
  DM: "Dominican",
  DO: "Dominican",
  DZ: "Algerian",
  EC: "Ecuadorean",
  EE: "Estonian",
  EG: "Egyptian",
  // ER: "Eritrean",
  ES: "Spanish",
  // ET: "Ethiopian",
  FI: "Finnish",
  FJ: "Fijian",
  FK: "Falkland Islander",
  FM: "Micronesian",
  FO: "Faroese",
  FR: "French",
  GA: "Gabonese",
  GB: "British",
  GD: "Grenadian",
  GE: "Georgian",
  GG: "Channel Islander",
  GH: "Ghanaian",
  GI: "Gibraltar",
  GL: "Greenlandic",
  GM: "Gambian",
  GN: "Guinean",
  GP: "Guadeloupian",
  GQ: "Equatorial Guinean",
  GR: "Greek",
  GS: "South Georgia and the South Sandwich Islander",
  GT: "Guatemalan",
  GU: "Guamanian",
  // GW: "Guinea-Bissauan",
  GY: "Guyanese",
  HK: "Chinese",
  HM: "Heard and McDonald Islander",
  HN: "Honduran",
  // HR: "Croatian",
  // HT: "Haitian",
  HU: "Hungarian",
  ID: "Indonesian",
  IE: "Irish",
  IL: "Israeli",
  IM: "Manx",
  IN: "Indian",
  IO: "Indian",
  IS: "Icelander",
  IT: "Italian",
  JE: "Channel Islander",
  JM: "Jamaican",
  JO: "Jordanian",
  JP: "Japanese",
  KE: "Kenyan",
  KG: "Kirghiz",
  KH: "Cambodian",
  KI: "I-Kiribati",
  KM: "Comoran",
  KN: "Kittian and Nevisian",
  KR: "South Korean",
  KW: "Kuwaiti",
  KY: "Caymanian",
  KZ: "Kazakhstani",
  LA: "Laotian",
  LC: "Saint Lucian",
  LI: "Liechtensteiner",
  LK: "Sri Lankan",
  LS: "Mosotho",
  LT: "Lithuanian",
  LU: "Luxembourger",
  LV: "Latvian",
  MA: "Moroccan",
  MC: "Monegasque",
  MD: "Moldovan",
  ME: "Montenegrin",
  MF: "Saint Martin Islander",
  MG: "Malagasy",
  MH: "Marshallese",
  // MK: "Macedonian",
  ML: "Malian",
  // MM: "Myanmar",
  MN: "Mongolian",
  MO: "Chinese",
  MP: "American",
  MQ: "French",
  MR: "Mauritanian",
  MS: "Montserratian",
  MT: "Maltese",
  MU: "Mauritian",
  MV: "Maldivan",
  MW: "Malawian",
  MX: "Mexican",
  MY: "Malaysian",
  MZ: "Mozambican",
  NA: "Namibian",
  NC: "New Caledonian",
  NE: "Nigerian",
  NF: "Norfolk Islander",
  NG: "Nigerian",
  // NI: "Nicaraguan",
  NL: "Dutch",
  NO: "Norwegian",
  NP: "Nepalese",
  NR: "Nauruan",
  NU: "Niuean",
  NZ: "New Zealander",
  OM: "Omani",
  PA: "Panamanian",
  PE: "Peruvian",
  PF: "French Polynesian",
  PG: "Papua New Guinean",
  PH: "Filipino",
  PK: "Pakistani",
  PL: "Polish",
  PM: "French",
  PN: "Pitcairn Islander",
  PR: "Puerto Rican",
  // PS: "Palestinian",
  PT: "Portuguese",
  PW: "Palauan",
  PY: "Paraguayan",
  QA: "Qatari",
  RE: "French",
  RO: "Romanian",
  // RU: "Russian",
  RS: "Serbian",
  RW: "Rwandan",
  SA: "Saudi Arabian",
  SB: "Solomon Islander",
  SC: "Seychellois",
  SE: "Swedish",
  SG: "Singaporean",
  SH: "Saint Helenian",
  SI: "Slovene",
  SJ: "Norwegian",
  SK: "Slovak",
  SL: "Sierra Leonean",
  SM: "Sammarinese",
  SN: "Senegalese",
  SR: "Surinamer",
  ST: "Sao Tomean",
  SV: "Salvadoran",
  SZ: "Swazi",
  TC: "Turks and Caicos Islander",
  TD: "Chadian",
  TF: "French",
  TG: "Togolese",
  TH: "Thai",
  TJ: "Tadzhik",
  TK: "Tokelauan",
  TL: "East Timorese",
  TM: "Turkmen",
  // TN: "Tunisian",
  TO: "Tongan",
  TR: "Turkish",
  TT: "Trinidadian",
  TV: "Tuvaluan",
  TW: "Taiwanese",
  TZ: "Tanzanian",
  UA: "Ukrainian",
  UG: "Ugandan",
  UM: "American",
  US: "American",
  UY: "Uruguayan",
  UZ: "Uzbekistani",
  VA: "Italian",
  VC: "Saint Vincentian",
  VG: "Virgin Islander",
  VI: "Virgin Islander",
  VN: "Vietnamese",
  VU: "Ni-Vanuatu",
  WF: "Wallis and Futuna Islander",
  WS: "Samoan",
  YT: "French",
  ZA: "South African",
  ZM: "Zambian",
};

export const source_of_funds_list = [
  {
    value: "Sales Revenue",
    label: "Sales Revenue",
  },
  {
    value: "Investment Capital",
    label: "Investment Capital",
  },
  {
    value: "Loans & Credit",
    label: "Loans & Credit",
  },
  {
    value: "Grants or Sponsorship",
    label: "Grants or Sponsorship",
  },
  {
    value: "OTHER",
    label: "Other",
  },
];

export const referral_source_list = [
  {
    value: "Finmo Sales Team",
    label: "Finmo Sales Team",
  },
  {
    value: "Finmo Partnership",
    label: "Finmo Partnership",
  },
  {
    value: "Organic routes",
    label: "Organic routes",
  },
];

export const account_usage_intent_list = [
  {
    value: "Collections",
    label: "Collections",
  },
  {
    value: "Payouts",
    label: "Payouts",
  },
  {
    value: "E-wallets",
    label: "E-wallets",
  },
  {
    value: "Global Currency Account (GCA)",
    label: "Global Currency Account (GCA)",
  },
  {
    value: "Others",
    label: "Others",
  },
];

export const identity_document_type_list = [
  {
    value: "WORK_PERMIT",
    label: "WORK PERMIT",
  },
  {
    value: "PASSPORT",
    label: "PASSPORT",
  },
  {
    value: "NATIONAL_ID",
    label: "NATIONAL ID",
  },
  {
    value: "DRIVERS_LICENCE",
    label: "DRIVERS LICENCE",
  },
  {
    value: "OTHERS",
    label: "OTHERS",
  },
];

export const event_name_desc = {
  REFUND_COMPLETED: "Successful Refunds",
  VA_PAYIN_COMPLETED: "Incoming funds on a static virtual account",
  PAYIN_COMPLETED: "Incoming funds received (General)",
  VA_CREATED: "Static virtual account creation ",
};

export const wallet_scope = [
  {
    value: "master",
    label: "ORGANIZATION",
  },
  {
    value: "customer",
    label: "CUSTOMER",
  },
];

export const currency_code_to_symbol = {
  AED: "AED",
  AFN: "L",
  ALL: "؋",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "A$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: "BD",
  BIF: "FBu",
  BMD: "$",
  BND: "B",
  BOB: "Bs",
  BRL: "R",
  BSD: "B",
  BTN: "Nu",
  BWP: "P",
  BYN: "Br",
  BZD: "BZ",
  CAD: "CA",
  CDF: "FC",
  CHF: "CHF",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  CRC: "₡",
  CUP: "CUC",
  CVE: "CVE",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "RD",
  DZD: "دج",
  EGP: "E£",
  ERN: "Nkf",
  ETB: "Br",
  EUR: "€",
  FKP: "FK£",
  GBP: "£",
  GEL: "₾",
  GHS: "GH₵",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "G",
  HKD: "HK",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "Íkr",
  JMD: "J",
  JOD: "ينار",
  JPY: "¥",
  KES: "KSh",
  KGS: "som",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "ك",
  KYD: "CI",
  KZT: "₸",
  LAK: "₭",
  LBP: "ل.ل",
  LKR: "Rs",
  LRD: "LD",
  LSL: "L",
  LYD: "LD",
  MAD: "DH",
  MDL: "L",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP",
  MRU: "UM",
  MUR: "₨",
  MVR: "MRf",
  MWK: "K",
  MXN: "$",
  MYR: "RM",
  MZN: "MT",
  NAD: "N",
  NGN: "₦",
  NIO: "C",
  NOK: "kr",
  NPR: "Rs",
  NZD: "NZ$",
  OMR: "ر.ع",
  PAB: "B/.",
  PEN: "S/.",
  PHP: "₱",
  PKR: "Rs",
  PLN: "zł",
  PYG: "₲",
  QAR: "ر.ق",
  RON: "lei",
  RSD: "RSD",
  RUB: "₽",
  RWF: "R₣",
  SAR: "SR",
  SCR: "SR",
  SDG: "SDG",
  SEK: "kr",
  SGD: "S$",
  SLL: "Le",
  SOS: "Sh.So",
  SRD: "Sr",
  SSP: "SS£",
  STN: "Db",
  SYP: "£S",
  SZL: "L",
  THB: "฿",
  TJS: "TJS",
  TMT: "m",
  TND: "د.ت",
  TRY: "₺",
  TTD: "TT",
  TWD: "NT",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYU: "$U",
  UZS: "som",
  VED: "Bs",
  VND: "₫",
  XAF: "FCFA",
  XCD: "$",
  XOF: "CFA",
  YER: "﷼",
  ZAR: "R",
};

export const currency_decimal = {
  AED: 2,
  AFN: 2,
  ALL: 2,
  AMD: 2,
  ANG: 2,
  AOA: 2,
  ARS: 2,
  AUD: 2,
  AWG: 2,
  AZN: 2,
  BAM: 2,
  BBD: 2,
  BDT: 2,
  BGN: 2,
  BHD: 3,
  BIF: 0,
  BMD: 2,
  BND: 2,
  BOB: 2,
  BOV: 2,
  BRL: 2,
  BSD: 2,
  BTN: 2,
  BWP: 2,
  BYR: 0,
  BZD: 2,
  CAD: 2,
  CDF: 2,
  CHE: 2,
  CHF: 2,
  CHW: 2,
  CLF: 0,
  CLP: 0,
  CNY: 2,
  COP: 2,
  COU: 2,
  CRC: 2,
  CUC: 2,
  CUP: 2,
  CVE: 0,
  CZK: 2,
  DJF: 0,
  DKK: 2,
  DOP: 2,
  DZD: 2,
  EGP: 2,
  ERN: 2,
  ETB: 2,
  EUR: 2,
  FJD: 2,
  FKP: 2,
  GBP: 2,
  GEL: 2,
  GHS: 2,
  GIP: 2,
  GMD: 2,
  GNF: 0,
  GTQ: 2,
  GYD: 2,
  HKD: 2,
  HNL: 2,
  HRK: 2,
  HTG: 2,
  HUF: 2,
  IDR: 2,
  ILS: 2,
  INR: 2,
  IQD: 3,
  IRR: 0,
  ISK: 0,
  JMD: 2,
  JOD: 3,
  JPY: 0,
  KES: 2,
  KGS: 2,
  KHR: 2,
  KMF: 0,
  KPW: 0,
  KRW: 0,
  KWD: 3,
  KYD: 2,
  KZT: 2,
  LAK: 0,
  LBP: 0,
  LKR: 2,
  LRD: 2,
  LSL: 2,
  LTL: 2,
  LVL: 2,
  LYD: 3,
  MAD: 2,
  MDL: 2,
  MGA: 0,
  MKD: 0,
  MMK: 0,
  MNT: 2,
  MOP: 2,
  MRO: 0,
  MUR: 2,
  MVR: 2,
  MWK: 2,
  MXV: 2,
  MYR: 2,
  MZN: 2,
  NAD: 2,
  NGN: 2,
  NIO: 2,
  NOK: 2,
  NPR: 2,
  NZD: 2,
  OMR: 3,
  PAB: 2,
  PEN: 2,
  PGK: 2,
  PHP: 2,
  PKR: 2,
  PLN: 2,
  PYG: 0,
  QAR: 2,
  RON: 2,
  RSD: 2,
  RUB: 2,
  RWF: 0,
  SAR: 2,
  SBD: 2,
  SCR: 2,
  SDG: 2,
  SEK: 2,
  SGD: 2,
  SHP: 2,
  SLL: 0,
  SOS: 2,
  SRD: 2,
  SSP: 2,
  STD: 0,
  SYP: 2,
  SZL: 2,
  THB: 2,
  TJS: 2,
  TMT: 2,
  TND: 3,
  TOP: 2,
  TRY: 2,
  TTD: 2,
  TWD: 2,
  TZS: 2,
  UAH: 2,
  UGX: 2,
  USD: 2,
  USN: 2,
  USS: 2,
  UYI: 0,
  UYU: 2,
  UZS: 2,
  VEF: 2,
  VND: 0,
  VUV: 0,
  WST: 2,
  XAF: 0,
  XAG: 0,
  XAU: 0,
  XBA: 0,
  XBB: 0,
  XBC: 0,
  XBD: 0,
  XCD: 2,
  XDR: 0,
  XFU: 0,
  XOF: 0,
  XPD: 0,
  XPF: 0,
  XPT: 0,
  XTS: 0,
  XXX: 0,
  YER: 2,
  ZAR: 2,
  ZMW: 2,
};

export const decimal_format_list = {
  2: "0,0.00",
  3: "0,0.000",
  0: "0,0",
};

export const currency_list = [
  {
    value: "AUD",
    label: "Australian Dollar",
  },
  // {
  //   value: "USD",
  //   label: "US Dollar",
  // },
  // {
  //   value: "OMR",
  //   label: "Rial Omani",
  // },
];

export const to_currency_cloud_allowed_currency = {
  AED: [
    "AUD",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KWD",
    "NOK",
    "NZD",
    "OMR",
    "PLN",
    "QAR",
    "SAR",
    "SEK",
    "SGD",
    "USD",
    "ZAR",
  ],
  AUD: [
    "AED",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "JPY",
    "KES",
    "KWD",

    "MYR",
    "NOK",
    "NZD",
    "OMR",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  BGN: ["CAD", "CHF", "CZK", "DKK", "GBP", "JPY", "NOK", "SEK", "USD"],
  BHD: [
    "AED",
    "CAD",
    "CHF",
    "EUR",
    "GBP",
    "HKD",
    "JPY",
    "KWD",
    "QAR",
    "SAR",
    "SGD",
    "USD",
  ],
  CAD: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KES",
    "KWD",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  CHF: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CAD",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "NZD",
    "OMR",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  CNY: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  CZK: [
    "AED",
    "AUD",
    "BGN",
    "CAD",
    "CHF",
    "CNY",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  DKK: [
    "AED",
    "AUD",
    "BGN",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  EUR: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "JPY",
    "KES",
    "KWD",

    "MYR",
    "NOK",
    "NZD",
    "OMR",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  GBP: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "HKD",
    "HRK",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "JPY",
    "KES",
    "KWD",

    "MYR",
    "NOK",
    "NZD",
    "OMR",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  HKD: [
    "AED",
    "AUD",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HUF",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "OMR",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  HRK: ["CHF", "DKK", "EUR", "GBP", "JPY", "NOK", "PLN", "SEK", "USD"],
  HUF: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  ILS: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "JPY",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  JPY: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "KES",
    "KWD",

    "NOK",
    "NZD",
    "OMR",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  KES: [
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "DKK",
    "EUR",
    "GBP",
    "JPY",
    "NOK",
    "NZD",
    "SEK",
    "SGD",
    "UGX",
    "USD",
    "ZAR",
  ],
  KWD: [
    "AED",
    "AUD",
    "BHD",
    "CAD",
    "EUR",
    "GBP",
    "JPY",
    "QAR",
    "SAR",
    "USD",
    "ZAR",
  ],
  NOK: [
    "AED",
    "AUD",
    "BGN",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  NZD: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "OMR",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  OMR: ["AED", "AUD", "CHF", "EUR", "GBP", "HKD", "JPY", "NZD", "QAR", "USD"],
  PLN: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  QAR: [
    "AED",
    "AUD",
    "BHD",
    "CAD",
    "CHF",
    "CZK",
    "EUR",
    "GBP",
    "JPY",
    "KWD",

    "NOK",
    "OMR",
    "SAR",
    "SEK",
    "TRY",
    "USD",
    "ZAR",
  ],
  RON: [
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",

    "NOK",
    "NZD",
    "PLN",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  SAR: [
    "AED",
    "AUD",
    "BHD",
    "CAD",
    "CHF",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "JPY",
    "KWD",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  SEK: [
    "AED",
    "AUD",
    "BGN",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  SGD: [
    "AED",
    "AUD",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  THB: [
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "TRY",
    "USD",
    "ZAR",
  ],
  TRY: [
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "USD",
    "ZAR",
  ],
  UGX: [
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "DKK",
    "EUR",
    "GBP",
    "JPY",
    "KES",
    "NZD",
    "USD",
    "ZAR",
  ],
  USD: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "JPY",
    "KES",
    "KWD",

    "MYR",
    "NOK",
    "NZD",
    "OMR",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "ZAR",
  ],
  ZAR: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KES",
    "KWD",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
  ],
};

export const from_currency_cloud_allowed_currency = {
  AED: [
    "AUD",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KWD",
    "NOK",
    "NZD",
    "OMR",
    "PLN",
    "QAR",
    "SAR",
    "SEK",
    "SGD",
    "USD",
    "ZAR",
  ],
  AUD: [
    "AED",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "JPY",
    "KES",
    "KWD",

    "MYR",
    "NOK",
    "NZD",
    "OMR",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  BGN: ["CAD", "CHF", "CZK", "DKK", "EUR", "GBP", "JPY", "NOK", "SEK", "USD"],
  BHD: [
    "AED",
    "CAD",
    "CHF",
    "EUR",
    "GBP",
    "HKD",
    "JPY",
    "KWD",
    "QAR",
    "SAR",
    "SGD",
    "USD",
  ],
  CAD: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KES",
    "KWD",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  CHF: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CAD",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "NZD",
    "OMR",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  CNY: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  CZK: [
    "AED",
    "AUD",
    "BGN",
    "CAD",
    "CHF",
    "CNY",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  DKK: [
    "AED",
    "AUD",
    "BGN",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  EUR: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "JPY",
    "KES",
    "KWD",

    "MYR",
    "NOK",
    "NZD",
    "OMR",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  GBP: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "HKD",
    "HRK",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "JPY",
    "KES",
    "KWD",

    "MYR",
    "NOK",
    "NZD",
    "OMR",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  HKD: [
    "AED",
    "AUD",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HUF",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "OMR",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  HRK: ["CHF", "DKK", "EUR", "GBP", "JPY", "NOK", "PLN", "SEK", "USD"],
  HUF: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  IDR: ["AUD", "EUR", "GBP", "USD"],
  ILS: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "JPY",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  INR: ["AUD", "EUR", "GBP", "USD"],
  JPY: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "KES",
    "KWD",

    "NOK",
    "NZD",
    "OMR",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  KES: [
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "DKK",
    "EUR",
    "GBP",
    "JPY",
    "NOK",
    "NZD",
    "SEK",
    "SGD",
    "UGX",
    "USD",
    "ZAR",
  ],
  KWD: [
    "AED",
    "AUD",
    "BHD",
    "CAD",
    "EUR",
    "GBP",
    "JPY",
    "QAR",
    "SAR",
    "USD",
    "ZAR",
  ],
  MYR: ["AUD", "EUR", "GBP", "USD"],
  NOK: [
    "AED",
    "AUD",
    "BGN",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  NZD: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "OMR",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  OMR: ["AED", "AUD", "CHF", "EUR", "GBP", "HKD", "JPY", "NZD", "QAR", "USD"],
  PHP: ["AUD", "EUR", "GBP", "OMR", "PHP", "USD"],
  PLN: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  QAR: [
    "AED",
    "AUD",
    "BHD",
    "CAD",
    "CHF",
    "CZK",
    "EUR",
    "GBP",
    "JPY",
    "KWD",

    "NOK",
    "OMR",
    "SAR",
    "SEK",
    "TRY",
    "USD",
    "ZAR",
  ],
  RON: [
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "PLN",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  SAR: [
    "AED",
    "AUD",
    "BHD",
    "CAD",
    "CHF",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "JPY",
    "KWD",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  SEK: [
    "AED",
    "AUD",
    "BGN",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SGD",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  SGD: [
    "AED",
    "AUD",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KES",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "THB",
    "TRY",
    "USD",
    "ZAR",
  ],
  THB: [
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "PLN",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "TRY",
    "USD",
    "ZAR",
  ],
  TRY: [
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "USD",
    "ZAR",
  ],
  UGX: [
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "DKK",
    "EUR",
    "GBP",
    "JPY",
    "KES",
    "NZD",
    "USD",
    "ZAR",
  ],
  USD: [
    "AED",
    "AUD",
    "BGN",
    "BHD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "JPY",
    "KES",
    "KWD",

    "MYR",
    "NOK",
    "NZD",
    "OMR",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
    "ZAR",
  ],
  ZAR: [
    "AED",
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "KES",
    "KWD",

    "NOK",
    "NZD",
    "PLN",
    "QAR",
    "RON",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "UGX",
    "USD",
  ],
};

export const beneficiary_currency__to_country = {
  AED: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  AUD: [
    "AX",
    "DZ",
    "AS",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  BGN: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  BHD: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  CAD: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  CHF: ["CH"],
  CNY: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  CZK: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  DKK: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  EUR: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  GBP: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  HKD: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  HRK: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  HUF: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  IDR: ["ID"],
  ILS: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  INR: ["IN"],
  JPY: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  KES: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  KWD: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  MYR: ["MY"],
  NOK: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  NZD: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  OMR: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  PHP: ["PH"],
  PLN: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  QAR: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  RON: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  SAR: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  SEK: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  SGD: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  THB: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  TRY: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  UGX: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  USD: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
  ZAR: [
    "AX",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "BS",
    "BH",
    "BD",
    "BB",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "EE",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GY",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LS",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "ZM",
  ],
};

export const all_currency_list = [
  {
    code: "AED",
    name: "United Arab Emirates Dirham",
  },
  {
    code: "AFN",
    name: "Afghan Afghani",
  },
  {
    code: "ALL",
    name: "Albanian Lek",
  },
  {
    code: "AMD",
    name: "Armenian Dram",
  },
  {
    code: "ANG",
    name: "Netherlands Antillean Guilder",
  },
  {
    code: "AOA",
    name: "Angolan Kwanza",
  },
  {
    code: "ARS",
    name: "Argentine Peso",
  },
  {
    code: "AUD",
    name: "Australian Dollar",
  },
  {
    code: "AWG",
    name: "Aruban Florin",
  },
  {
    code: "AZN",
    name: "Azerbaijani Manat",
  },
  {
    code: "BBD",
    name: "Barbados Dollar",
  },
  {
    code: "BDT",
    name: "Bangladeshi Taka",
  },
  {
    code: "BGN",
    name: "Bulgarian Lev",
  },
  {
    code: "BHD",
    name: "Bahraini Dinar",
  },
  {
    code: "BIF",
    name: "Burundian Franc",
  },
  {
    code: "BMD",
    name: "Bermudian Dollar",
  },
  {
    code: "BND",
    name: "Brunei Dollar",
  },
  {
    code: "BOB",
    name: "Boliviano",
  },
  {
    code: "BOV",
    name: "Bolivian Mvdol",
  },
  {
    code: "BRL",
    name: "Brazilian Real",
  },
  {
    code: "BSD",
    name: "Bahamian Dollar",
  },
  {
    code: "BTN",
    name: "Bhutanese Ngultrum",
  },
  {
    code: "BWP",
    name: "Botswana Pula",
  },
  {
    code: "BYR",
    name: "Belarusian Ruble",
  },
  {
    code: "BZD",
    name: "Belize Dollar",
  },
  {
    code: "CAD",
    name: "Canadian Dollar",
  },
  {
    code: "CDF",
    name: "Congolese Franc",
  },
  {
    code: "CHF",
    name: "Swiss Franc",
  },
  {
    code: "CLP",
    name: "Chilean Peso",
  },
  {
    code: "CNY",
    name: "Chinese Yuan",
  },
  {
    code: "COP",
    name: "Colombian Peso",
  },
  {
    code: "COU",
    name: "Unidad De Valor Real",
  },
  {
    code: "CRC",
    name: "Costa Rican Colon",
  },
  {
    code: "CUC",
    name: "Cuban Convertible Peso",
  },
  {
    code: "CUP",
    name: "Cuban Peso",
  },
  {
    code: "CVE",
    name: "Cape Verde Escudo",
  },
  {
    code: "CZK",
    name: "Czech Koruna",
  },
  {
    code: "DJF",
    name: "Djiboutian Franc",
  },
  {
    code: "DKK",
    name: "Danish Krone",
  },
  {
    code: "DOP",
    name: "Dominican Peso",
  },
  {
    code: "DZD",
    name: "Algerian Dinar",
  },
  {
    code: "EGP",
    name: "Egyptian Pound",
  },
  {
    code: "ERN",
    name: "Eritrean Nakfa",
  },
  {
    code: "ETB",
    name: "Ethiopian Birr",
  },
  {
    code: "EUR",
    name: "Euro",
  },
  {
    code: "FJD",
    name: "Fiji Dollar",
  },
  {
    code: "FKP",
    name: "Falkland Islands Pound",
  },
  {
    code: "GBP",
    name: "Pound Sterling",
  },
  {
    code: "GEL",
    name: "Georgian Lari",
  },
  {
    code: "GHS",
    name: "Ghanaian Cedi",
  },
  {
    code: "GIP",
    name: "Gibraltar Pound",
  },
  {
    code: "GMD",
    name: "Gambian Dalasi",
  },
  {
    code: "GNF",
    name: "Guinean Franc",
  },
  {
    code: "GTQ",
    name: "Guatemalan Quetzal",
  },
  {
    code: "GYD",
    name: "Guyanese Dollar",
  },
  {
    code: "HKD",
    name: "Hong Kong Dollar",
  },
  {
    code: "HNL",
    name: "Honduran Lempira",
  },
  {
    code: "HRK",
    name: "Croatian Kuna",
  },
  {
    code: "HTG",
    name: "Haitian Gourde",
  },
  {
    code: "HUF",
    name: "Hungarian Forint",
  },
  {
    code: "IDR",
    name: "Indonesian Rupiah",
  },
  {
    code: "ILS",
    name: "Israeli New Shekel",
  },
  {
    code: "INR",
    name: "Indian Rupee",
  },
  {
    code: "IQD",
    name: "Iraqi Dinar",
  },
  {
    code: "IRR",
    name: "Iranian Rial",
  },
  {
    code: "ISK",
    name: "Icelandic Króna",
  },
  {
    code: "JMD",
    name: "Jamaican Dollar",
  },
  {
    code: "JOD",
    name: "Jordanian Dinar",
  },
  {
    code: "JPY",
    name: "Japanese Yen",
  },
  {
    code: "KES",
    name: "Kenyan Shilling",
  },
  {
    code: "KGS",
    name: "Kyrgyzstani Som",
  },
  {
    code: "KHR",
    name: "Cambodian Riel",
  },
  {
    code: "KMF",
    name: "Comoro Franc",
  },
  {
    code: "KPW",
    name: "North Korean Won",
  },
  {
    code: "KRW",
    name: "South Korean Won",
  },
  {
    code: "KWD",
    name: "Kuwaiti Dinar",
  },
  {
    code: "KYD",
    name: "Cayman Islands Dollar",
  },
  {
    code: "KZT",
    name: "Kazakhstani Tenge",
  },
  {
    code: "LAK",
    name: "Lao Kip",
  },
  {
    code: "LBP",
    name: "Lebanese Pound",
  },
  {
    code: "LKR",
    name: "Sri Lankan Rupee",
  },
  {
    code: "LRD",
    name: "Liberian Dollar",
  },
  {
    code: "LSL",
    name: "Lesotho Loti",
  },
  {
    code: "LTL",
    name: "Lithuanian Litas",
  },
  {
    code: "LVL",
    name: "Latvian Lats",
  },
  {
    code: "LYD",
    name: "Libyan Dinar",
  },
  {
    code: "MAD",
    name: "Moroccan Dirham",
  },
  {
    code: "MDL",
    name: "Moldovan Leu",
  },
  {
    code: "MGA",
    name: "Malagasy Ariary",
  },
  {
    code: "MKD",
    name: "Macedonian Denar",
  },
  {
    code: "MMK",
    name: "Myanma Kyat",
  },
  {
    code: "MNT",
    name: "Mongolian Tugrik",
  },
  {
    code: "MOP",
    name: "Macanese Pataca",
  },
  {
    code: "MRO",
    name: "Mauritanian Ouguiya",
  },
  {
    code: "MUR",
    name: "Mauritian Rupee",
  },
  {
    code: "MVR",
    name: "Maldivian Rufiyaa",
  },
  {
    code: "MWK",
    name: "Malawian Kwacha",
  },
  {
    code: "MYR",
    name: "Malaysian Ringgit",
  },
  {
    code: "MZN",
    name: "Mozambican Metical",
  },
  {
    code: "NAD",
    name: "Namibian Dollar",
  },
  {
    code: "NGN",
    name: "Nigerian Naira",
  },
  {
    code: "NIO",
    name: "Nicaraguan Córdoba",
  },
  {
    code: "NOK",
    name: "Norwegian Krone",
  },
  {
    code: "NPR",
    name: "Nepalese Rupee",
  },
  {
    code: "NZD",
    name: "New Zealand Dollar",
  },
  {
    code: "OMR",
    name: "Omani Rial",
  },
  {
    code: "PAB",
    name: "Panamanian Balboa",
  },
  {
    code: "PEN",
    name: "Peruvian Nuevo Sol",
  },
  {
    code: "PGK",
    name: "Papua New Guinean Kina",
  },
  {
    code: "PHP",
    name: "Philippine Peso",
  },
  {
    code: "PKR",
    name: "Pakistani Rupee",
  },
  {
    code: "PLN",
    name: "Polish Złoty",
  },
  {
    code: "PYG",
    name: "Paraguayan Guaraní",
  },
  {
    code: "QAR",
    name: "Qatari Riyal",
  },
  {
    code: "RON",
    name: "Romanian New Leu",
  },
  {
    code: "RSD",
    name: "Serbian Dinar",
  },
  {
    code: "RWF",
    name: "Rwandan Franc",
  },
  {
    code: "SAR",
    name: "Saudi Riyal",
  },
  {
    code: "SBD",
    name: "Solomon Islands Dollar",
  },
  {
    code: "SCR",
    name: "Seychelles Rupee",
  },
  {
    code: "SDG",
    name: "Sudanese Pound",
  },
  {
    code: "SEK",
    name: "Swedish Krona/kronor",
  },
  {
    code: "SGD",
    name: "Singapore Dollar",
  },
  {
    code: "SHP",
    name: "Saint Helena Pound",
  },
  {
    code: "SLL",
    name: "Sierra Leonean Leone",
  },
  {
    code: "SOS",
    name: "Somali Shilling",
  },
  {
    code: "SRD",
    name: "Surinamese Dollar",
  },
  {
    code: "SSP",
    name: "South Sudanese Pound",
  },
  {
    code: "STD",
    name: "São Tomé And Príncipe Dobra",
  },
  {
    code: "SYP",
    name: "Syrian Pound",
  },
  {
    code: "SZL",
    name: "Swazi Lilangeni",
  },
  {
    code: "THB",
    name: "Thai Baht",
  },
  {
    code: "TJS",
    name: "Tajikistani Somoni",
  },
  {
    code: "TMT",
    name: "Turkmenistani Manat",
  },
  {
    code: "TND",
    name: "Tunisian Dinar",
  },
  {
    code: "TOP",
    name: "Tongan Paʻanga",
  },
  {
    code: "TRY",
    name: "Turkish Lira",
  },
  {
    code: "TTD",
    name: "Trinidad And Tobago Dollar",
  },
  {
    code: "TWD",
    name: "New Taiwan Dollar",
  },
  {
    code: "TZS",
    name: "Tanzanian Shilling",
  },
  {
    code: "UAH",
    name: "Ukrainian Hryvnia",
  },
  {
    code: "UGX",
    name: "Ugandan Shilling",
  },
  {
    code: "USD",
    name: "United States Dollar",
  },
  {
    code: "UYU",
    name: "Uruguayan Peso",
  },
  {
    code: "UZS",
    name: "Uzbekistan Som",
  },
  {
    code: "VEF",
    name: "Venezuelan Bolívar Fuerte",
  },
  {
    code: "VND",
    name: "Vietnamese Dong",
  },
  {
    code: "VUV",
    name: "Vanuatu Vatu",
  },
  {
    code: "WST",
    name: "Samoan Tala",
  },
  {
    code: "XCD",
    name: "East Caribbean Dollar",
  },
  {
    code: "XPF",
    name: "CFP Franc",
  },
  {
    code: "YER",
    name: "Yemeni Rial",
  },
  {
    code: "ZAR",
    name: "South African Rand",
  },
  {
    code: "ZMW",
    name: "Zambian Kwacha",
  },
];

export const currency_full_name_list = {
  AED: "United Arab Emirates Dirham",
  AFN: "Afghan Afghani",
  ALL: "Albanian Lek",
  AMD: "Armenian Dram",
  ANG: "Netherlands Antillean Guilder",
  AOA: "Angolan Kwanza",
  ARS: "Argentine Peso",
  AUD: "Australian Dollar",
  AWG: "Aruban Florin",
  AZN: "Azerbaijani Manat",
  BBD: "Barbados Dollar",
  BDT: "Bangladeshi Taka",
  BGN: "Bulgarian Lev",
  BHD: "Bahraini Dinar",
  BIF: "Burundian Franc",
  BMD: "Bermudian Dollar",
  BND: "Brunei Dollar",
  BOB: "Boliviano",
  BOV: "Bolivian Mvdol",
  BRL: "Brazilian Real",
  BSD: "Bahamian Dollar",
  BTN: "Bhutanese Ngultrum",
  BWP: "Botswana Pula",
  BYR: "Belarusian Ruble",
  BZD: "Belize Dollar",
  CAD: "Canadian Dollar",
  CDF: "Congolese Franc",
  CHF: "Swiss Franc",
  CLP: "Chilean Peso",
  CNY: "Chinese Yuan",
  COP: "Colombian Peso",
  COU: "Unidad De Valor Real",
  CRC: "Costa Rican Colon",
  CUC: "Cuban Convertible Peso",
  CUP: "Cuban Peso",
  CVE: "Cape Verde Escudo",
  CZK: "Czech Koruna",
  DJF: "Djiboutian Franc",
  DKK: "Danish Krone",
  DOP: "Dominican Peso",
  DZD: "Algerian Dinar",
  EGP: "Egyptian Pound",
  ERN: "Eritrean Nakfa",
  ETB: "Ethiopian Birr",
  EUR: "Euro",
  FJD: "Fiji Dollar",
  FKP: "Falkland Islands Pound",
  GBP: "Pound Sterling",
  GEL: "Georgian Lari",
  GHS: "Ghanaian Cedi",
  GIP: "Gibraltar Pound",
  GMD: "Gambian Dalasi",
  GNF: "Guinean Franc",
  GTQ: "Guatemalan Quetzal",
  GYD: "Guyanese Dollar",
  HKD: "Hong Kong Dollar",
  HNL: "Honduran Lempira",
  HRK: "Croatian Kuna",
  HTG: "Haitian Gourde",
  HUF: "Hungarian Forint",
  IDR: "Indonesian Rupiah",
  ILS: "Israeli New Shekel",
  INR: "Indian Rupee",
  IQD: "Iraqi Dinar",
  IRR: "Iranian Rial",
  ISK: "Icelandic Króna",
  JMD: "Jamaican Dollar",
  JOD: "Jordanian Dinar",
  JPY: "Japanese Yen",
  KES: "Kenyan Shilling",
  KGS: "Kyrgyzstani Som",
  KHR: "Cambodian Riel",
  KMF: "Comoro Franc",
  KPW: "North Korean Won",
  KRW: "South Korean Won",
  KWD: "Kuwaiti Dinar",
  KYD: "Cayman Islands Dollar",
  KZT: "Kazakhstani Tenge",
  LAK: "Lao Kip",
  LBP: "Lebanese Pound",
  LKR: "Sri Lankan Rupee",
  LRD: "Liberian Dollar",
  LSL: "Lesotho Loti",
  LTL: "Lithuanian Litas",
  LVL: "Latvian Lats",
  LYD: "Libyan Dinar",
  MAD: "Moroccan Dirham",
  MDL: "Moldovan Leu",
  MGA: "Malagasy Ariary",
  MKD: "Macedonian Denar",
  MMK: "Myanma Kyat",
  MNT: "Mongolian Tugrik",
  MOP: "Macanese Pataca",
  MRO: "Mauritanian Ouguiya",
  MUR: "Mauritian Rupee",
  MVR: "Maldivian Rufiyaa",
  MWK: "Malawian Kwacha",
  MYR: "Malaysian Ringgit",
  MZN: "Mozambican Metical",
  NAD: "Namibian Dollar",
  NGN: "Nigerian Naira",
  NIO: "Nicaraguan Córdoba",
  NOK: "Norwegian Krone",
  NPR: "Nepalese Rupee",
  NZD: "New Zealand Dollar",
  OMR: "Omani Rial",
  PAB: "Panamanian Balboa",
  PEN: "Peruvian Nuevo Sol",
  PGK: "Papua New Guinean Kina",
  PHP: "Philippine Peso",
  PKR: "Pakistani Rupee",
  PLN: "Polish Złoty",
  PYG: "Paraguayan Guaraní",
  QAR: "Qatari Riyal",
  RON: "Romanian New Leu",
  RSD: "Serbian Dinar",
  RWF: "Rwandan Franc",
  SAR: "Saudi Riyal",
  SBD: "Solomon Islands Dollar",
  SCR: "Seychelles Rupee",
  SDG: "Sudanese Pound",
  SEK: "Swedish Krona/Kronor",
  SGD: "Singapore Dollar",
  SHP: "Saint Helena Pound",
  SLL: "Sierra Leonean Leone",
  SOS: "Somali Shilling",
  SRD: "Surinamese Dollar",
  SSP: "South Sudanese Pound",
  STD: "São Tomé And Príncipe Dobra",
  SYP: "Syrian Pound",
  SZL: "Swazi Lilangeni",
  THB: "Thai Baht",
  TJS: "Tajikistani Somoni",
  TMT: "Turkmenistani Manat",
  TND: "Tunisian Dinar",
  TOP: "Tongan Paʻanga",
  TRY: "Turkish Lira",
  TTD: "Trinidad And Tobago Dollar",
  TWD: "New Taiwan Dollar",
  TZS: "Tanzanian Shilling",
  UAH: "Ukrainian Hryvnia",
  UGX: "Ugandan Shilling",
  USD: "United States Dollar",
  UYU: "Uruguayan Peso",
  UZS: "Uzbekistan Som",
  VEF: "Venezuelan Bolívar Fuerte",
  VND: "Vietnamese Dong",
  VUV: "Vanuatu Vatu",
  WST: "Samoan Tala",
  XCD: "East Caribbean Dollar",
  XPF: "CFP Franc",
  YER: "Yemeni Rial",
  ZAR: "South African Rand",
  ZMW: "Zambian Kwacha",
};

export const RBAC_POLICIES = {
  read_only: ["read_only"],
};

export const purpose_codes = {
  Global: {
    SUPA: "Supplier payment",
    PROL: "Payroll",
    TUIT: "Tuition",
    AFEN: "Payment to affiliate entity",
    TAXP: "Tax",
    RENT: "Rent",
    LOAN: "Loan",
    GIFT: "Gift",
    SELF: "Withdrawal to self",
  },
  BH: {
    GDEB: "Goods sold (Exports in fob value)",
    GDIB: "Goods bought (Imports in cif value)",
    STSB: "Sea Transport",
    ATSB: "Air transport",
    OTSB: "OTS Other methods of transport (including Postal and courier services)",
    TRVL: "Travel",
    GMSB: "Processing repair and maintenance services on goods",
    CONS: "Construction",
    INSP: "Insurance Services",
    FINS: "Financial Services",
    IPCB: "Charges for the use of intellectual property royalties",
    TCSB: "Telecommunications services",
    ITSB: "Computer services",
    ITSV: "Information services",
    RDSB: "Research and development services",
    PMSB: "Professional and management consulting services",
    TTSB: "Technical, trade- related and other business services",
    PRSB: "Personal, cultural, audiovisual and recreational services",
    IGDB: "Dividends intragroup",
    IIDB: "Interest on debt intragroup",
    PIPB: "Profits on Islamic products",
    PRRB: "Profits or rents on real estate",
    DOEB: "Dividends on equity not Intragroup",
    ISHB: "Income on investment funds shares",
    ISLB: "Interest on securities more than a year",
    ISSB: "Interest on securities less than a year",
    IOLB: "Income on loans",
    IODB: "Income on deposits",
    GOSB: "Government goods and services embassies etc",
    GRIB: "Government related income taxes, tariffs, capital transfers, etc",
    CHCB: "Charitable Contributions (Charity and Aid)",
    FAML: "Family Support (Workers’ remittances)",
    PROL: "Salary (Compensation of employees)",
    PPAB: "Purchase of real estate abroad from residents",
    PPLB: "Purchase of real estate in Bahrain from non-residents",
    CEAB: "Equity and Investment fund shares for the establishment of new company from residents abroad, equity ,of merger or acquisition of companies abroad from residents and participation to capital increase of related companies abroad",
    DSFB: "Debt instruments Intragroup foreign securities",
    RELB: "Reverse equity share in Bahrain",
    RDLB: "Reverse debt instruments in Bahrain",
    FSAB: "Equity other than investment fund shares in not related companies abroad",
    FIAB: "Investment fund shares foreign",
    DSAB: "Purchases and sales of foreign debt securities in not related companies- Less than a year",
    DLAB: "Purchases and sales of foreign debt securities in not related companies- More than a year",
    FDAB: "Financial derivatives foreign",
    DLFB: "Debt Instruments Intragroup loans, deposits foreign (above 10% share)",
    AFAB: "Receipts or payments from personal residents bank account or deposits abroad",
    SLAB: "Loans- Drawings or Repayments on loans extended to nonresidents- Short-term",
    LLAB: "Loans- Drawings or Repayments on loans extended to nonresidents- Long-term",
    LEAB: "Leasing abroad",
    RFSB: "Repos on foreign securities",
    TCRB: "Trade credits and advances receivable",
    CELB: "Equity and Investment fund shares for the establishment of new company in Bahrain from non-residents, equity of merger or acquisition of companies in Bahrain from non-residents and participation to capital increase of related companies from non-residents in Bahrain",
    LDSB: "Debt instruments intragroup securities in Bahrain",
    REAB: "Reverse equity share abroad",
    RDAB: "Reverse debt instruments abroad",
    FSLB: "Equity other than investment fund shares in not related companies in Bahrain",
    FILB: "Investment fund shares in Bahrain",
    DSLB: "Purchases and sales of securities issued by residents in not related companies- Less than a year",
    DLLB: "Purchases and sales of securities issued by residents in not related companies- More than a year",
    FDLB: "Financial derivatives in Bahrain",
    LDLB: "Debt Instruments Intragroup loans, deposits in Bahrain (above 10% share)",
    AFLB: "Receipts or payments from personal nonresidents bank account in Bahrain",
    SLLB: "Loans- Drawings or Repayments on foreign loans extended to residents- Short-term",
    LLLB: "Loans- Drawings or Repayments on foreign loans extended to residents- Long-term",
    LELB: "Leasing in Bahrain",
    RLSB: "Repos on securities issued by residents",
    TCPB: "Trade credits and advances payable",
  },
  CNY: {
    CBCT: "Cross-border capital transfer",
    CBGT: "Cross-border goods trade",
    CBST: "Cross-border service transfer",
    CBIR: "Cross-border individual remittance",
    CBOT: "Other transfers",
  },
  INR: {
    ADVR: "Advertising and public relations-related expenses",
    ADVF: "Fees for advisory, technical, academic or specialist assistance",
    CONS: "Construction costs/expenses",
    DFFG: "Delivery fees for goods",
    TUIT: "Education-related student expenses (Individual only)",
    PEXP: "Payments for exported goods",
    FAML: "Family maintenance",
    FUMU: "Mutual fund investment (Individual only)",
    TRGD: "Trade settlement for goods and general goods trades",
    HOTL: "Hotel accommodation (Individual only)",
    INSC: "Insurance claims payment",
    INSP: "Insurance premium (Individual only)",
    LOAN: "Repayment of loans (Individual only)",
    MDCL: "Medical treatment and expenses (Individual only)",
    BEXP: "Representative office expenses",
    OTHR: "Broker, front end, commitment, guarantee and custodian fees",
    PRES: "Purchase of residential property (Individual only)",
    ROYL: "Royalty, trademark, patent and copyright fees",
    ITSV: "Information service charges",
    INVS: "Investment in shares (Individual only)",
    TAXP: "Tax payment (Individual only)",
    SELF: "Transfer to own account",
    TRPT: "Transportation fees for goods",
    TRVL: "Travel",
    UTIL: "Utility bills (Individual only)",
  },
  MYR: {
    ADVR: "Advertising and public relations-related expenses",
    ADVF: "Fees for advisory, technical, academic or specialist assistance",
    BSIN: "Product indemnity insurance",
    CONS: "Construction costs/expenses",
    DFFG: "Delivery fees for goods",
    TUIT: "Education-related student expenses",
    PEXP: "Payments for exported goods",
    FAML: "Family maintenance",
    FUMU: "Mutual fund investment",
    HMSD: "Homesend Payments",
    HOTL: "Hotel accommodation",
    INSC: "Insurance claims payment",
    INSP: "Insurance premium",
    LOAN: "Repayment of loans",
    MDCL: "Medical treatment and expenses",
    BEXP: "Representative office expenses",
    OTHR: "Broker, front end, commitment, guarantee and custodian fees",
    PRES: "Purchase of residential property",
    RENT: "Property rental payment",
    ROYL: "Royalty, trademark, patent and copyright fees",
    ITSV: "Information service charges",
    INVS: "Investment in shares",
    TAXP: "Tax payment",
    SELF: "Transfer to own account",
    TRPT: "Transportation fees for goods",
    TRVL: "Travel",
    UTIL: "Utility bills",
  },
  AE: {
    ACMU: "Agency Commission",
    AESU: "Advance payment against EOS",
    AFAU: "Receipts or payments from personal residents bank account or deposits abroad",
    AFLU: "Receipts or payments from personal non-resident bank account in the UAE",
    ALWU: "Allowances",
    ATSU: "Air transport",
    BONU: "Bonus",
    CCPU: "Corporate Card Payment",
    CEAU: "Equity and investment fund shares for the establishment of new company from residents abroad, equity of merger or acquisition of companies abroad from residents and participation to capital increase of related companies abroad",
    CELU: "Equity and investment fund shares for the establishment of new company in the UAE from non-residents, equity of merger or acquisition of companies in the UAE from non-residents and participation to capital increase of related companies from non-residents in the UAE",
    CHCU: "Charitable Contributions",
    CINU: "Commercial Investments",
    COMM: "Commission",
    CRPU: "Credit Card Payments",
    DCPU: "Pre-Paid Reloadable & Personalized Debit Card Payments",
    DIVU: "Dividend Payouts",
    DLAU: "Purchases and sales of foreign debt securities in not related companies – More than a year",
    DLFU: "Debt instruments intragroup loans, deposits foreign",
    DLLU: "Purchases and sales of securities issued by residents in not related companies – More than a year",
    DOEU: "Dividends on equity not intra group",
    DSAU: "Purchases and sales of foreign debt securities in not related companies – Less than a year",
    DSFU: "Debt instruments intragroup foreign securities",
    DSLU: "Purchases and sales of securities issued by residents in not related companies – Less than a year",
    EDUU: "Educational Support",
    EMIU: "Equated Monthly Instalments",
    EOSU: "End of Service",
    FAML: "Family Support",
    FDAU: "Financial derivatives foreign",
    FDLU: "Financial derivatives in the UAE",
    FIAU: "Investment fund shares foreign",
    FILU: "Investment fund shares in the UAE",
    FINS: "Financial services",
    FSAU: "Equity other than investment fund shares in not related companies abroad",
    FSLU: "Equity other than investment fund shares in not related companies in the UAE",
    GDEU: "Goods Sold",
    GDIU: "Goods bought",
    GMSU: "Processing repair and maintenance services on goods",
    GOSU: "Government goods and services embassies etc",
    GRIU: "Government related income taxes tariffs capital transfers etc",
    ITSV: "Information services",
    IGDU: "Intra group dividends",
    IGTU: "Inter group transfer",
    IIDU: "Intra group interest on debt",
    INSP: "Insurance services",
    IODU: "Income on deposits",
    IOLU: "Income on loans",
    IPCU: "Charges for the use of intellectual property royalties",
    IPOU: "IPO subscriptions",
    IRPU: "Interest rate swap payments",
    IRWU: "Interest rate unwind payments",
    ISHU: "Income on investment funds share",
    ISLU: "Interest on securities more than a year",
    ISSU: "Interest on securities less than a year",
    ITSU: "Computer services",
    LASU: "Leave salary",
    LDLU: "Debt instruments intragroup loans, deposits in the UAE",
    LDSU: "Debt instruments intragroup securities in the UAE",
    LEAU: "Leasing abroad",
    LELU: "Leasing in the UAE",
    LOAN: "Loan interest payments",
    LLAU: "Loans – Drawings or Repayments on loans extended to nonresidents – Long-term",
    LLLU: "Loans – Drawings or Repayments on foreign loans extended to residents – Long-term",
    LNCU: "Loan charges",
    LNDU: "Loan disbursements",
    MCRU: "Monetary Claim Reimbursements Medical Insurance or Auto Insurance etc.",
    MWIU: "Mobile wallet cash in",
    MWOU: "Mobile wallet cash out",
    MWPU: "Mobile wallet payments",
    SELF: "Own account transfer",
    OTSU: "Other modes of transport",
    OVTU: "Overtime",
    PENU: "Pension",
    INVS: "Personal Investments",
    PIPU: "Profits on Islamic products",
    PMSU: "Professional and management consulting services",
    PORU: "Refunds or Reversals on IPO subscriptions",
    POSU: "POS Merchant Settlement",
    PPAU: "Purchase of real estate abroad from residents",
    PPLU: "Purchase of real estate in the UAE from non-residents",
    PRPU: "Profit rate swap payments",
    PRRU: "Profits or rents on real estate",
    PRSU: "Personal cultural audio visual and recreational services",
    PRWU: "Profit rate unwind payments",
    RDSU: "Research and development services",
    RFSU: "Repos on foreign securities",
    RLSU: "Repos on securities issued by residents",
    RENT: "Rent payments",
    PROA: "Salary advance",
    PROL: "Salary/Compensation",
    CONS: "Construction",
    SLAU: "Loans – Drawings or Repayments on loans extended to nonresidents – Short-term",
    SLLU: "Loans – Drawings or Repayments on foreign loans extended to residents – Short-term",
    TRVL: "Travel",
    STSU: "Sea transport",
    SVIU: "Stored value card cash-in",
    SVOU: "Stored value card cash-out",
    SVPU: "Stored value card payments",
    TCPU: "Trade credits and advances payable",
    TCRU: "Trade credits and advances receivable",
    TELE: "Telecommunication services",
    TKTU: "Tickets",
    TOFU: "Transfer of funds between persons Normal and Juridical",
    TTSU: "Technical trade-related and other business services",
    UTIL: "Utility Bill Payments",
  },
  SGD: {
    ACCT: "Account Management",
    ADVA: "Advance Payment",
    AFEN: "Payment to Affiliate Entity",
    AGRT: "Agricultural Transfer",
    AIRB: "Air",
    ALLW: "Allowance",
    ALMY: "Alimony Payment",
    ANNI: "Annuity",
    ANTS: "Anaesthesia Services",
    AREN: "Account Receivable Entry",
    BBSC: "Baby Bonus Scheme",
    BECH: "Child Benefit",
    BENE: "Unemployment Disability Benefit",
    BEXP: "Business Expenses",
    BOCE: "Back Office Conversion Entry",
    BONU: "Bonus Payment",
    BUSB: "Bus",
    CASH: "Cash Management Transfer",
    CBFF: "Capital Building",
    CBTV: "Cable TV Bill",
    CCRD: "Credit Card Payment",
    CDBL: "Credit Card Bill",
    CDCB: "Credit Payment with Cashback",
    CDCD: "Cash Disbursement",
    CDOC: "Original Credit",
    CDQC: "Quasi Cash",
    CFEE: "Cancellation Fee",
    CHAR: "Charity Payment",
    CLPR: "Car Loan Principal Repayment",
    CMDT: "Commodity Transfer",
    COLL: "Collection Payment",
    COMC: "Commercial Payment",
    COMM: "Commission",
    COMT: "Consumer Third Party Consolidate Payment",
    COST: "Costs",
    CPKC: "Carpark Charges",
    CPYR: "Copyright",
    CSDB: "Cash Disbursement",
    CSLP: "Company Social Loan Payment To Bank",
    CVCF: "Convalescent Care facility",
    DBTC: "Debit Collection Payment",
    DCRD: "Debit Card Payment",
    DEPT: "Deposit",
    DERI: "Derivatives",
    DIVD: "Dividend",
    DMEQ: "Durable Medical Equipment",
    DNTS: "Dental Services",
    EDUC: "Education",
    ELEC: "Electricity Bill",
    ENRG: "Energies",
    ESTX: "Estate Tax",
    FCPM: "Payment of Fees & Charges",
    FERB: "Ferry",
    FREX: "Foreign Exchange",
    FWLV: "Foreign Worker Levy",
    GASB: "Gas Bill",
    GDDS: "Purchase Sale Of Goods",
    GDSV: "Purchase Sale Of Goods And Services",
    GIFT: "Gift",
    GOVI: "Government Insurance",
    GOVT: "Government Payment",
    GSCB: "Purchase Sale Of Goods And Service With Cash Back",
    GSTX: "Goods & Services Tax",
    HEDG: "Hedging",
    HLRP: "Housing Loan Repayment",
    HLTC: "Home Health Care",
    HLTI: "Health Insurance",
    HSPC: "Hospital Care",
    HSTX: "Housing Tax",
    ICCP: "Irrevocable Credit Card Payment",
    ICRF: "Intermediate Care Facility",
    IDCP: "Irrevocable Debit Card Payment",
    IHRP: "Instalment Hire Purchase Agreement",
    INPC: "Insurance Premium Car",
    INSU: "Insurance Premium",
    INTC: "Intra Company Payment",
    INTE: "Interest",
    INVS: "Investment & Securities",
    IVPT: "Invoice Payment",
    LBRI: "Labor Insurance",
    LICF: "License Fee",
    LIFI: "Life Insurance",
    LIMA: "Liquidity Management",
    LOAN: "Loan",
    LOAR: "Loan Repayment",
    LTCF: "Long Term Care Facility",
    MDCS: "Medical Services",
    MSVC: "Multiple Service Type",
    NETT: "Netting",
    NITX: "Net Income Tax",
    NOWS: "Not Otherwise Specified",
    NWCH: "Network Charge",
    NWCM: "Network Communication",
    OFEE: "Opening Fee",
    OTHR: "Other",
    OTLC: "Other Telecom Related Bill",
    PADD: "Preauthorised Debit",
    PENS: "Pension Payment",
    PHON: "Telephone Bill",
    POPE: "Point Of Purchase Entry",
    PPTI: "Property Insurance",
    PRCP: "Price Payment",
    PRME: "Precious Metal",
    PROL: "Payroll",
    PTSP: "Payment Terms",
    PTXP: "Property Tax",
    RCKE: "Re-presented Check Entry",
    RCPT: "Receipt Payment",
    RDTX: "Road Tax",
    REBT: "Rebate",
    REFU: "Refund",
    RENT: "Rent",
    RINP: "Recurring Instalment Payment",
    RLWY: "Railway",
    ROYA: "Royalties",
    SALA: "Salary Payment",
    SAVG: "Savings",
    SCVE: "Purchase Sale of Services",
    SECU: "Securities",
    SELF: "Withdrawal to Self",
    SSBE: "Social Security Benefits",
    SUBS: "Subscription",
    SUPA: "Supplier Payment",
    TAXP: "Tax",
    TBIL: "Telco Bill",
    TCSC: "Town Council Service Charges",
    TELI: "Telephone-Initiated Transaction",
    TRAD: "Trade Services",
    TREA: "Treasury Payment",
    TRFD: "Trust Fund",
    TRPT: "Transport",
    TUIT: "Tuition/Study",
    UBIL: "Utilities",
    VATX: "Value Added Tax Payment",
    VIEW: "Vision Care",
    WEBI: "Internet-Initiated Transaction",
    WHLD: "With Holding",
    WTER: "Water Bill",
  },
  // SG: {
  //   ACCT: "Account Management",
  //   ADVA: "Advance Payment",
  //   AGRT: "Agricultural Transfer",
  //   AIRB: "Air",
  //   ALLW: "Allowance",
  //   ALMY: "Alimony Payment",
  //   ANNI: "Annuity",
  //   ANTS: "Anaesthesia Services",
  //   AREN: "Account Receivable Entry",
  //   BBSC: "Baby Bonus Scheme",
  //   BECH: "Child Benefit",
  //   BENE: "Unemployment Disability Benefit",
  //   BEXP: "Business Expenses",
  //   BOCE: "Back Office Conversion Entry",
  //   BONU: "Bonus Payment",
  //   BUSB: "Bus",
  //   CASH: "Cash Management Transfer",
  //   CBFF: "Capital Building",
  //   CBTV: "Cable TV Bill",
  //   CCRD: "Credit Card Payment",
  //   CDBL: "Credit Card Bill",
  //   CDCB: "Credit Payment with Cashback",
  //   CDCD: "Cash Disbursement",
  //   CDOC: "Original Credit",
  //   CDQC: "Quasi Cash",
  //   CFEE: "Cancellation Fee",
  //   CHAR: "Charity Payment",
  //   CLPR: "Car Loan Principal Repayment",
  //   CMDT: "Commodity Transfer",
  //   COLL: "Collection Payment",
  //   COMC: "Commercial Payment",
  //   COMM: "Commission",
  //   COMT: "Consumer Third Party Consolidate Payment",
  //   COST: "Costs",
  //   CPKC: "Carpark Charges",
  //   CPYR: "Copyright",
  //   CSDB: "Cash Disbursement",
  //   CSLP: "Company Social Loan Payment To Bank",
  //   CVCF: "Convalescent Care facility",
  //   DBTC: "Debit Collection Payment",
  //   DCRD: "Debit Card Payment",
  //   DEPT: "Deposit",
  //   DERI: "Derivatives",
  //   DIVD: "Dividend",
  //   DMEQ: "Durable Medical Equipment",
  //   DNTS: "Dental Services",
  //   EDUC: "Education",
  //   ELEC: "Electricity Bill",
  //   ENRG: "Energies",
  //   ESTX: "Estate Tax",
  //   FCPM: "Payment of Fees & Charges",
  //   FERB: "Ferry",
  //   FREX: "Foreign Exchange",
  //   FWLV: "Foreign Worker Levy",
  //   GASB: "Gas Bill",
  //   GDDS: "Purchase Sale Of Goods",
  //   GDSV: "Purchase Sale Of Goods And Services",
  //   GOVI: "Government Insurance",
  //   GOVT: "Government Payment",
  //   GSCB: "Purchase Sale Of Goods And Service With Cash Back",
  //   GSTX: "Goods & Services Tax",
  //   HEDG: "Hedging",
  //   HLRP: "Housing Loan Repayment",
  //   HLTC: "Home Health Care",
  //   HLTI: "Health Insurance",
  //   HSPC: "Hospital Care",
  //   HSTX: "Housing Tax",
  //   ICCP: "Irrevocable Credit Card Payment",
  //   ICRF: "Intermediate Care Facility",
  //   IDCP: "Irrevocable Debit Card Payment",
  //   IHRP: "Instalment Hire Purchase Agreement",
  //   INPC: "Insurance Premium Car",
  //   INSU: "Insurance Premium",
  //   INTC: "Intra Company Payment",
  //   INTE: "Interest",
  //   INVS: "Investment & Securities",
  //   IVPT: "Invoice Payment",
  //   LBRI: "Labor Insurance",
  //   LICF: "License Fee",
  //   LIFI: "Life Insurance",
  //   LIMA: "Liquidity Management",
  //   LOAN: "Loan",
  //   LOAR: "Loan Repayment",
  //   LTCF: "Long Term Care Facility",
  //   MDCS: "Medical Services",
  //   MSVC: "Multiple Service Type",
  //   NETT: "Netting",
  //   NITX: "Net Income Tax",
  //   NOWS: "Not Otherwise Specified",
  //   NWCH: "Network Charge",
  //   NWCM: "Network Communication",
  //   OFEE: "Opening Fee",
  //   OTHR: "Other",
  //   OTLC: "Other Telecom Related Bill",
  //   PADD: "Preauthorised Debit",
  //   PAYR: "Payroll",
  //   PENS: "Pension Payment",
  //   PHON: "Telephone Bill",
  //   POPE: "Point Of Purchase Entry",
  //   PPTI: "Property Insurance",
  //   PRCP: "Price Payment",
  //   PRME: "Precious Metal",
  //   PTSP: "Payment Terms",
  //   PTXP: "Property Tax",
  //   RCKE: "Re-presented Check Entry",
  //   RCPT: "Receipt Payment",
  //   RDTX: "Road Tax",
  //   REBT: "Rebate",
  //   REFU: "Refund",
  //   RENT: "Rent",
  //   RINP: "Recurring Instalment Payment",
  //   RLWY: "Railway",
  //   ROYA: "Royalties",
  //   SALA: "Salary Payment",
  //   SAVG: "Savings",
  //   SCVE: "Purchase Sale of Services",
  //   SECU: "Securities",
  //   SSBE: "Social Security Benefits",
  //   STDY: "Study",
  //   SUBS: "Subscription",
  //   SUPP: "Supplier Payment",
  //   TAXS: "Tax Payment",
  //   TBIL: "Telco Bill",
  //   TCSC: "Town Council Service Charges",
  //   TELI: "Telephone-Initiated Transaction",
  //   TRAD: "Trade Services",
  //   TREA: "Treasury Payment",
  //   TRFD: "Trust Fund",
  //   TRPT: "Transport",
  //   UBIL: "Utilities",
  //   VATX: "Value Added Tax Payment",
  //   VIEW: "Vision Care",
  //   WEBI: "Internet-Initiated Transaction",
  //   WHLD: "With Holding",
  //   WTER: "Water Bill",
  // },
};

export const payin_method_to_label = {
  au_bank_de_aud: "AUSPAYNET (NPP / DIRECT-ENTRY) - DOMESTIC ONLY",
  ca_bank_eft_cad: "LOCAL EFT - DOMESTIC ONLY",
  eu_bank_sepa_eur: "SEPA CREDIT TRANSFER (SCT) - DOMESTIC ONLY",
  gb_bank_fps_gbp: "UK DOMESTIC",
  global_bank_swift: "INTERNATIONAL WIRE TRANSFER VIA SWIFT",
  us_bank_ach_usd: "FED ACH - DOMESTIC ONLY",
  us_bank_fedwire_usd: "FED WIRE",
};

export const sanctioned_countries = [
  "AF",
  "AL",
  "AZ",
  "BY",
  "BA",
  "BI",
  "CF",
  "CG",
  "HR",
  "CU",
  "ER",
  "ET",
  "PS",
  "GW",
  "HT",
  "IR",
  "IQ",
  "XK",
  "LB",
  "LY",
  "MK",
  "MM",
  "NI",
  "KP",
  "RU",
  "SO",
  "SS",
  "SD",
  "SY",
  "TN",
  "VE",
  "YE",
  "ZW",
  "CD",
  "EH",
  "LR",
];

export const finmo_country_to_currency = {
  AU: ["AUD"],
  PH: ["PHP"],
  TH: ["THB"],
  NZ: ["NZD"],
  SG: ["SGD"],
};

export const soc_local_currency_to_country = {
  AUD: "AU",
  PHP: "PH",
  THB: "TH",
  NZD: "NZ",
  SGD: "SG",
};

export const dashboard_routes = {
  PAYIN: "/preview/dashboards/payments",
  PAYOUT: "/preview/dashboards/all-payouts",
  REFUND: "/preview/dashboards/refunds",
  VIRTUAL_ACCOUNT: "/preview/dashboards/virtual-accounts",
  SETTLEMENT: "/preview/dashboards/settlements",
  SETTLEMENT_PAYOUT: "/preview/dashboards/settlement-payout",
  WALLET_FUND_TRANSFER: "/preview/dashboards/wallet-fund-transfer",
  CUSTOMER: "/preview/dashboards/customers",
  WALLET: "/preview/dashboards/wallets",
  TRANSACTION: "/preview/dashboards/transactions",
  WALLET_TRANSACTION: "/preview/dashboards/wallets/transaction",
  CHECKOUT: "/preview/dashboards/sessions",
  PAYOUT_BENEFICIARY: "/preview/dashboards/payout-beneficiary",
  PAYOUT_SENDER: "/preview/dashboards/payout-sender",
  FX_CONVERSION: "/preview/dashboards/global-currency-conversion",
  INVOICE: "/preview/dashboards/invoices",
  ADJUSTMENT: "/preview/dashboards/adjustments",
  BULK_PAYOUT: "/preview/dashboards/all-bulk-payouts",
};

export const route_name = {
  PAYIN: "PAYIN",
  PAYOUT: "PAYOUT",
  REFUND: "REFUND",
  VIRTUAL_ACCOUNT: "VIRTUAL_ACCOUNT",
  SETTLEMENT: "SETTLEMENT",
  SETTLEMENT_PAYOUT: "SETTLEMENT_PAYOUT",
  WALLET_FUND_TRANSFER: "WALLET_FUND_TRANSFER",
  CUSTOMER: "CUSTOMER",
  WALLET: "WALLET",
  TRANSACTION: "TRANSACTION",
  WALLET_TRANSACTION: "WALLET_TRANSACTION",
  CHECKOUT: "CHECKOUT",
  PAYOUT_BENEFICIARY: "PAYOUT_BENEFICIARY",
  PAYOUT_SENDER: "PAYOUT_SENDER",
  FX_CONVERSION: "FX_CONVERSION",
  INVOICE: "INVOICE",
  ADJUSTMENT: "ADJUSTMENT",
  BULK_PAYOUT: "BULK_PAYOUT",
};

export const mt_103_message_fields = [
  { key: 20, value: "Sender's Reference" },
  { key: "13C", value: "Time Indication" },
  { key: "23B", value: "Bank Operation Code" },
  { key: "23E", value: "Instruction Code" },
  { key: "26T", value: "Transaction Type Code" },
  { key: "32A", value: "Value Date/Currency/Interbank Settled Amount" },
  { key: "33B", value: "Currency/Instructed Amount" },
  { key: 36, value: "Exchange Rate" },
  { key: "50A", value: "Ordering Customer" },
  { key: "50F", value: "Ordering Customer" },
  { key: "50K", value: "Ordering Customer" },
  { key: "51A", value: "Sending Institution" },
  { key: "52A", value: "Ordering Institution" },
  { key: "52D", value: "Ordering Institution" },
  { key: "53A", value: "Sender's Correspondent" },
  { key: "53B", value: "Sender's Correspondent" },
  { key: "53D", value: "Sender's Correspondent" },
  { key: "54A", value: "Receiver's Correspondent" },
  { key: "54B", value: "Receiver's Correspondent" },
  { key: "54D", value: "Receiver's Correspondent" },
  { key: "55A", value: "Third Reimbursement Institution" },
  { key: "55B", value: "Third Reimbursement Institution" },
  { key: "55D", value: "Third Reimbursement Institution" },
  { key: "56A", value: "Intermediary Institution" },
  { key: "56C", value: "Intermediary Institution" },
  { key: "56D", value: "Intermediary Institution" },
  { key: "57A", value: "Account With Institution" },
  { key: "57B", value: "Account With Institution" },
  { key: "57C", value: "Account With Institution" },
  { key: "57D", value: "Account With Institution" },
  { key: 59, value: "Beneficiary Customer" },
  { key: "59A", value: "Beneficiary Customer" },
  { key: "59F", value: "Beneficiary Customer" },
  { key: 70, value: "Remittance Information" },
  { key: "71A", value: "Details of Charges" },
  { key: "71F", value: "Sender's Charges" },
  { key: "71G", value: "Receiver's Charges" },
  { key: 72, value: "Sender to Receiver Information" },
  { key: "77B", value: "Regulatory Reporting" },
];

export const column_setting_keys = {
  TRANSACTION_COLUMNS: "transaction_columns",
  ORGANIZATION_COLUMNS: "organization_columns",
  PRICING_SETUP_COLUMNS: "pricing_setup_columns",
  SETTLEMENT_BANK_COLUMNS: "settlement_bank_columns",
  REPORT_COLUMNS: "report_columns",
  ORG_USER_COLUMNS: "org_user_columns",
};

export const wft_allowed_currency_list = ["AUD", "PHP", "THB", "NZD", "SGD"];

export const finmo_platform_currency_list = ["AUD", "PHP", "THB", "NZD", "SGD"];

export const notification_specific_org_user_roles_list = [
  { key: "ADMIN", value: "ADMIN" },
  { key: "READ_ONLY", value: "NON-ADMIN" },
  { key: "DEVELOPER", value: "DEVELOPER" },
];

export const notification_email_events_list = [
  "PAYIN",
  "REFUND",
  "PAYOUT",
  "BULK-PAYOUT",
  "ADJUSTMENT",
  "VIRTUAL-ACCOUNT",
  "WALLET-FUND-TRANSFER",
  "CUSTOMER",
  "FX-CONVERSION",
  "SETTLEMENT-PAYOUT",
];
export const auth_token_expiry_warning = 900000; //15 min

export const country_timezones = {
  AD: { "Europe/Andorra": "+01:00" },
  AE: { "Asia/Dubai": "+04:00" },
  AF: { "Asia/Kabul": "+04:30" },
  AG: { "America/Puerto_Rico": "-04:00" },
  AI: { "America/Puerto_Rico": "-04:00" },
  AL: { "Europe/Tirane": "+01:00" },
  AM: { "Asia/Yerevan": "+04:00" },
  AO: { "Africa/Lagos": "+01:00" },
  AQ: {
    "Antarctica/Casey": "+11:00",
    "Antarctica/Davis": "+07:00",
    "Antarctica/Mawson": "+05:00",
    "Antarctica/Palmer": "-03:00",
    "Antarctica/Rothera": "-03:00",
    "Antarctica/Troll": "+00:00",
    "Antarctica/Vostok": "+06:00",
    "Asia/Riyadh": "+03:00",
    "Pacific/Auckland": "+12:00",
    "Pacific/Port_Moresby": "+10:00",
  },
  AR: {
    "America/Argentina/Buenos_Aires": "-03:00",
    "America/Argentina/Catamarca": "-03:00",
    "America/Argentina/Cordoba": "-03:00",
    "America/Argentina/Jujuy": "-03:00",
    "America/Argentina/La_Rioja": "-03:00",
    "America/Argentina/Mendoza": "-03:00",
    "America/Argentina/Rio_Gallegos": "-03:00",
    "America/Argentina/Salta": "-03:00",
    "America/Argentina/San_Juan": "-03:00",
    "America/Argentina/San_Luis": "-03:00",
    "America/Argentina/Tucuman": "-03:00",
    "America/Argentina/Ushuaia": "-03:00",
  },
  AS: { "Pacific/Pago_Pago": "-11:00" },
  AT: { "Europe/Vienna": "+01:00" },
  AU: {
    "Australia/Sydney": "+10:00",
    "Antarctica/Macquarie": "+10:00",
    "Australia/Adelaide": "+09:30",
    "Australia/Brisbane": "+10:00",
    "Australia/Broken_Hill": "+09:30",
    "Australia/Darwin": "+09:30",
    "Australia/Eucla": "+08:45",
    "Australia/Hobart": "+10:00",
    "Australia/Lindeman": "+10:00",
    "Australia/Lord_Howe": "+10:30",
    "Australia/Melbourne": "+10:00",
    "Australia/Perth": "+08:00",
  },
  AW: { "America/Puerto_Rico": "-04:00" },
  AX: { "Europe/Helsinki": "+02:00" },
  AZ: { "Asia/Baku": "+04:00" },
  BA: { "Europe/Belgrade": "+01:00" },
  BB: { "America/Barbados": "-04:00" },
  BD: { "Asia/Dhaka": "+06:00" },
  BE: { "Europe/Brussels": "+01:00" },
  BF: { "Africa/Abidjan": "+00:00" },
  BG: { "Europe/Sofia": "+02:00" },
  BH: { "Asia/Qatar": "+03:00" },
  BI: { "Africa/Maputo": "+02:00" },
  BJ: { "Africa/Lagos": "+01:00" },
  BL: { "America/Puerto_Rico": "-04:00" },
  BM: { "Atlantic/Bermuda": "-04:00" },
  BN: { "Asia/Brunei": "+08:00" },
  BO: { "America/La_Paz": "-04:00" },
  BR: {
    "America/Araguaina": "-03:00",
    "America/Bahia": "-03:00",
    "America/Belem": "-03:00",
    "America/Boa_Vista": "-04:00",
    "America/Campo_Grande": "-04:00",
    "America/Cuiaba": "-04:00",
    "America/Eirunepe": "-05:00",
    "America/Fortaleza": "-03:00",
    "America/Maceio": "-03:00",
    "America/Manaus": "-04:00",
    "America/Noronha": "-02:00",
    "America/Porto_Velho": "-04:00",
    "America/Recife": "-03:00",
    "America/Rio_Branco": "-05:00",
    "America/Santarem": "-03:00",
    "America/Sao_Paulo": "-03:00",
  },
  BS: { "America/Toronto": "-05:00" },
  BT: { "Asia/Thimphu": "+06:00" },
  BV: { "Europe/Oslo": "+01:00" },
  BW: { "Africa/Maputo": "+02:00" },
  BY: { "Europe/Minsk": "+03:00" },
  BZ: { "America/Belize": "-06:00" },
  CA: {
    "America/Cambridge_Bay": "-07:00",
    "America/Dawson": "-07:00",
    "America/Dawson_Creek": "-07:00",
    "America/Edmonton": "-07:00",
    "America/Fort_Nelson": "-07:00",
    "America/Glace_Bay": "-04:00",
    "America/Goose_Bay": "-04:00",
    "America/Halifax": "-04:00",
    "America/Inuvik": "-07:00",
    "America/Iqaluit": "-05:00",
    "America/Moncton": "-04:00",
    "America/Nipigon": "-05:00",
    "America/Panama": "-05:00",
    "America/Pangnirtung": "-05:00",
    "America/Phoenix": "-07:00",
    "America/Puerto_Rico": "-04:00",
    "America/Rainy_River": "-06:00",
    "America/Rankin_Inlet": "-06:00",
    "America/Regina": "-06:00",
    "America/Resolute": "-06:00",
    "America/St_Johns": "-03:30",
    "America/Swift_Current": "-06:00",
    "America/Thunder_Bay": "-05:00",
    "America/Toronto": "-05:00",
    "America/Vancouver": "-08:00",
    "America/Whitehorse": "-07:00",
    "America/Winnipeg": "-06:00",
    "America/Yellowknife": "-07:00",
  },
  CC: { "Indian/Cocos": "+06:30" },
  CD: { "Africa/Lagos": "+01:00", "Africa/Maputo": "+02:00" },
  CF: { "Africa/Lagos": "+01:00" },
  CG: { "Africa/Lagos": "+01:00" },
  CH: { "Europe/Zurich": "+01:00" },
  CI: { "Africa/Abidjan": "+00:00" },
  CK: { "Pacific/Rarotonga": "-10:00" },
  CL: {
    "America/Punta_Arenas": "-03:00",
    "America/Santiago": "-04:00",
    "Pacific/Easter": "-06:00",
  },
  CM: { "Africa/Lagos": "+01:00" },
  CN: { "Asia/Shanghai": "+08:00", "Asia/Urumqi": "+06:00" },
  CO: { "America/Bogota": "-05:00" },
  CR: { "America/Costa_Rica": "-06:00" },
  CU: { "America/Havana": "-05:00" },
  CW: { "America/Puerto_Rico": "-04:00" },
  CV: { "Atlantic/Cape_Verde": "-01:00" },
  CX: { "Indian/Christmas": "+07:00" },
  CY: { "Asia/Famagusta": "+02:00", "Asia/Nicosia": "+02:00" },
  CZ: { "Europe/Prague": "+01:00" },
  DE: { "Europe/Berlin": "+01:00", "Europe/Zurich": "+01:00" },
  DJ: { "Africa/Nairobi": "+03:00" },
  DK: { "Europe/Copenhagen": "+01:00" },
  DM: { "America/Puerto_Rico": "-04:00" },
  DO: { "America/Santo_Domingo": "-04:00" },
  DZ: { "Africa/Algiers": "+01:00" },
  EC: { "America/Guayaquil": "-05:00", "Pacific/Galapagos": "-06:00" },
  EE: { "Europe/Tallinn": "+02:00" },
  EG: { "Africa/Cairo": "+02:00" },
  EH: { "Africa/El_Aaiun": "+01:00" },
  ER: { "Africa/Nairobi": "+03:00" },
  ES: {
    "Africa/Ceuta": "+01:00",
    "Atlantic/Canary": "+00:00",
    "Europe/Madrid": "+01:00",
  },
  ET: { "Africa/Nairobi": "+03:00" },
  FI: { "Europe/Helsinki": "+02:00" },
  FJ: { "Pacific/Fiji": "+12:00" },
  FK: { "Atlantic/Stanley": "-03:00" },
  FM: {
    "Pacific/Chuuk": "+10:00",
    "Pacific/Kosrae": "+11:00",
    "Pacific/Pohnpei": "+11:00",
  },
  FO: { "Atlantic/Faroe": "+00:00" },
  FR: { "Europe/Paris": "+01:00" },
  GA: { "Africa/Lagos": "+01:00" },
  GB: { "Europe/London": "+00:00" },
  GD: { "America/Puerto_Rico": "-04:00" },
  GE: { "Asia/Tbilisi": "+04:00" },
  GF: { "America/Cayenne": "-03:00" },
  GG: { "Europe/London": "+00:00" },
  GH: { "Africa/Abidjan": "+00:00" },
  GI: { "Europe/Gibraltar": "+01:00" },
  GL: {
    "America/Danmarkshavn": "+00:00",
    "America/Nuuk": "-03:00",
    "America/Scoresbysund": "-01:00",
    "America/Thule": "-04:00",
  },
  GM: { "Africa/Abidjan": "+00:00" },
  GN: { "Africa/Abidjan": "+00:00" },
  GP: { "America/Puerto_Rico": "-04:00" },
  GQ: { "Africa/Lagos": "+01:00" },
  GR: { "Europe/Athens": "+02:00" },
  GS: { "Atlantic/South_Georgia": "-02:00" },
  GT: { "America/Guatemala": "-06:00" },
  GU: { "Pacific/Guam": "+10:00" },
  GW: { "Africa/Bissau": "+00:00" },
  GY: { "America/Guyana": "-04:00" },
  HK: { "Asia/Hong_Kong": "+08:00" },
  HM: { "Indian/Kerguelen": "+05:00" },
  HN: { "America/Tegucigalpa": "-06:00" },
  HR: { "Europe/Belgrade": "+01:00" },
  HT: { "America/Port-au-Prince": "-05:00" },
  HU: { "Europe/Budapest": "+01:00" },
  ID: {
    "Asia/Jakarta": "+07:00",
    "Asia/Jayapura": "+09:00",
    "Asia/Makassar": "+08:00",
    "Asia/Pontianak": "+07:00",
  },
  IE: { "Europe/Dublin": "+01:00" },
  IL: { "Asia/Jerusalem": "+02:00" },
  IM: { "Europe/London": "+00:00" },
  IN: { "Asia/Kolkata": "+05:30" },
  IO: { "Indian/Chagos": "+06:00" },
  IQ: { "Asia/Baghdad": "+03:00" },
  IR: { "Asia/Tehran": "+03:30" },
  IS: { "Atlantic/Reykjavik": "+00:00" },
  IT: { "Europe/Rome": "+01:00" },
  JE: { "Europe/London": "+00:00" },
  JM: { "America/Jamaica": "-05:00" },
  JO: { "Asia/Amman": "+02:00" },
  JP: { "Asia/Tokyo": "+09:00" },
  KE: { "Africa/Nairobi": "+03:00" },
  KG: { "Asia/Bishkek": "+06:00" },
  KH: { "Asia/Bangkok": "+07:00" },
  KI: {
    "Pacific/Kanton": "+13:00",
    "Pacific/Kiritimati": "+14:00",
    "Pacific/Tarawa": "+12:00",
  },
  KM: { "Africa/Nairobi": "+03:00" },
  KN: { "America/Puerto_Rico": "-04:00" },
  KP: { "Asia/Pyongyang": "+09:00" },
  KR: { "Asia/Seoul": "+09:00" },
  KW: { "Asia/Riyadh": "+03:00" },
  KY: { "America/Panama": "-05:00" },
  KZ: {
    "Asia/Almaty": "+06:00",
    "Asia/Aqtau": "+05:00",
    "Asia/Aqtobe": "+05:00",
    "Asia/Atyrau": "+05:00",
    "Asia/Oral": "+05:00",
    "Asia/Qostanay": "+06:00",
    "Asia/Qyzylorda": "+05:00",
  },
  LA: { "Asia/Bangkok": "+07:00" },
  LB: { "Asia/Beirut": "+02:00" },
  LC: { "America/Puerto_Rico": "-04:00" },
  LI: { "Europe/Zurich": "+01:00" },
  LK: { "Asia/Colombo": "+05:30" },
  LR: { "Africa/Monrovia": "+00:00" },
  LS: { "Africa/Johannesburg": "+02:00" },
  LT: { "Europe/Vilnius": "+02:00" },
  LU: { "Europe/Luxembourg": "+01:00" },
  LV: { "Europe/Riga": "+02:00" },
  LY: { "Africa/Tripoli": "+02:00" },
  MA: { "Africa/Casablanca": "+01:00" },
  MC: { "Europe/Monaco": "+01:00" },
  MD: { "Europe/Chisinau": "+02:00" },
  ME: { "Europe/Belgrade": "+01:00" },
  MF: { "America/Puerto_Rico": "-04:00" },
  MG: { "Africa/Nairobi": "+03:00" },
  MH: { "Pacific/Kwajalein": "+12:00", "Pacific/Majuro": "+12:00" },
  MK: { "Europe/Belgrade": "+01:00" },
  ML: { "Africa/Abidjan": "+00:00" },
  MM: { "Asia/Yangon": "+06:30" },
  MN: {
    "Asia/Choibalsan": "+08:00",
    "Asia/Hovd": "+07:00",
    "Asia/Ulaanbaatar": "+08:00",
  },
  MO: { "Asia/Macau": "+08:00" },
  MP: { "Pacific/Guam": "+10:00" },
  MQ: { "America/Martinique": "-04:00" },
  MR: { "Africa/Abidjan": "+00:00" },
  MS: { "America/Puerto_Rico": "-04:00" },
  MT: { "Europe/Malta": "+01:00" },
  MU: { "Indian/Mauritius": "+04:00" },
  MV: { "Indian/Maldives": "+05:00" },
  MW: { "Africa/Maputo": "+02:00" },
  MX: {
    "America/Bahia_Banderas": "-06:00",
    "America/Cancun": "-05:00",
    "America/Chihuahua": "-07:00",
    "America/Hermosillo": "-07:00",
    "America/Matamoros": "-06:00",
    "America/Mazatlan": "-07:00",
    "America/Merida": "-06:00",
    "America/Mexico_City": "-06:00",
    "America/Monterrey": "-06:00",
    "America/Ojinaga": "-07:00",
    "America/Tijuana": "-08:00",
  },
  MY: {
    "Asia/Kuala_Lumpur": "+08:00",
    "Asia/Kuching": "+08:00",
    "Asia/Singapore": "+08:00",
  },
  MZ: { "Africa/Maputo": "+02:00" },
  NA: { "Africa/Windhoek": "+02:00" },
  NC: { "Pacific/Noumea": "+11:00" },
  NE: { "Africa/Lagos": "+01:00" },
  NF: { "Pacific/Norfolk": "+11:00" },
  NG: { "Africa/Lagos": "+01:00" },
  NI: { "America/Managua": "-06:00" },
  NL: { "Europe/Amsterdam": "+01:00" },
  NO: { "Europe/Oslo": "+01:00" },
  NP: { "Asia/Kathmandu": "+05:45" },
  NR: { "Pacific/Nauru": "+12:00" },
  NU: { "Pacific/Niue": "-11:00" },
  NZ: { "Pacific/Auckland": "+12:00", "Pacific/Chatham": "+12:45" },
  OM: { "Asia/Dubai": "+04:00" },
  PA: { "America/Panama": "-05:00" },
  PE: { "America/Lima": "-05:00" },
  PF: {
    "Pacific/Gambier": "-09:00",
    "Pacific/Marquesas": "-09:30",
    "Pacific/Tahiti": "-10:00",
  },
  PG: {
    "Pacific/Bougainville": "+11:00",
    "Pacific/Port_Moresby": "+10:00",
  },
  PH: { "Asia/Manila": "+08:00" },
  PK: { "Asia/Karachi": "+05:00" },
  PL: { "Europe/Warsaw": "+01:00" },
  PM: { "America/Miquelon": "-03:00" },
  PN: { "Pacific/Pitcairn": "-08:00" },
  PR: { "America/Puerto_Rico": "-04:00" },
  PS: { "Asia/Gaza": "+02:00", "Asia/Hebron": "+02:00" },
  PT: {
    "Atlantic/Azores": "-01:00",
    "Atlantic/Madeira": "+00:00",
    "Europe/Lisbon": "+00:00",
  },
  PW: { "Pacific/Palau": "+09:00" },
  PY: { "America/Asuncion": "-04:00" },
  QA: { "Asia/Qatar": "+03:00" },
  RE: { "Indian/Reunion": "+04:00" },
  RO: { "Europe/Bucharest": "+02:00" },
  RS: { "Europe/Belgrade": "+01:00" },
  RU: {
    "Asia/Anadyr": "+12:00",
    "Asia/Barnaul": "+07:00",
    "Asia/Chita": "+09:00",
    "Asia/Irkutsk": "+08:00",
    "Asia/Kamchatka": "+12:00",
    "Asia/Khandyga": "+09:00",
    "Asia/Krasnoyarsk": "+07:00",
    "Asia/Magadan": "+11:00",
    "Asia/Novokuznetsk": "+07:00",
    "Asia/Novosibirsk": "+07:00",
    "Asia/Omsk": "+06:00",
    "Asia/Sakhalin": "+11:00",
    "Asia/Srednekolymsk": "+11:00",
    "Asia/Tomsk": "+07:00",
    "Asia/Ust-Nera": "+10:00",
    "Asia/Vladivostok": "+10:00",
    "Asia/Yakutsk": "+09:00",
    "Asia/Yekaterinburg": "+05:00",
    "Europe/Astrakhan": "+04:00",
    "Europe/Kaliningrad": "+02:00",
    "Europe/Kirov": "+03:00",
    "Europe/Moscow": "+03:00",
    "Europe/Samara": "+04:00",
    "Europe/Saratov": "+04:00",
    "Europe/Simferopol": "+03:00",
    "Europe/Ulyanovsk": "+04:00",
    "Europe/Volgograd": "+03:00",
  },
  RW: { "Africa/Maputo": "+02:00" },
  SA: { "Asia/Riyadh": "+03:00" },
  SB: { "Pacific/Guadalcanal": "+11:00" },
  SC: { "Indian/Mahe": "+04:00" },
  SD: { "Africa/Khartoum": "+02:00" },
  SE: { "Europe/Stockholm": "+01:00" },
  SG: { "Asia/Singapore": "+08:00" },
  SH: { "Africa/Abidjan": "+00:00" },
  SI: { "Europe/Belgrade": "+01:00" },
  SJ: { "Europe/Oslo": "+01:00" },
  SK: { "Europe/Prague": "+01:00" },
  SL: { "Africa/Abidjan": "+00:00" },
  SM: { "Europe/Rome": "+01:00" },
  SN: { "Africa/Abidjan": "+00:00" },
  SO: { "Africa/Nairobi": "+03:00" },
  SR: { "America/Paramaribo": "-03:00" },
  ST: { "Africa/Sao_Tome": "+00:00" },
  SV: { "America/El_Salvador": "-06:00" },
  SY: { "Asia/Damascus": "+02:00" },
  SZ: { "Africa/Johannesburg": "+02:00" },
  TC: { "America/Grand_Turk": "-05:00" },
  TD: { "Africa/Ndjamena": "+01:00" },
  TF: { "Indian/Kerguelen": "+05:00", "Indian/Reunion": "+04:00" },
  TG: { "Africa/Abidjan": "+00:00" },
  TH: { "Asia/Bangkok": "+07:00" },
  TJ: { "Asia/Dushanbe": "+05:00" },
  TK: { "Pacific/Fakaofo": "+13:00" },
  TL: { "Asia/Dili": "+09:00" },
  TM: { "Asia/Ashgabat": "+05:00" },
  TN: { "Africa/Tunis": "+01:00" },
  TO: { "Pacific/Tongatapu": "+13:00" },
  TR: { "Europe/Istanbul": "+03:00" },
  TT: { "America/Puerto_Rico": "-04:00" },
  TV: { "Pacific/Funafuti": "+12:00" },
  TW: { "Asia/Taipei": "+08:00" },
  TZ: { "Africa/Nairobi": "+03:00" },
  UA: {
    "Europe/Kiev": "+02:00",
    "Europe/Simferopol": "+03:00",
    "Europe/Uzhgorod": "+02:00",
    "Europe/Zaporozhye": "+02:00",
  },
  UG: { "Africa/Nairobi": "+03:00" },
  UM: {
    "Pacific/Honolulu": "-10:00",
    "Pacific/Pago_Pago": "-11:00",
    "Pacific/Wake": "+12:00",
  },
  US: {
    "America/Adak": "-10:00",
    "America/Anchorage": "-09:00",
    "America/Boise": "-07:00",
    "America/Chicago": "-06:00",
    "America/Denver": "-07:00",
    "America/Detroit": "-05:00",
    "America/Indiana/Indianapolis": "-05:00",
    "America/Indiana/Knox": "-06:00",
    "America/Indiana/Marengo": "-05:00",
    "America/Indiana/Petersburg": "-05:00",
    "America/Indiana/Tell_City": "-06:00",
    "America/Indiana/Vevay": "-05:00",
    "America/Indiana/Vincennes": "-05:00",
    "America/Indiana/Winamac": "-05:00",
    "America/Juneau": "-09:00",
    "America/Kentucky/Louisville": "-05:00",
    "America/Kentucky/Monticello": "-05:00",
    "America/Los_Angeles": "-08:00",
    "America/Menominee": "-06:00",
    "America/Metlakatla": "-09:00",
    "America/New_York": "-05:00",
    "America/Nome": "-09:00",
    "America/North_Dakota/Beulah": "-06:00",
    "America/North_Dakota/Center": "-06:00",
    "America/North_Dakota/New_Salem": "-06:00",
    "America/Phoenix": "-07:00",
    "America/Sitka": "-09:00",
    "America/Yakutat": "-09:00",
    "Pacific/Honolulu": "-10:00",
  },
  UY: { "America/Montevideo": "-03:00" },
  UZ: { "Asia/Samarkand": "+05:00", "Asia/Tashkent": "+05:00" },
  VA: { "Europe/Rome": "+01:00" },
  VC: { "America/Puerto_Rico": "-04:00" },
  VE: { "America/Caracas": "-04:00" },
  VG: { "America/Puerto_Rico": "-04:00" },
  VI: { "America/Puerto_Rico": "-04:00" },
  VN: { "Asia/Bangkok": "+07:00", "Asia/Ho_Chi_Minh": "+07:00" },
  VU: { "Pacific/Efate": "+11:00" },
  WF: { "Pacific/Wallis": "+12:00" },
  WS: { "Pacific/Apia": "+13:00" },
  YE: { "Asia/Riyadh": "+03:00" },
  YT: { "Africa/Nairobi": "+03:00" },
  ZA: { "Africa/Johannesburg": "+02:00" },
  ZM: { "Africa/Maputo": "+02:00" },
  ZW: { "Africa/Maputo": "+02:00" },
};

export const currency_code_iso4217 = {
  AED: {
    code: "AED",
    permissible_decimal_places: 2,
    name: "United Arab Emirates dirham",
  },
  AFN: {
    code: "AFN",
    permissible_decimal_places: 2,
    name: "Afghan afghani",
  },
  ALL: {
    code: "ALL",
    permissible_decimal_places: 2,
    name: "Albanian lek",
  },
  AMD: {
    code: "AMD",
    permissible_decimal_places: 2,
    name: "Armenian dram",
  },
  ANG: {
    code: "ANG",
    permissible_decimal_places: 2,
    name: "Netherlands Antillean guilder",
  },
  AOA: {
    code: "AOA",
    permissible_decimal_places: 2,
    name: "Angolan kwanza",
  },
  ARS: {
    code: "ARS",
    permissible_decimal_places: 2,
    name: "Argentine peso",
  },
  AUD: {
    code: "AUD",
    permissible_decimal_places: 2,
    name: "Australian dollar",
  },
  AWG: {
    code: "AWG",
    permissible_decimal_places: 2,
    name: "Aruban florin",
  },
  AZN: {
    code: "AZN",
    permissible_decimal_places: 2,
    name: "Azerbaijani manat",
  },
  BAM: {
    code: "BAM",
    permissible_decimal_places: 2,
    name: "Bosnia and Herzegovina convertible mark",
  },
  BBD: {
    code: "BBD",
    permissible_decimal_places: 2,
    name: "Barbados dollar",
  },
  BDT: {
    code: "BDT",
    permissible_decimal_places: 2,
    name: "Bangladeshi taka",
  },
  BGN: {
    code: "BGN",
    permissible_decimal_places: 2,
    name: "Bulgarian lev",
  },
  BHD: {
    code: "BHD",
    permissible_decimal_places: 3,
    name: "Bahraini dinar",
  },
  BIF: {
    code: "BIF",
    permissible_decimal_places: 0,
    name: "Burundian franc",
  },
  BMD: {
    code: "BMD",
    permissible_decimal_places: 2,
    name: "Bermudian dollar (customarily known as Bermuda dollar)",
  },
  BND: {
    code: "BND",
    permissible_decimal_places: 2,
    name: "Brunei dollar",
  },
  BOB: {
    code: "BOB",
    permissible_decimal_places: 2,
    name: "Boliviano",
  },
  BOV: {
    code: "BOV",
    permissible_decimal_places: 2,
    name: "Bolivian Mvdol (funds code)",
  },
  BRL: {
    code: "BRL",
    permissible_decimal_places: 2,
    name: "Brazilian real",
  },
  BSD: {
    code: "BSD",
    permissible_decimal_places: 2,
    name: "Bahamian dollar",
  },
  BTN: {
    code: "BTN",
    permissible_decimal_places: 2,
    name: "Bhutanese ngultrum",
  },
  BWP: {
    code: "BWP",
    permissible_decimal_places: 2,
    name: "Botswana pula",
  },
  BYR: {
    code: "BYR",
    permissible_decimal_places: 0,
    name: "Belarusian ruble",
  },
  BZD: {
    code: "BZD",
    permissible_decimal_places: 2,
    name: "Belize dollar",
  },
  CAD: {
    code: "CAD",
    permissible_decimal_places: 2,
    name: "Canadian dollar",
  },
  CDF: {
    code: "CDF",
    permissible_decimal_places: 2,
    name: "Congolese franc",
  },
  CHE: {
    code: "CHE",
    permissible_decimal_places: 2,
    name: "WIR Euro (complementary currency)",
  },
  CHF: {
    code: "CHF",
    permissible_decimal_places: 2,
    name: "Swiss franc",
  },
  CHW: {
    code: "CHW",
    permissible_decimal_places: 2,
    name: "WIR Franc (complementary currency)",
  },
  CLF: {
    code: "CLF",
    permissible_decimal_places: 0,
    name: "Unidad de Fomento (funds code)",
  },
  CLP: {
    code: "CLP",
    permissible_decimal_places: 0,
    name: "Chilean peso",
  },
  CNY: {
    code: "CNY",
    permissible_decimal_places: 2,
    name: "Chinese yuan",
  },
  COP: {
    code: "COP",
    permissible_decimal_places: 2,
    name: "Colombian peso",
  },
  COU: {
    code: "COU",
    permissible_decimal_places: 2,
    name: "Unidad de Valor Real",
  },
  CRC: {
    code: "CRC",
    permissible_decimal_places: 2,
    name: "Costa Rican colon",
  },
  CUC: {
    code: "CUC",
    permissible_decimal_places: 2,
    name: "Cuban convertible peso",
  },
  CUP: {
    code: "CUP",
    permissible_decimal_places: 2,
    name: "Cuban peso",
  },
  CVE: {
    code: "CVE",
    permissible_decimal_places: 0,
    name: "Cape Verde escudo",
  },
  CZK: {
    code: "CZK",
    permissible_decimal_places: 2,
    name: "Czech koruna",
  },
  DJF: {
    code: "DJF",
    permissible_decimal_places: 0,
    name: "Djiboutian franc",
  },
  DKK: {
    code: "DKK",
    permissible_decimal_places: 2,
    name: "Danish krone",
  },
  DOP: {
    code: "DOP",
    permissible_decimal_places: 2,
    name: "Dominican peso",
  },
  DZD: {
    code: "DZD",
    permissible_decimal_places: 2,
    name: "Algerian dinar",
  },
  EGP: {
    code: "EGP",
    permissible_decimal_places: 2,
    name: "Egyptian pound",
  },
  ERN: {
    code: "ERN",
    permissible_decimal_places: 2,
    name: "Eritrean nakfa",
  },
  ETB: {
    code: "ETB",
    permissible_decimal_places: 2,
    name: "Ethiopian birr",
  },
  EUR: {
    code: "EUR",
    permissible_decimal_places: 2,
    name: "Euro",
    number: "978",
  },
  FJD: {
    code: "FJD",
    permissible_decimal_places: 2,
    name: "Fiji dollar",
  },
  FKP: {
    code: "FKP",
    permissible_decimal_places: 2,
    name: "Falkland Islands pound",
  },
  GBP: {
    code: "GBP",
    permissible_decimal_places: 2,
    name: "Pound sterling",
  },
  GEL: {
    code: "GEL",
    permissible_decimal_places: 2,
    name: "Georgian lari",
  },
  GHS: {
    code: "GHS",
    permissible_decimal_places: 2,
    name: "Ghanaian cedi",
  },
  GIP: {
    code: "GIP",
    permissible_decimal_places: 2,
    name: "Gibraltar pound",
  },
  GMD: {
    code: "GMD",
    permissible_decimal_places: 2,
    name: "Gambian dalasi",
  },
  GNF: {
    code: "GNF",
    permissible_decimal_places: 0,
    name: "Guinean franc",
  },
  GTQ: {
    code: "GTQ",
    permissible_decimal_places: 2,
    name: "Guatemalan quetzal",
  },
  GYD: {
    code: "GYD",
    permissible_decimal_places: 2,
    name: "Guyanese dollar",
  },
  HKD: {
    code: "HKD",
    permissible_decimal_places: 2,
    name: "Hong Kong dollar",
  },
  HNL: {
    code: "HNL",
    permissible_decimal_places: 2,
    name: "Honduran lempira",
  },
  HRK: {
    code: "HRK",
    permissible_decimal_places: 2,
    name: "Croatian kuna",
  },
  HTG: {
    code: "HTG",
    permissible_decimal_places: 2,
    name: "Haitian gourde",
  },
  HUF: {
    code: "HUF",
    permissible_decimal_places: 2,
    name: "Hungarian forint",
  },
  IDR: {
    code: "IDR",
    permissible_decimal_places: 2,
    name: "Indonesian rupiah",
  },
  ILS: {
    code: "ILS",
    permissible_decimal_places: 2,
    name: "Israeli new shekel",
  },
  INR: {
    code: "INR",
    permissible_decimal_places: 2,
    name: "Indian rupee",
  },
  IQD: {
    code: "IQD",
    permissible_decimal_places: 3,
    name: "Iraqi dinar",
  },
  IRR: {
    code: "IRR",
    permissible_decimal_places: 0,
    name: "Iranian rial",
  },
  ISK: {
    code: "ISK",
    permissible_decimal_places: 0,
    name: "Icelandic króna",
  },
  JMD: {
    code: "JMD",
    permissible_decimal_places: 2,
    name: "Jamaican dollar",
  },
  JOD: {
    code: "JOD",
    permissible_decimal_places: 3,
    name: "Jordanian dinar",
  },
  JPY: {
    code: "JPY",
    permissible_decimal_places: 0,
    name: "Japanese yen",
  },
  KES: {
    code: "KES",
    permissible_decimal_places: 2,
    name: "Kenyan shilling",
  },
  KGS: {
    code: "KGS",
    permissible_decimal_places: 2,
    name: "Kyrgyzstani som",
  },
  KHR: {
    code: "KHR",
    permissible_decimal_places: 2,
    name: "Cambodian riel",
  },
  KMF: {
    code: "KMF",
    permissible_decimal_places: 0,
    name: "Comoro franc",
  },
  KPW: {
    code: "KPW",
    permissible_decimal_places: 0,
    name: "North Korean won",
  },
  KRW: {
    code: "KRW",
    permissible_decimal_places: 0,
    name: "South Korean won",
  },
  KWD: {
    code: "KWD",
    permissible_decimal_places: 3,
    name: "Kuwaiti dinar",
  },
  KYD: {
    code: "KYD",
    permissible_decimal_places: 2,
    name: "Cayman Islands dollar",
  },
  KZT: {
    code: "KZT",
    permissible_decimal_places: 2,
    name: "Kazakhstani tenge",
  },
  LAK: {
    code: "LAK",
    permissible_decimal_places: 0,
    name: "Lao kip",
  },
  LBP: {
    code: "LBP",
    permissible_decimal_places: 0,
    name: "Lebanese pound",
  },
  LKR: {
    code: "LKR",
    permissible_decimal_places: 2,
    name: "Sri Lankan rupee",
  },
  LRD: {
    code: "LRD",
    permissible_decimal_places: 2,
    name: "Liberian dollar",
  },
  LSL: {
    code: "LSL",
    permissible_decimal_places: 2,
    name: "Lesotho loti",
  },
  LTL: {
    code: "LTL",
    permissible_decimal_places: 2,
    name: "Lithuanian litas",
  },
  LVL: {
    code: "LVL",
    permissible_decimal_places: 2,
    name: "Latvian lats",
  },
  LYD: {
    code: "LYD",
    permissible_decimal_places: 3,
    name: "Libyan dinar",
  },
  MAD: {
    code: "MAD",
    permissible_decimal_places: 2,
    name: "Moroccan dirham",
  },
  MDL: {
    code: "MDL",
    permissible_decimal_places: 2,
    name: "Moldovan leu",
  },
  MGA: {
    code: "MGA",
    permissible_decimal_places: 0,
    name: "Malagasy ariary",
  },
  MKD: {
    code: "MKD",
    permissible_decimal_places: 0,
    name: "Macedonian denar",
  },
  MMK: {
    code: "MMK",
    permissible_decimal_places: 0,
    name: "Myanma kyat",
  },
  MNT: {
    code: "MNT",
    permissible_decimal_places: 2,
    name: "Mongolian tugrik",
  },
  MOP: {
    code: "MOP",
    permissible_decimal_places: 2,
    name: "Macanese pataca",
  },
  MRO: {
    code: "MRO",
    permissible_decimal_places: 0,
    name: "Mauritanian ouguiya",
  },
  MUR: {
    code: "MUR",
    permissible_decimal_places: 2,
    name: "Mauritian rupee",
  },
  MVR: {
    code: "MVR",
    permissible_decimal_places: 2,
    name: "Maldivian rufiyaa",
  },
  MWK: {
    code: "MWK",
    permissible_decimal_places: 2,
    name: "Malawian kwacha",
  },
  MXV: {
    code: "MXV",
    permissible_decimal_places: 2,
    name: "Mexican Unidad de Inversion (UDI) (funds code)",
  },
  MYR: {
    code: "MYR",
    permissible_decimal_places: 2,
    name: "Malaysian ringgit",
  },
  MZN: {
    code: "MZN",
    permissible_decimal_places: 2,
    name: "Mozambican metical",
  },
  NAD: {
    code: "NAD",
    permissible_decimal_places: 2,
    name: "Namibian dollar",
  },
  NGN: {
    code: "NGN",
    permissible_decimal_places: 2,
    name: "Nigerian naira",
  },
  NIO: {
    code: "NIO",
    permissible_decimal_places: 2,
    name: "Nicaraguan córdoba",
  },
  NOK: {
    code: "NOK",
    permissible_decimal_places: 2,
    name: "Norwegian krone",
  },
  NPR: {
    code: "NPR",
    permissible_decimal_places: 2,
    name: "Nepalese rupee",
  },
  NZD: {
    code: "NZD",
    permissible_decimal_places: 2,
    name: "New Zealand dollar",
  },
  OMR: {
    code: "OMR",
    permissible_decimal_places: 3,
    name: "Omani rial",
  },
  PAB: {
    code: "PAB",
    permissible_decimal_places: 2,
    name: "Panamanian balboa",
  },
  PEN: {
    code: "PEN",
    permissible_decimal_places: 2,
    name: "Peruvian nuevo sol",
  },
  PGK: {
    code: "PGK",
    permissible_decimal_places: 2,
    name: "Papua New Guinean kina",
  },
  PHP: {
    code: "PHP",
    permissible_decimal_places: 2,
    name: "Philippine peso",
  },
  PKR: {
    code: "PKR",
    permissible_decimal_places: 2,
    name: "Pakistani rupee",
  },
  PLN: {
    code: "PLN",
    permissible_decimal_places: 2,
    name: "Polish złoty",
  },
  PYG: {
    code: "PYG",
    permissible_decimal_places: 0,
    name: "Paraguayan guaraní",
  },
  QAR: {
    code: "QAR",
    permissible_decimal_places: 2,
    name: "Qatari riyal",
  },
  RON: {
    code: "RON",
    permissible_decimal_places: 2,
    name: "Romanian new leu",
  },
  RSD: {
    code: "RSD",
    permissible_decimal_places: 2,
    name: "Serbian dinar",
  },
  RUB: {
    code: "RUB",
    permissible_decimal_places: 2,
    name: "Russian rouble",
  },
  RWF: {
    code: "RWF",
    permissible_decimal_places: 0,
    name: "Rwandan franc",
  },
  SAR: {
    code: "SAR",
    permissible_decimal_places: 2,
    name: "Saudi riyal",
  },
  SBD: {
    code: "SBD",
    permissible_decimal_places: 2,
    name: "Solomon Islands dollar",
  },
  SCR: {
    code: "SCR",
    permissible_decimal_places: 2,
    name: "Seychelles rupee",
  },
  SDG: {
    code: "SDG",
    permissible_decimal_places: 2,
    name: "Sudanese pound",
  },
  SEK: {
    code: "SEK",
    permissible_decimal_places: 2,
    name: "Swedish krona/kronor",
  },
  SGD: {
    code: "SGD",
    permissible_decimal_places: 2,
    name: "Singapore dollar",
  },
  SHP: {
    code: "SHP",
    permissible_decimal_places: 2,
    name: "Saint Helena pound",
  },
  SLL: {
    code: "SLL",
    permissible_decimal_places: 0,
    name: "Sierra Leonean leone",
  },
  SOS: {
    code: "SOS",
    permissible_decimal_places: 2,
    name: "Somali shilling",
  },
  SRD: {
    code: "SRD",
    permissible_decimal_places: 2,
    name: "Surinamese dollar",
  },
  SSP: {
    code: "SSP",
    permissible_decimal_places: 2,
    name: "South Sudanese pound",
  },
  STD: {
    code: "STD",
    permissible_decimal_places: 0,
    name: "São Tomé and Príncipe dobra",
  },
  SYP: {
    code: "SYP",
    permissible_decimal_places: 2,
    name: "Syrian pound",
  },
  SZL: {
    code: "SZL",
    permissible_decimal_places: 2,
    name: "Swazi lilangeni",
  },
  THB: {
    code: "THB",
    permissible_decimal_places: 2,
    name: "Thai baht",
  },
  TJS: {
    code: "TJS",
    permissible_decimal_places: 2,
    name: "Tajikistani somoni",
  },
  TMT: {
    code: "TMT",
    permissible_decimal_places: 2,
    name: "Turkmenistani manat",
  },
  TND: {
    code: "TND",
    permissible_decimal_places: 3,
    name: "Tunisian dinar",
  },
  TOP: {
    code: "TOP",
    permissible_decimal_places: 2,
    name: "Tongan paʻanga",
  },
  TRY: {
    code: "TRY",
    permissible_decimal_places: 2,
    name: "Turkish lira",
  },
  TTD: {
    code: "TTD",
    permissible_decimal_places: 2,
    name: "Trinidad and Tobago dollar",
  },
  TWD: {
    code: "TWD",
    permissible_decimal_places: 2,
    name: "New Taiwan dollar",
  },
  TZS: {
    code: "TZS",
    permissible_decimal_places: 2,
    name: "Tanzanian shilling",
  },
  UAH: {
    code: "UAH",
    permissible_decimal_places: 2,
    name: "Ukrainian hryvnia",
  },
  UGX: {
    code: "UGX",
    permissible_decimal_places: 2,
    name: "Ugandan shilling",
  },
  USD: {
    code: "USD",
    permissible_decimal_places: 2,
    name: "United States dollar",
  },
  USN: {
    code: "USN",
    permissible_decimal_places: 2,
    name: "United States dollar (next day) (funds code)",
  },
  USS: {
    code: "USS",
    permissible_decimal_places: 2,
    name: "United States dollar (same day) (funds code) (one source[who?] claims it is no longer used, but it is still on the ISO 4217-MA list)",
  },
  UYI: {
    code: "UYI",
    permissible_decimal_places: 0,
    name: "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)",
  },
  UYU: {
    code: "UYU",
    permissible_decimal_places: 2,
    name: "Uruguayan peso",
  },
  UZS: {
    code: "UZS",
    permissible_decimal_places: 2,
    name: "Uzbekistan som",
  },
  VEF: {
    code: "VEF",
    permissible_decimal_places: 2,
    name: "Venezuelan bolívar fuerte",
  },
  VND: {
    code: "VND",
    permissible_decimal_places: 0,
    name: "Vietnamese dong",
  },
  VUV: {
    code: "VUV",
    permissible_decimal_places: 0,
    name: "Vanuatu vatu",
  },
  WST: {
    code: "WST",
    permissible_decimal_places: 2,
    name: "Samoan tala",
  },
  XAF: {
    code: "XAF",
    permissible_decimal_places: 0,
    name: "CFA franc BEAC",
  },
  XAG: {
    code: "XAG",
    permissible_decimal_places: null,
    name: "Silver (one troy ounce)",
  },
  XAU: {
    code: "XAU",
    permissible_decimal_places: null,
    name: "Gold (one troy ounce)",
  },
  XBA: {
    code: "XBA",
    permissible_decimal_places: null,
    name: "European Composite Unit (EURCO) (bond market unit)",
  },
  XBB: {
    code: "XBB",
    permissible_decimal_places: null,
    name: "European Monetary Unit (E.M.U.-6) (bond market unit)",
  },
  XBC: {
    code: "XBC",
    permissible_decimal_places: null,
    name: "European Unit of Account 9 (E.U.A.-9) (bond market unit)",
  },
  XBD: {
    code: "XBD",
    permissible_decimal_places: null,
    name: "European Unit of Account 17 (E.U.A.-17) (bond market unit)",
  },
  XCD: {
    code: "XCD",
    permissible_decimal_places: 2,
    name: "East Caribbean dollar",
  },
  XDR: {
    code: "XDR",
    permissible_decimal_places: null,
    name: "Special drawing rights",
  },
  XFU: {
    code: "XFU",
    permissible_decimal_places: null,
    name: "UIC franc (special settlement currency)",
    number: "Nil",
  },
  XOF: {
    code: "XOF",
    permissible_decimal_places: 0,
    name: "CFA franc BCEAO",
  },
  XPD: {
    code: "XPD",
    permissible_decimal_places: null,
    name: "Palladium (one troy ounce)",
  },
  XPF: {
    code: "XPF",
    permissible_decimal_places: 0,
    name: "CFP franc",
  },
  XPT: {
    code: "XPT",
    permissible_decimal_places: null,
    name: "Platinum (one troy ounce)",
  },
  XTS: {
    code: "XTS",
    permissible_decimal_places: null,
    name: "Code reserved for testing purposes",
  },
  XXX: {
    code: "XXX",
    permissible_decimal_places: null,
    name: "No currency",
  },
  YER: {
    code: "YER",
    permissible_decimal_places: 2,
    name: "Yemeni rial",
  },
  ZAR: {
    code: "ZAR",
    permissible_decimal_places: 2,
    name: "South African rand",
  },
  ZMW: {
    code: "ZMW",
    permissible_decimal_places: 2,
    name: "Zambian kwacha",
  },
};

export const ifn_payin_method = {
  AED: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  AUD: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  BGN: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  BHD: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  CAD: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
    {
      payin_method_id: "pim_c5041adb9af843258f0aa1d05ab5b112",
      payin_method_name: "ca_bank_eft_cad",
      payin_method_description: "LOCAL EFT",
    },
  ],
  CHF: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  CNY: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  CZK: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  DKK: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  EUR: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
    {
      payin_method_id: "pim_934fe422d3294417a93870ea94558d04",
      payin_method_name: "eu_bank_sepa_eur",
      payin_method_description: "SEPA CREDIT TRANSFER (SCT)",
    },
  ],
  GBP: [
    {
      payin_method_id: "pim_e20f500aa5bc430397c469af1112874c",
      payin_method_name: "gb_bank_fps_gbp",
      payin_method_description: "UK DOMESTIC (FPS)",
    },
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  HKD: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  HRK: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  HUF: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  ILS: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  JPY: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  KES: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  KWD: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  NOK: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  NZD: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  OMR: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  PLN: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  QAR: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  RON: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  SAR: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  SEK: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  SGD: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  THB: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  TRY: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  UGX: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
  USD: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
    {
      payin_method_id: "pim_7fbfab29d3424e6a98e138f46ae89975",
      payin_method_name: "us_bank_fedwire_usd",
      payin_method_description: "FED WIRE",
    },
    {
      payin_method_id: "pim_f34b75ac562a4ef1b4898f0b69e6694a",
      payin_method_name: "us_bank_ach_usd",
      payin_method_description: "FED ACH",
    },
  ],
  ZAR: [
    {
      payin_method_id: "pim_f138eb02afc442b89fe24062941bd1b5",
      payin_method_name: "global_bank_swift",
      payin_method_description: "INTERNATIONAL WIRE(SWIFT) TRANSFER",
    },
  ],
};
