import { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import SidebarLayout from "src/layouts";
import SuspenseLoader from "src/components/SuspenseLoader";
import Guest from "src/components/Guest";
import Authenticated from "src/components/Authenticated";

export const renderRoutes = (routes) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    path: "/verify",
    guard: Authenticated,
    layout: SidebarLayout,
    routes: [
      {
        exact: true,
        route_id: "read_only",
        path: "/verify/mobile-otp",
        component: lazy(() => import("src/dashboards/Auth/MobileVerify")),
      },
    ],
  },
  // preview
  {
    path: "/preview",
    guard: Authenticated,
    layout: SidebarLayout,
    routes: [
      {
        exact: true,
        path: "/preview/dashboards/analytics",
        component: lazy(() => import("src/dashboards/Home")),
      },
      {
        exact: true,
        path: "/preview/dashboards/profile",
        component: lazy(() => import("src/dashboards/Profile")),
      },
      {
        exact: true,
        route_id: "read_only",
        path: "/preview/account/onboarding",
        component: lazy(() => import("src/dashboards/KYBOnboarding")),
      },
      {
        exact: true,
        route_id: "read_only",
        path: "/preview/account/onboarding/merchant/:merchant_id",
        component: lazy(() => import("src/dashboards/KYBOnboarding")),
      },
      {
        exact: true,
        path: "/preview/dashboards/organization",
        component: lazy(() => import("src/dashboards/Organizations/index")),
      },
      {
        exact: true,
        path: "/preview/dashboards/merchant-console",
        component: lazy(() => import("src/dashboards/MerchantConsole")),
      },
      {
        exact: true,
        path: "/preview/dashboards/merchant-console/:orgId",
        component: lazy(() =>
          import("src/dashboards/MerchantConsole/MerchantDetails")
        ),
      },

      {
        exact: true,
        path: "/preview/dashboards/organization/:orgId",
        component: lazy(() =>
          import("src/dashboards/Organizations/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/invite-merchant",
        component: lazy(() => import("src/dashboards/Organizations/InviteOrg")),
      },
      {
        exact: true,
        path: "/preview/dashboards/user/orgs",
        component: lazy(() => import("src/dashboards/OrgUsers")),
      },
      // {
      //   exact: true,
      //   path: "/preview/dashboards/user/impersonate",
      //   component: lazy(() =>
      //     import("src/content/dashboards/OrgUsers/ImpersonateUser")
      //   ),
      // },
      {
        exact: true,
        path: "/preview/dashboards/user/org-specific-users/:org_id",
        component: lazy(() => import("src/dashboards/OrgUsers/orgUsers")),
      },
      {
        exact: true,
        path: "/preview/dashboards/user/:org_id/:org_user_id",
        component: lazy(() => import("src/dashboards/OrgUsers/MoreDetails")),
      },
      {
        exact: true,
        path: "/preview/dashboards/transactions",
        component: lazy(() => import("src/dashboards/MerchantTransactions")),
      },
      {
        exact: true,
        path: "/preview/dashboards/transactions/:org_id/:transaction_id",
        component: lazy(() =>
          import("src/dashboards/MerchantTransactions/MoreDetails")
        ),
      },
      // {
      //   exact: true,
      //   path: "/preview/dashboards/transactions/org-specific-transactions/:org_id",
      //   component: lazy(() => import("src/content/dashboards/Transactions")),
      // },
      {
        exact: true,
        path: "/preview/dashboards/report/:report_id",
        component: lazy(() =>
          import("src/dashboards/MerchantTransactions/Report/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/plan",
        component: lazy(() => import("src/dashboards/Pricings/Plans")),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/plan/create",
        component: lazy(() => import("src/dashboards/Pricings")),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/plan/:plan_id",
        component: lazy(() => import("src/dashboards/Pricings")),
      },
      {
        exact: true,
        path: "/preview/dashboards/setting",
        component: lazy(() => import("src/dashboards/Settings")),
      },
      {
        exact: true,
        path: "/preview/dashboards/setting/logo",
        component: lazy(() => import("src/dashboards/Settings/Logo/LogoUrl")),
      },
      {
        exact: true,
        path: "/preview/dashboards/setting/settlement-config",
        component: lazy(() =>
          import("src/dashboards/Settings/SettlementConfig")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/users",
        component: lazy(() => import("src/dashboards/Users")),
      },
      {
        exact: true,
        path: "/preview/dashboards/users/add",
        component: lazy(() => import("src/dashboards/Users/AddUser")),
      },
    ],
  },

  {
    exact: true,
    path: "/",
    component: () => <Redirect to="/preview/dashboards/analytics" />,
  },
  // login
  {
    exact: true,
    guard: Guest,
    path: "/login",
    component: lazy(() => import("src/dashboards/Auth/Login/Cover")),
  },
  {
    exact: true,
    guard: Guest,
    path: "/register",
    component: lazy(() => import("src/dashboards/Auth/Register/Cover/index")),
  },
  {
    exact: true,
    path: "/new-partner-invitation",
    component: lazy(() => import("src/dashboards/Auth/VerifyInvitation")),
  },

  {
    exact: true,
    path: "/recover-password",
    component: lazy(() => import("src/dashboards/Auth/RecoverPassword")),
  },
  {
    exact: true,
    path: "/reset-password",
    component: lazy(() =>
      import("src/dashboards/Auth/OrganizationUserResetPassword")
    ),
  },
  {
    exact: true,
    path: "/status/404",
    component: lazy(() => import("src/dashboards/Status/Status404")),
  },
  {
    exact: true,
    path: "/status/500",
    component: lazy(() => import("src/dashboards/Status/Status500")),
  },
  {
    exact: true,
    path: "/status/coming-soon",
    component: lazy(() => import("src/dashboards/Status/ComingSoon")),
  },
  {
    exact: true,
    path: "/status/maintenance",
    component: lazy(() => import("src/dashboards/Status/Maintenance")),
  },
  {
    exact: true,
    path: "*",
    component: lazy(() => import("src/dashboards/Status/Status404")),
  },
  // {
  //   path: '*',
  //   layout: BaseLayout,
  //   routes: [
  //     // {
  //     //   exact: true,
  //     //   path: '/',
  //     //   component: Overview
  //     // },
  //     {
  //       component: () => <Redirect to="/" />
  //     }
  //   ]
  // }
];

export default routes;
