import PropTypes from "prop-types";
import { experimentalStyled } from "@mui/material/styles";

const LabelWrapper = experimentalStyled("span")(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(10)};
      border-radius: ${theme.general.borderRadius};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
      text-transform : capitalize;
      
      &.MuiLabel {
        &-primary {
          background-color: ${theme.colors.primary.lighter};
          color: ${theme.palette.primary.main}
        }
        
        &-secondary {
          background-color: ${theme.colors.secondary.lighter};
          color: ${theme.palette.secondary.main}
        }
        
        &-success {
          background-color: ${theme.colors.success.lighter};
          color: ${theme.palette.success.main}
        }
        
        &-warning {
          background-color: ${theme.colors.warning.lighter};
          color: ${theme.palette.warning.main}
        }
              
        &-error {
          background-color: ${theme.colors.error.lighter};
          color: ${theme.palette.error.main}
        }
        
        &-info {
          background-color: ${theme.colors.info.lighter};
          color: ${theme.palette.info.main}
        }
      }
`
);

const checkStatus = (text) => {
  switch (true) {
    case text.startsWith("2"): {
      return "success";
    }
    case text.startsWith("4"): {
      return "warning";
    }
    case text.startsWith("5"): {
      return "error";
    }
  }

  switch (text) {
    case "Refund_Active":
    case "POST":
    case "SIGNED": {
      return "success";
    }
    case "Refund_Closed": {
      return "primary";
    }
    case "true":
    case "TRUE": {
      return "success";
    }
    case "false":
    case "FALSE":
    case "DELETE":
    case "UNSIGNED": {
      return "error";
    }
    case "CUST_SUBMITTED": {
      return "primary";
    }
    case "PUT":
    case "PATCH":
    case "NOT_STARTED": {
      return "warning";
    }
    case "ACTIVATED": {
      return "primary";
    }
    case "GET":
    case "REQUESTED": {
      return "info";
    }
    case "DEACTIVATED": {
      return "error";
    }
    case "REJECTED": {
      return "error";
    }
    case "APPROVED": {
      return "success";
    }
    case "TO_BE_RESUBMITTED": {
      return "info";
    }
    case "COMPLETED": {
      return "success";
    }
    case "INITIATED": {
      return "primary";
    }
    case "PENDING": {
      return "warning";
    }
    case "CREATED": {
      return "primary";
    }
    case "ACTIVE": {
      return "success";
    }
    case "DELETED": {
      return "error";
    }
    case "PAID": {
      return "success";
    }
    case "CANCELLED": {
      return "warning";
    }

    default: {
      return "secondary";
    }
  }
};

const Label = ({
  className = "",
  color = "secondary",
  children,
  text = null,
  ...rest
}) => {
  return (
    <LabelWrapper
      className={`MuiLabel-${text !== null ? checkStatus(text.trim()) : color}`}
      {...rest}
    >
      {children || text}
    </LabelWrapper>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "warning",
    "success",
    "info",
  ]),
};

export default Label;
