import { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import SuspenseLoader from "src/components/SuspenseLoader";
import axios from "axios";
import { BASE_API_URL, WEB_GTWY_API_URL } from "src/config";
import { apiErrorMessage, toastNotification } from "src/utils/helper";
import {
  getKybOrgDetails,
  getOrgAccDetails,
  logoutSession,
  fetchAllOrganization,
} from "src/utils/NetworkUtils";
import { useHistory } from "react-router";
import { useQuery } from "src/hooks/querys";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  custom_data: null,
  otp_login_user: null,
  authData: null,
  partner_data: null,
};

const isValidToken = (access_token) => {
  if (!access_token) {
    return false;
  }

  const decoded = jwtDecode(access_token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (access_token) => {
  if (access_token) {
    localStorage.setItem("access_token", access_token);
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  } else {
    localStorage.removeItem("access_token");
    delete axios.defaults.headers.common.Authorization;
  }
};

const getAllOrgCustomData = async () => {
  let custom_data = {};
  const res = await fetchAllOrganization([
    { filter_key: "limit", filter_value: 1000 },
    {
      filter_key: "organization_fields",
      filter_value: "org_id,name,created_at",
    },
  ]);

  if (res.success) {
    let all_org_name_and_id = {};
    res.data.forEach((org) => {
      all_org_name_and_id[org.organization.org_id] = org.organization.name;
    });

    custom_data = {
      all_orgs: res,
      all_org_name_and_id: all_org_name_and_id,
    };
  }
  return custom_data;
};

const setSessionNew = (key, value) => {
  if (value) {
    localStorage.setItem(key, value);
    if (key == "access_token") {
      axios.defaults.headers.common.Authorization = `Bearer ${value}`;
    }
  } else {
    localStorage.removeItem(key);
    if (key == "access_token") {
      delete axios.defaults.headers.common.Authorization;
    }
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user, partner_data, custom_data } =
        action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user: { ...user, is_read_only: !user?.is_admin },
        partner_data,
        custom_data,
      };
    }
    case "LOGIN": {
      const { user, authData, partner_data, custom_data } = action.payload;
      const { is_bo_admin_login } = authData || {};
      if (is_bo_admin_login) {
        setSessionNew("is_bo_admin_login", is_bo_admin_login);
      } else {
        localStorage.removeItem("is_bo_admin_login");
      }

      return {
        ...state,
        isAuthenticated: true,
        otp_login_user: null,
        authData: authData || {},
        user: { ...user, is_read_only: !user?.is_admin },
        partner_data,
        custom_data,
      };
    }
    case "OTP_LOGIN": {
      const { otp_login_user } = action.payload;
      return {
        ...state,
        otp_login_user,
      };
    }
    case "VERIFY_EMAIL_LOGIN": {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    case "LOGIN_FAILED": {
      const { message, user } = action.payload;

      return {
        ...state,
        login_err: message,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        otp_login_user: null,
        authData: null,
        partner_data: null,
        custom_data: null,
      };
    }
    case "REGISTER": {
      const { message } = action.payload;

      return {
        ...state,
        register_suc: message,
      };
    }
    case "REGISTER_FAILED": {
      const { message } = action.payload;

      return {
        ...state,
        register_err: message,
      };
    }
    case "RESET": {
      return {
        ...state,
        register_err: null,
        login_err: null,
        register_suc: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  otp_login: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  bo_login: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const otp_login = async (email, password, recaptcha_token) => {
    try {
      const response = await axios.post(`${WEB_GTWY_API_URL}/login`, {
        email,
        password,
        "g-recaptcha-response": recaptcha_token,
      });
      const { success, data } = response.data;
      if (success) {
        if (data.is_email_verified === false) {
          dispatch({
            type: "VERIFY_EMAIL_LOGIN",
            payload: {
              user: { ...data },
            },
          });
          history.push("/email-verification");
        } else {
          setSessionNew("access_token", data.access_token);
          setSessionNew(
            "password_reset_required",
            data.password_reset_required
          );
          dispatch({
            type: "OTP_LOGIN",
            payload: {
              otp_login_user: { ...data },
            },
          });
          toastNotification("success", "User Logged In Successfully", 1500);
        }
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 2000);
    }
  };

  const login = async (sessionId, otp) => {
    try {
      const response = await axios.post(
        `${WEB_GTWY_API_URL}/verifyLoginOtp/${sessionId}/${otp}`
      );
      const { success, data } = response.data;
      if (success) {
        setSessionNew("access_token", data.access_token);
        setSessionNew("session_id", data.session_id);
        const orgData = await getOrgAccDetails();
        if (orgData.success) {
          toastNotification("success", "User Logged In Successfully", 1500);
          try {
            const res = await getKybOrgDetails([], orgData.data.org_id);
            const { partner, organization, kyb } = res.data;
            if (res.success) {
              const custom_data = await getAllOrgCustomData();
              dispatch({
                type: "LOGIN",
                payload: {
                  user: { ...orgData.data },
                  authData: { ...data },
                  partner_data: {
                    partner: partner,
                    associated_org: organization,
                    kyb_status: kyb.status,
                    partner_level: partner?.partner_level || "L1",
                    error: null,
                  },
                  custom_data: custom_data,
                },
              });
            }
          } catch (err) {
            dispatch({
              type: "LOGIN",
              payload: {
                user: { ...orgData.data },
                authData: { ...data },
                partner_data: {
                  partner: null,
                  associated_org: null,
                  kyb_status: null,
                  partner_level: "L1",
                  error: err,
                },
                custom_data: { all_orgs_error: err },
              },
            });
          }
        }
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 2000);
    }
  };

  const bo_login = async (bo_token) => {
    try {
      const response = await axios.get(
        `${WEB_GTWY_API_URL}/partner-user/bo-admin-login?bo_admin_login_token=${bo_token}`
      );
      const { success, data } = response.data;
      if (success) {
        setSessionNew("access_token", data.access_token);
        setSessionNew("session_id", data.session_id);

        const orgData = await getOrgAccDetails();
        if (orgData.success) {
          try {
            const res = await getKybOrgDetails([], orgData.data.org_id);
            const { partner, organization, kyb } = res.data;
            if (res.success) {
              const custom_data = await getAllOrgCustomData();
              dispatch({
                type: "LOGIN",
                payload: {
                  user: { ...orgData.data, is_admin: true },
                  authData: { ...data },
                  partner_data: {
                    partner: partner,
                    associated_org: organization,
                    kyb_status: kyb.status,
                    partner_level: partner.partner_level || "L1",
                    error: null,
                  },
                  custom_data: custom_data,
                },
              });
            }
          } catch (err) {
            dispatch({
              type: "LOGIN",
              payload: {
                user: { ...orgData.data },
                authData: { ...data },
                partner_data: {
                  partner: null,
                  associated_org: null,
                  kyb_status: null,
                  partner_level: "L1",
                  error: err,
                },
                custom_data: { all_orgs_error: err },
              },
            });
          }
        }
        toastNotification("success", "BO User login successful", 1500);
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 2000);
    }
  };

  const logout = async () => {
    const session_id = localStorage.getItem("session_id");
    try {
      const { success } = await logoutSession(session_id);
      if (success) {
        setSession(null);
        localStorage.clear();
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      console.error(error);
      setSession(null);
      dispatch({ type: "LOGOUT" });
    }
  };

  const { push } = useHistory();

  const register = async (
    first_name,
    last_name,
    email,
    org_name,
    org_country,
    password,
    phone_number,
    phone_country_code,
    recaptcha_token
  ) => {
    try {
      const response = await axios.post(`${BASE_API_URL}/register`, {
        first_name,
        last_name,
        email,
        org_name,
        org_country,
        password,
        phone_number,
        phone_country_code,
        is_partner_privelege_enabled: true,
      });
      const { success, data } = response.data;
      if (success) {
        toastNotification("success", "User Registered Successfully", 1500);
        setSessionNew("access_token", data.access_token);
        return success;
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 2000);
    }
  };

  const initialise = async () => {
    try {
      const access_token = window.localStorage.getItem("access_token");

      if (access_token) {
        const orgData = await getOrgAccDetails();
        if (orgData.success) {
          try {
            const is_bo_login =
              window.localStorage.getItem("is_bo_admin_login");
            if (is_bo_login) {
              orgData.data = { ...orgData.data, is_admin: true };
            }
            const res = await getKybOrgDetails([], orgData.data.org_id);
            const { partner, organization, kyb } = res.data;
            if (res.success) {
              const custom_data = await getAllOrgCustomData();
              dispatch({
                type: "INITIALISE",
                payload: {
                  isAuthenticated: true,
                  user: { ...orgData.data },
                  partner_data: {
                    partner: partner,
                    associated_org: organization,
                    kyb_status: kyb.status,
                    partner_level: partner.partner_level || "L1",
                    error: null,
                  },
                  custom_data: custom_data,
                },
              });
            }
          } catch (err) {
            dispatch({
              type: "INITIALISE",
              payload: {
                isAuthenticated: true,
                user: { ...orgData.data },
                partner_data: {
                  partner: null,
                  associated_org: null,
                  kyb_status: null,
                  partner_level: "L1",
                  error: err,
                },
                custom_data: { all_orgs_error: err },
              },
            });
          }
        }
      } else {
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
            partner_data: null,
            custom_data: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALISE",
        payload: {
          isAuthenticated: false,
          user: null,
          partner_data: null,
          custom_data: null,
        },
      });
    }
  };

  const queryParam = useQuery();
  const bo_token = queryParam.get("bo_admin_login_token");

  useEffect(() => {
    if (bo_token) {
      localStorage.clear();
      dispatch(new Event("storage"));
    }
    (async () => {
      await initialise();
    })();
  }, []);

  if (!state.isInitialised) {
    return <SuspenseLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        otp_login,
        login,
        logout,
        initialise,
        register,
        dispatch,
        bo_login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
