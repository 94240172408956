import { combineReducers } from '@reduxjs/toolkit';
import { clientUserReducer } from './reducers/clientUserReducer';
import { orgReducer } from './reducers/orgReducer';
import { kybReducer } from './reducers/kybReducer';
import { webhookReducer } from './reducers/webhookReducer';
import { walletTansactionReducer } from './reducers/walletTransactionReducer';
import { accountSummaryReducer } from './reducers/accountSummaryReducer';
import { payinReducer } from './reducers/payinReducer';
import { newConnectorReducer } from './reducers/connectorReducer';

const rootReducer = combineReducers({
  clientUsers: clientUserReducer,
  org: orgReducer,
  payin: payinReducer,
  kyb: kybReducer,
  webhook: webhookReducer,
  walletTransaction: walletTansactionReducer,
  accountSummary: accountSummaryReducer,
  connector: newConnectorReducer,
  
});

export default rootReducer;
