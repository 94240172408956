import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import routes, { renderRoutes } from "./router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ScrollToTop from "./utils/ScrollToTop";
import "./App.css";
import { AuthProvider } from "./contexts/JWTAuthContext";
import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline } from "@mui/material";

const history = createBrowserHistory();

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router history={history}>
          <ScrollToTop />
          <CssBaseline />
          <AuthProvider>{renderRoutes(routes)}</AuthProvider>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
