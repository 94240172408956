import axios from "axios";
import { WEB_GTWY_API_URL } from "src/config";
import { errorMessage } from "src/utils/apiCalls";

export const getRequestHeaders = async () => {
  const header = { "Content-Type": "application/json" };
  const access_token = window.localStorage.getItem("access_token");
  if (access_token) {
    header["Authorization"] = `Bearer ${access_token}`;
  }
  return header;
};

export const getFilterQueryUrl = (filterQueryArr) =>
  filterQueryArr.reduce((prevVal, currArrVal, currIdx, arr) => {
    const { filter_key, filter_value } = currArrVal;
    if (!(filter_key !== "" && filter_value !== "")) {
      return "";
    }
    if (currIdx === arr.length - 1) {
      return `${prevVal}${filter_key}=${filter_value}`;
    } else {
      return prevVal + `${filter_key}=${filter_value}&`;
    }
  }, "");

const createRequest = async () => {
  const request = {};

  return request;
};

export const getOrgAccDetails = async () => {
  const url = `${WEB_GTWY_API_URL}/partner-user`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchAllOrganization = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const forgotPasswordEmail = async (email, recaptcha_token) => {
  const url = `${WEB_GTWY_API_URL}/partner-user/reset-password`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.email = email;
  requestData["g-recaptcha-response"] = recaptcha_token;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const setTotpSecret = async (userData, with_log = true) => {
  const url = `${WEB_GTWY_API_URL}/set-totp-secret`;
  const requestHeaders = await getRequestHeaders({ with_log });
  let requestData = await createRequest();
  requestData = { ...requestData, ...userData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateTotpStatus = async (userData, with_log = true) => {
  const url = `${WEB_GTWY_API_URL}/update-totp-status`;
  const requestHeaders = await getRequestHeaders({ with_log });
  let requestData = await createRequest();
  requestData = { ...requestData, ...userData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const sendOtpOnPhoneEmail = async (requestObject) => {
  const url = `${WEB_GTWY_API_URL}/sendOtpOnPhoneEmail`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  const requestData = await createRequest();
  requestData.auth_id = requestObject.auth_id;
  requestData.session_id = requestObject.session_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updatePasswordWithCode = async (reset_code, new_password) => {
  const url = `${WEB_GTWY_API_URL}/partner-user/reset-password`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.password_reset_code = reset_code;
  requestData.new_password = new_password;

  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const verifyPasswordResetCode = async (reset_code) => {
  const url = `${WEB_GTWY_API_URL}/partner-user/reset-password/${reset_code}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const verifyInviteToken = async (token) => {
  const url = `${WEB_GTWY_API_URL}/partner/validate-invitation-token/${token}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resetInvitePassword = async (token, password) => {
  const url = `${WEB_GTWY_API_URL}/partner/reset-invitation-password`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData.invitation_token = token;
  requestData.password = password;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const logoutSession = async (session_id) => {
  const url = `${WEB_GTWY_API_URL}/logout/${session_id}`;
  const response = await axios.delete(url);
  return response.data;
};

export const addAccount = async ({
  email,
  first_name,
  last_name,
  password,
  phone_number,
  is_admin,
  password_reset_required,
}) => {
  const url = `${WEB_GTWY_API_URL}/partner-user/add`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.email = `${email}@finmo.net`;
  requestData.first_name = first_name;
  requestData.last_name = last_name;
  requestData.password = password;
  requestData.phone_number = phone_number;
  requestData.is_admin = is_admin;
  requestData.password_reset_required = password_reset_required;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getOrgSpecificUsers = async (org_id, filterQueryArr = []) => {
  let URL = `${WEB_GTWY_API_URL}/partner/organization/${org_id}/user`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      URL = `${URL}?${filterUrl}`;
    }
  }

  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.get(URL, { headers: requestHeaders });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const loginUser = async ({ org_id, org_user_id }) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/partner-admin-login`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.org_id = org_id;
  requestData.org_user_id = org_user_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resendLoginOtp = async (auth_id, session_id) => {
  const url = `${WEB_GTWY_API_URL}/resend-login-otp`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.auth_id = auth_id;
  requestData.session_id = session_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const verifyEmailToken = async (email_token) => {
  const url = `${WEB_GTWY_API_URL}/partner-user/verify-email/${email_token}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resendVerifyEmail = async (email) => {
  const url = `${WEB_GTWY_API_URL}/partner-user/resend-verification-email/${email}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const inviteOrgUser = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/invite-organization`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.email = obj.email;
  requestData.first_name = obj.first_name;
  requestData.last_name = obj.last_name;
  requestData.org_name = obj.org_name;
  requestData.org_country = obj.org_country;
  requestData.is_kyb_permission_by_merchant = obj.is_kyb_permission_by_merchant;
  requestData.gca_pricing_plan_id = obj.merchant_plan_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const resendInvitation = async (org_id) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/resend-invitation-email/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllAccounts = async (filterQuerryArr = [], org_id) => {
  let url;
  if (org_id) url = `${WEB_GTWY_API_URL}/partner-user/peers/${org_id}`;
  else url = `${WEB_GTWY_API_URL}/partner-user/peers`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllPartnerUsers = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/partner-user`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSingleOrganizationDoc = async (org_id, org_doc_id) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/organization-document/${org_doc_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-merchant-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const completeGcaActivation = async (org_id, gcaData) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization/${org_id}/gca/complete-activation`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, gcaData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getPartnerSetting = async (key, filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/partner-setting/${key}`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const createOrUpdatePartnerSetting = async (data) => {
  const url = `${WEB_GTWY_API_URL}/partner-setting`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData = { ...requestData, ...data };

  const response = await axios.put(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchPartnerDocUrl = async (org_document_id) => {
  const url = `${WEB_GTWY_API_URL}/partner-document/${org_document_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const uploadDocs = async (file, doc_descriptor, file_type) => {
  const url = `${WEB_GTWY_API_URL}/partner-document`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.file_name = file.name;
  requestData.file_type = file_type ? file_type : file.type;
  requestData.descriptor = doc_descriptor;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  const uploadUrl = response.data.data.upload_url;
  const partner_document_id = response.data.data.partner_document_id;
  delete axios.defaults.headers.common.Authorization;
  const uploadResponse = await axios.put(uploadUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
    redirect: "follow",
  });
  return { status: uploadResponse.status === 200, partner_document_id };
};
// ----------------------------------------------------------------------------------------------

export const getAllRecons = async (limit, page) => {
  let url = `${WEB_GTWY_API_URL}/torc/reconciliation/batch`;
  if (limit && page) {
    url = `${WEB_GTWY_API_URL}/torc/reconciliation/batch?limit=${limit}&page=${page}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getReconBatchById = async (recon_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/reconciliation/batch/${recon_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllReconTrxn = async (batch_id, limit, page) => {
  let url = `${WEB_GTWY_API_URL}/torc/reconciliation?reconciliation_batch_id=${batch_id}`;
  if (limit && page) {
    url = `${WEB_GTWY_API_URL}/torc/reconciliation?reconciliation_batch_id=${batch_id}&limit=${limit}&page=${page}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllSettlements = async (filterQueryArr = [], org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSettlement = async (settlement_id, org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement/${settlement_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

//settlement-bank

export const getSettlementBank = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization/settlement-bank`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getSettlementBankById = async (settlement_bank_id) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization/settlement-bank/${settlement_bank_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const validateSettlementBankWithId = async (
  payout_method_name,
  org_settlement_bank_id
) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/settlement-bank/${org_settlement_bank_id}/payout-method/${payout_method_name}/validate-settlement-bank`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const validateSettlementBankWithData = async (
  payout_method_name,
  data
) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/settlement-bank/payout-method/${payout_method_name}/validate-settlement-bank`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData = { ...data, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateSettlementBank = async (
  org_settlement_bank_id,
  updated_data
) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization/settlement-bank/${org_settlement_bank_id}`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData = { ...requestData, ...updated_data };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createSettlementBank = async (bankData) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/settlement-bank`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, bankData, {
    headers: requestHeaders,
  });
  return response.data;
};

//settlement-org-config

export const createSettlementConfig = async (data) => {
  const {
    currency,
    settlement_payout_mode,
    min_amount_threshold,
    org_settlement_bank_id,
    is_settlement_enabled,
    settlement_schedule,
    settlement_payout_reference,
  } = data;
  const URL = `${WEB_GTWY_API_URL}/partner/organization/settlement-config`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.currency = currency;
  requestData.settlement_payout_mode = settlement_payout_mode;
  requestData.settlement_schedule = settlement_schedule;
  requestData.min_amount_threshold = min_amount_threshold;
  requestData.is_settlement_enabled = is_settlement_enabled;
  requestData.org_settlement_bank_id = org_settlement_bank_id || null;
  requestData.settlement_payout_reference = settlement_payout_reference || null;
  const response = await axios.post(URL, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateSettlementConfig = async (
  org_settlement_config_id,
  data
) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/settlement-config/${org_settlement_config_id}`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();

  requestData.is_settlement_enabled = data.is_settlement_enabled;
  requestData.settlement_payout_mode = data.settlement_payout_mode;
  requestData.settlement_payout_reference =
    data.settlement_payout_reference || null;
  requestData.org_settlement_bank_id = data.org_settlement_bank_id || null;
  requestData.min_amount_threshold = +data.min_amount_threshold;

  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSettlementConfig = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization/settlement-config`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const releaseAllSettlementPayout = async (
  org_id,
  env,
  currency,
  date,
  rate = null,
  settlement_id = null
) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement/release-settlement-payout`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = env;
  const requestData = await createRequest();
  requestData["org_id"] = org_id;
  requestData["currency"] = currency;
  requestData["settlement_payout_till_date"] =
    date || new Date(Date.now()).toISOString();
  if (!!settlement_id) {
    requestData["settlement_id"] = settlement_id;
  }
  if (!!rate) {
    requestData["fx_rate"] = +rate;
  }
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const ReleaseSettlementPayout = async (
  org_id,
  settlement_id,
  rate,
  currency
) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement/release-settlement-payout`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = "production";
  const requestData = await createRequest();

  requestData["settlement_id"] = settlement_id;
  requestData["currency"] = currency;

  if (!!rate) {
    requestData["fx_rate"] = +rate;
  }

  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllSettlementTrxn = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet-transaction/partner`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const fetchWalletTransactionById = async (transaction_id, org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet-transaction/${transaction_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getKybOrgDetails = async (filterQueryArr = [], merchant_id) => {
  let url = `${WEB_GTWY_API_URL}/partner/kyb`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const settlementEnabled = async (org_id, enabled) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.organization = { is_settlement_allowed: enabled };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const updateSettlementType = async (org_id, type) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.organization = { settlement_type: type };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateSettleToPayoutWallet = async (org_id, val) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.organization = { is_settle_to_payout_wallet: val };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const runSettlementForOrg = async (org_id, env, currency, date) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement/settle/org/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const requestData = await createRequest();
  requestData["currency"] = currency;
  requestData["settlement_calculate_till_date"] =
    date || new Date(Date.now()).toISOString();
  requestData["org_id"] = org_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSettlementPayout = async (filterQueryArr = [], org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement-payout`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSettlementPayoutDetails = async () => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement-payout/settlement-summary`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchSettlePayoutById = async (payoutId, org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement-payout/${payoutId}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllEntity = async (filterQueryArr = [], entity) => {
  let url = `${WEB_GTWY_API_URL}/partner/torc/${entity}`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchEntityByEntityID = async (entity_id, org_id, entity) => {
  let url = `${WEB_GTWY_API_URL}/partner/torc/${entity}/${entity_id}`;
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-merchant-id"] = org_id;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchAllEntityByOrgId = async (
  filterQueryArr = [],
  org_id,
  entity
) => {
  let url = `${WEB_GTWY_API_URL}/partner/torc/${entity}/org/all`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const updateOrgControl = async (org_id, env, key_type, key, value) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization-control`;
  const requestData = await createRequest();
  requestData.key_type = key_type;
  requestData.key = key;
  requestData.value = value;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = env;
  const response = await axios.put(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getOrgControl = async (filterQuerryArr = [], org_id, env) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization-control`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getOrgControlByKey = async (org_id, env, key_type, key) => {
  let url = `${WEB_GTWY_API_URL}/partner/organization-control`;
  if (key_type && key) {
    url = `${url}/${key_type}/${key}`;
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllAuditTrail = async (filterQueryArr = [], org_id) => {
  let url = `${WEB_GTWY_API_URL}/audit-trail`;
  if (filterQueryArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAuditTrailById = async (org_id, audit_id) => {
  const url = `${WEB_GTWY_API_URL}/audit-trail/${audit_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = "production";
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

//Compliance Service Routes

export const getComplianceControls = async (filterQueryArr = [], org_id) => {
  let url = `${WEB_GTWY_API_URL}/compliance-control`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (!!org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const updateComplianceControl = async (compliance_control_id, data) => {
  let url = `${WEB_GTWY_API_URL}/compliance-control/${compliance_control_id}`;
  const requestHeaders = await getRequestHeaders();
  let requesData = await createRequest();
  requesData = { ...requesData, ...data };
  const response = await axios.patch(url, requesData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createComplianceSearch = async (org_id, data = {}) => {
  const url = `${WEB_GTWY_API_URL}/compliance`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  let requestData = await createRequest();
  requestData = { ...requestData, ...data };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchComplianceSearch = async (org_id, filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/compliance`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const sendMobileOtp = async (
  phone_country_code,
  phone_number,
  org_user_id,
  partner_id
) => {
  const url = `${WEB_GTWY_API_URL}/partner-user/send-phonenumber-verification-otp`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-ptnr-user-id"] = org_user_id;
  requestHeaders["x-ptnr-id"] = partner_id;
  const requestData = await createRequest();
  requestData.phone_country_code = phone_country_code;
  requestData.phone_number = phone_number;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const verifyMobileOtp = async (otp, org_user_id, partner_id) => {
  const url = `${WEB_GTWY_API_URL}/partner-user/verify-phonenumber-verification-otp`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-ptnr-user-id"] = org_user_id;
  requestHeaders["x-ptnr-id"] = partner_id;
  const requestData = await createRequest();
  requestData.otp = otp;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchComplianceSearchBySearchId = async (search_id) => {
  let url = `${WEB_GTWY_API_URL}/compliance/search/${search_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const createKybOrgDetails = async (kybData, merchant_id) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/kyb/submit`;
  const requestHeaders = await getRequestHeaders();
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.patch(url, kybData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createOrganizationSetting = async (
  mode,
  data,
  with_log = false
) => {
  const url = `${WEB_GTWY_API_URL}/partner-setting`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData = { ...requestData, ...data };

  const response = await axios.put(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPayoutMethod = async (
  filterQueryArr = [
    {
      filter_key: "sender_country",
      filter_value: "AU",
    },
    {
      filter_key: "sender_currency",
      filter_value: "AUD",
    },
    {
      filter_key: "beneficiary_country",
      filter_value: "AU",
    },
    {
      filter_key: "beneficiary_currency",
      filter_value: "AUD",
    },
  ],
  merchant_id
) => {
  let url = `${WEB_GTWY_API_URL}/partner/torc/payout-method/all`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const getPayoutMethodParamsByName = async (payout_method_name) => {
  const url = `${WEB_GTWY_API_URL}/partner/torc/payout-method/param/${payout_method_name}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getPayoutMethodParamsById = async (
  payout_method_id,
  merchant_id
) => {
  const url = `${WEB_GTWY_API_URL}/partner/torc/payout-method/${payout_method_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const deleteShareHolder = async (org_shareholder_id, merchant_id) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/organization-shareholder/${org_shareholder_id}`;
  const requestHeaders = await getRequestHeaders();
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deleteDirector = async (org_director_id, merchant_id) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/organization-director/${org_director_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const saveKybOrgDetails = async (kybData, merchant_id) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/kyb/save-and-continue`;
  const requestHeaders = await getRequestHeaders();
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.patch(url, kybData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const isValidAcnAbn = async (payload) => {
  const { is_abn_acn, abn_acn_value, merchant_id } = payload;
  let url = "";
  if (is_abn_acn === "abn") {
    url = `${WEB_GTWY_API_URL}/partner/organization/is-valid-abn/${abn_acn_value}`;
  } else if (is_abn_acn === "acn") {
    url = `${WEB_GTWY_API_URL}/partner/organization/is-valid-acn/${abn_acn_value}`;
  }
  const requestHeaders = await getRequestHeaders();
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const isValidBsb = async (bsb_number) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/is-valid-bsb/${bsb_number}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchKybDocUrl = async (org_document_id, merchant_id) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/organization-document/${org_document_id}`;
  const requestHeaders = await getRequestHeaders();
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getCountryState = async (country_name) => {
  const url = `https://countriesnow.space/api/v0.1/countries/states`;
  let requestData = await createRequest();
  requestData = {
    country: country_name,
  };
  const response = await axios.post(url, requestData);
  return response.data;
};

export const getCountryStateCity = async (country_name, state_name) => {
  const url = `https://countriesnow.space/api/v0.1/countries/state/cities`;
  let requestData = await createRequest();
  requestData = {
    country: country_name,
    state: state_name,
  };
  const response = await axios.post(url, requestData);
  return response.data;
};

export const getFxSettlementFees = async (filterQueryArr = [], merchant_id) => {
  let url = `${WEB_GTWY_API_URL}/partner/torc/misc/fees`;
  if (filterQueryArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const uploadContract = async (
  org_id,
  org_document_id,
  signature_id,
  doc_type = "",
  merchant_id
) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization-document/${org_document_id}/sign-document/${signature_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const requestData = await createRequest();
  if (doc_type) {
    requestData.doc_type = doc_type;
  }
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const uploadOrgDocs = async (
  file,
  doc_descriptor,
  file_type,
  merchant_id = ""
) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/organization-document`;
  const requestHeaders = await getRequestHeaders();
  if (!!merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const requestData = await createRequest();
  requestData.file_name = file.name;
  requestData.file_type = file_type ? file_type : file.type;
  requestData.descriptor = doc_descriptor;

  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  const uploadUrl = response.data.data.upload_url;
  const org_document_id = response.data.data.org_document_id;
  delete axios.defaults.headers.common.Authorization;
  const uploadResponse = await axios.put(uploadUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
    redirect: "follow",
  });
  return { status: uploadResponse.status === 200, org_document_id };
};

// primary function to upload document
export const uploadDocument = async (file, doc_data, merchant_id = "") => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/organization-document`;
  const requestHeaders = await getRequestHeaders();
  if (!!merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const requestData = { ...doc_data };
  requestData.file_name = file.name;
  requestData.file_type = file.type;

  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  const org_document_id = response.data.data.org_document_id;
  const uploadUrl = response.data.data.upload_url;
  delete axios.defaults.headers.common.Authorization;
  const uploadResponse = await axios.put(uploadUrl, file, {
    headers: {
      "Content-Type": requestData.file_type,
    },
    redirect: "follow",
  });
  return { status: uploadResponse.status === 200, org_document_id };
};

export const uploadDoc = async (file, org_id, org_document_id, merchant_id) => {
  const response = await uploadOrgDocs(file, "Signature");
  const signature_id = response.org_document_id;
  await uploadContract(org_id, org_document_id, signature_id, merchant_id);
  return response.status;
};

export const getPayinMethodParamDetail = async (payment_method_name, mode) => {
  let url = `${WEB_GTWY_API_URL}/partner/torc/payin-method/param/${payment_method_name}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = "production";
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchPricingByPayoutMethodId = async (
  payout_method_id,
  pm_class,
  pm_group
) => {
  const url = `${WEB_GTWY_API_URL}/partner/pricing/organization/payout-method/${payout_method_id}/payout-method-class/${pm_class}/payout-method-group/${pm_group}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPricingByPaymentMethodId = async (
  payin_method_id,
  pm_class,
  pm_group
) => {
  const url = `${WEB_GTWY_API_URL}/partner/pricing/organization/payin-method/${payin_method_id}/payin-method-class/${pm_class}/payin-method-group/${pm_group}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchOrgPricing = async (with_log = false) => {
  const url = `${WEB_GTWY_API_URL}/partner/pricing/organization`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const mergeContractDocument = async (org_id, merchant_id) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/merge-contract-documents`;
  const requestHeaders = await getRequestHeaders();
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.patch(
    url,
    {},
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const fetchEnabledPaymentMethod = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/partner/torc/payin-method/all/enabled`;

  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }

  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = "production";
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

// Partner pricing
export const fetchPricingByReferenceId = async (
  reference_id,
  scope = "GCA"
) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-setup/scope/${scope}/reference_id/${reference_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createPricingPlan = async ({ plan_name, scope = "GCA" }) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/plan`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.plan_name = plan_name;
  requestData.scope = scope;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createPartnerPlan = async (data) => {
  const url = `${WEB_GTWY_API_URL}/pricing/partner-plan`;
  const requestHeaders = await getRequestHeaders();
  const requestData = { ...data };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPartnerPlan = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing/partner-plan`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();

  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchPlanById = async (plan_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing/plan/${plan_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchFxMarkupByPlanId = async (plan_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing/fx-conversion-markup?plan_id=${plan_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchOrganizationPlan = async (merchant_id = "") => {
  const url = `${WEB_GTWY_API_URL}/pricing/organization-plan`;
  const requestHeaders = await getRequestHeaders();
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updatePlanAssociation = async (
  op_id,
  plan_id,
  merchant_id = ""
) => {
  const url = `${WEB_GTWY_API_URL}/pricing/organization-plan/${op_id}`;
  const requestHeaders = await getRequestHeaders();
  if (merchant_id) {
    requestHeaders["x-merchant-id"] = merchant_id;
  }
  const requestData = await createRequest();
  requestData.plan_id = plan_id;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const activateMerchantAccount = async (org_user_id) => {
  const url = `${WEB_GTWY_API_URL}/partner/organization/account-activation/${org_user_id}`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateFxConversionMarkup = async (org_id, plan_id, obj) => {
  const url = `${WEB_GTWY_API_URL}/partner/pricing/fx-conversion-markup/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData["plan_id"] = plan_id;
  requestData["requested_fx_conversion_markup"] = obj.new_markup;
  requestData["plan_name"] = obj.plan_name;
  requestData["current_fx_conversion_markup"] =
    +obj.current_fx_conversion_markup;

  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updatePartnerPlan = async (values, plan_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing/partner-plan/${plan_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, values, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchCommissionWallet = async () => {
  const url = `${WEB_GTWY_API_URL}/partner/torc/commission-wallet`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchAllReport = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/partner/report`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const createReport = async (
  filterQueryArr = [],
  report_type,
  file_type = "csv"
) => {
  let url = `${WEB_GTWY_API_URL}/partner/report`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(
    url,
    { report_type, file_type },
    { headers: requestHeaders }
  );
  return response.data;
};

export const fetchReportById = async (report_id) => {
  const url = `${WEB_GTWY_API_URL}/partner/report/${report_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const addOrgUserAccount = async (userData) => {
  const url = `${WEB_GTWY_API_URL}/partner-user/organization-user/add`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData = { ...userData, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateOrgUserAccount = async (data, org_user_id) => {
  const url = `${WEB_GTWY_API_URL}/partner-user/organization-user/${org_user_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, data, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resendOrgUserInvitation = async (org_user_id) => {
  const url = `${WEB_GTWY_API_URL}/partner-user/organization-user/resend-user-invitation-email/${org_user_id}`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
