import { ListSubheader, List } from "@mui/material";
import { useLocation, matchPath } from "react-router-dom";
import SidebarMenuItem from "./item";
import menuItems from "./items";
import { experimentalStyled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import useAuth from "src/hooks/useAuth";

const MenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    padding:0px;

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(13)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(1, 3)};
      
      line-height: 1.5;
    }
`
);

const SubMenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    &.MuiList-root {
      padding:0;
      .MuiListItem-root {
        padding: 1px;
        padding-left: ${theme.spacing(0)};
        padding-right: ${theme.spacing(0)};

        .MuiButton-label {
          display: flex;
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(2)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(12)};
              color: ${theme.palette.primary.contrastText};
            }
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          border-radius: ${theme.sidebar.menuItemBorderRadius};
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(15)};
          padding: ${theme.spacing(1.5)};
          padding-left: ${theme.spacing(5)};
          font-weight: normal;
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(18)};
          }

          &:hover {
            background-color: ${theme.sidebar.menuItemBgHover};
            color: ${theme.sidebar.menuItemColorActive};
            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }

          &.Mui-active {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};
            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
            font-weight: bold;
            &:after {
              width: 5px;
              opacity: 1;
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;

          .MuiButton-root.Mui-active {
            &:after {
              opacity: 0;
            }
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0, 1, 3.8)};
          }

          .MuiListItem-root {
            padding: 0px;
            padding-left: ${theme.spacing(0)};
            padding-right: ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(12)};
              padding: ${theme.spacing(0, 2, 0, 0)};
              font-weight: normal;

              &:before {
                content: '';
                background: ${theme.sidebar.menuItemIconColor};
                opacity: .4;
                transition: ${theme.transitions.create(["background"])};
                width: 2px;
                height: 34px;
                margin-right: ${theme.spacing(2.9)};
              }

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};

                &:before {
                  background: ${theme.sidebar.menuItemIconColorActive};
                  opacity: 1;
                  width: 4px;
                  border-radius: 20px;
                }

                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function renderSidebarMenuItems({ items, path, partner_level }) {
  return (
    <SubMenuWrapper>
      {items.reduce((ev, item) => {
        if (item.name === "Organization Transactions") {
          if (partner_level !== "L1")
            return reduceChildRoutes({ ev, item, path, partner_level });
          else return ev;
        }
        return reduceChildRoutes({ ev, item, path, partner_level });
      }, [])}
    </SubMenuWrapper>
  );
}

function reduceChildRoutes({ ev, path, item, partner_level }) {
  const key = item.name;

  if (item.items) {
    const open = matchPath(path, {
      path: item.link,
      exact: false,
    });

    ev.push(
      <SidebarMenuItem
        key={key}
        open={Boolean(open)}
        name={item.name}
        icon={item.icon}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
          partner_level,
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
}

function SidebarMenu() {
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { partner_data } = useAuth();
  const { partner_level = "L1" } = partner_data || {};
  return (
    <>
      {menuItems.map((section) => (
        <MenuWrapper
          key={section.heading}
          subheader={
            <ListSubheader disableSticky>{t(section.heading)}</ListSubheader>
          }
        >
          {renderSidebarMenuItems({
            items: user.is_admin
              ? section.items
              : section.items.filter((item) => {
                  if (item.name !== "Admin Accounts") {
                    return item;
                  }
                }),
            path: location.pathname,
            partner_level,
          })}
        </MenuWrapper>
      ))}
    </>
  );
}

export default SidebarMenu;
