import App from "./App";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";

import "nprogress/nprogress.css";
import { SidebarProvider } from "./contexts/SidebarContext";
import { Provider } from "react-redux";
import store from "src/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <ToastContainer />
      <SidebarProvider>
        <App />
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();
